.prices {

	/* COLORS */
	&FreeFallTitle {
		@include text-gradient(left, $akGradientGreenDark, $akGradientGreenLight);
	}
	&KartingTitle {
		@include text-gradient(left, $akGradientOrangeDark, $akGradientOrangeLight);
	}
	&WinscapeTitle {
		@include text-gradient(left, $akGradientBlueDark, $akGradientBlueLight);
	}
	&VirtualLaserTitle {
		@include text-gradient(left, $akGradientPinkDark, $akGradientPinkLight);
	}

	&BankLogos {
        height: auto;
		max-width: 360px;
		width: 100%;
		margin-top: 47px;

	}

    &FreeFall {
        .bottomRow {
            a {
                color: $akGreen;
            }
        }
    }

	&FreeFall,
	&Karting,
	&VirtualLaser,
	&Winscape {
		margin-bottom: 40px;
		&Title,
		&TitleAdult,
		&TitleChild,
		&TitleHours {
			text-transform: uppercase;
		}

		&TitleAdult,
		&TitleChild,
		&TitleHours,
		&DetailPrice {
			@extend .akTitle6;
			font-weight: bold;
		}

		&Detail {
			&.row {
				margin-bottom: 0;
			}
			&Libelle,
			&Price {
				font-weight: bold;
				&ForSession {
					color: $akGreyText;
					font-size: 16px;
					font-size: 1.6rem;
					font-weight: 400;
					&Offer {
						@include text-gradient(left, $akGradientPinkDark, $akGradientPinkLight);
					}
				}
			}

            a {
                font-weight: bold;
            }
		}
		&TitleUnder,
		&DetailUnder {
			color: $akGreyText;
			margin-top: 0;
		}

		&Download {
			&Text {
				font-style: italic;
			}
			&Btn {
				margin-left: 40px;
			}
		}

		&Hours {
			.akTextSecondary {
				line-height: .7
			}
			&Day {
				margin-bottom: 0;
			}
		}
	}
}

p {
	margin-top: 0;
	margin-bottom: 10px;
}
