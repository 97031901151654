.freeFallInfos {
    &ContactForm .akFormInputField {
        margin-bottom: 20px;
    }
    &BackgroundImg {
        Height: 100vh;
        overflow: hidden;
    }
    &ReglementInterieur {
        font-size: 14px;
        margin-bottom: 30px;
        &Text {
            margin-bottom: 50px;
        }
    }
    &Informations {
        border: 1px solid white;
        &Texte {
            padding: 20px 0;
        }
    }
    &CheckboxNewspaper {
        margin-bottom: 40px;
    }
    &TextHideFormText {
        margin-top: 20px;
        margin-bottom: -30px;
    }
    &FlyDate {
        text-align: center;
        margin-top: 10px;
    }

}

.otherPrecisionsPlease {
    margin-bottom: 30px;
}

.confirmFormSend {
    border: 1px solid white;
    color: #ffffff;
    padding: 15px;
    text-align: center;
}

.LabelCustom {
    font: normal 17px "Raleway", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif !important;
}

label {
    &freeFallInfosCheckboxConditionsRead {
        color: #c6737e;
    }
}


