@charset "UTF-8";
.materialize-red {
  background-color: #e51c23 !important;
}

.materialize-red-text {
  color: #e51c23 !important;
}

.materialize-red.lighten-5 {
  background-color: #fdeaeb !important;
}

.materialize-red-text.text-lighten-5 {
  color: #fdeaeb !important;
}

.materialize-red.lighten-4 {
  background-color: #f8c1c3 !important;
}

.materialize-red-text.text-lighten-4 {
  color: #f8c1c3 !important;
}

.materialize-red.lighten-3 {
  background-color: #f3989b !important;
}

.materialize-red-text.text-lighten-3 {
  color: #f3989b !important;
}

.materialize-red.lighten-2 {
  background-color: #ee6e73 !important;
}

.materialize-red-text.text-lighten-2 {
  color: #ee6e73 !important;
}

.materialize-red.lighten-1 {
  background-color: #ea454b !important;
}

.materialize-red-text.text-lighten-1 {
  color: #ea454b !important;
}

.materialize-red.darken-1 {
  background-color: #d0181e !important;
}

.materialize-red-text.text-darken-1 {
  color: #d0181e !important;
}

.materialize-red.darken-2 {
  background-color: #b9151b !important;
}

.materialize-red-text.text-darken-2 {
  color: #b9151b !important;
}

.materialize-red.darken-3 {
  background-color: #a21318 !important;
}

.materialize-red-text.text-darken-3 {
  color: #a21318 !important;
}

.materialize-red.darken-4 {
  background-color: #8b1014 !important;
}

.materialize-red-text.text-darken-4 {
  color: #8b1014 !important;
}

.red {
  background-color: #F44336 !important;
}

.red-text {
  color: #F44336 !important;
}

.red.lighten-5 {
  background-color: #FFEBEE !important;
}

.red-text.text-lighten-5 {
  color: #FFEBEE !important;
}

.red.lighten-4 {
  background-color: #FFCDD2 !important;
}

.red-text.text-lighten-4 {
  color: #FFCDD2 !important;
}

.red.lighten-3 {
  background-color: #EF9A9A !important;
}

.red-text.text-lighten-3 {
  color: #EF9A9A !important;
}

.red.lighten-2 {
  background-color: #E57373 !important;
}

.red-text.text-lighten-2 {
  color: #E57373 !important;
}

.red.lighten-1 {
  background-color: #EF5350 !important;
}

.red-text.text-lighten-1 {
  color: #EF5350 !important;
}

.red.darken-1 {
  background-color: #E53935 !important;
}

.red-text.text-darken-1 {
  color: #E53935 !important;
}

.red.darken-2 {
  background-color: #D32F2F !important;
}

.red-text.text-darken-2 {
  color: #D32F2F !important;
}

.red.darken-3 {
  background-color: #C62828 !important;
}

.red-text.text-darken-3 {
  color: #C62828 !important;
}

.red.darken-4 {
  background-color: #B71C1C !important;
}

.red-text.text-darken-4 {
  color: #B71C1C !important;
}

.red.accent-1 {
  background-color: #FF8A80 !important;
}

.red-text.text-accent-1 {
  color: #FF8A80 !important;
}

.red.accent-2 {
  background-color: #FF5252 !important;
}

.red-text.text-accent-2 {
  color: #FF5252 !important;
}

.red.accent-3 {
  background-color: #FF1744 !important;
}

.red-text.text-accent-3 {
  color: #FF1744 !important;
}

.red.accent-4 {
  background-color: #D50000 !important;
}

.red-text.text-accent-4 {
  color: #D50000 !important;
}

.pink {
  background-color: #e91e63 !important;
}

.pink-text {
  color: #e91e63 !important;
}

.pink.lighten-5 {
  background-color: #fce4ec !important;
}

.pink-text.text-lighten-5 {
  color: #fce4ec !important;
}

.pink.lighten-4 {
  background-color: #f8bbd0 !important;
}

.pink-text.text-lighten-4 {
  color: #f8bbd0 !important;
}

.pink.lighten-3 {
  background-color: #f48fb1 !important;
}

.pink-text.text-lighten-3 {
  color: #f48fb1 !important;
}

.pink.lighten-2 {
  background-color: #f06292 !important;
}

.pink-text.text-lighten-2 {
  color: #f06292 !important;
}

.pink.lighten-1 {
  background-color: #ec407a !important;
}

.pink-text.text-lighten-1 {
  color: #ec407a !important;
}

.pink.darken-1 {
  background-color: #d81b60 !important;
}

.pink-text.text-darken-1 {
  color: #d81b60 !important;
}

.pink.darken-2 {
  background-color: #c2185b !important;
}

.pink-text.text-darken-2 {
  color: #c2185b !important;
}

.pink.darken-3 {
  background-color: #ad1457 !important;
}

.pink-text.text-darken-3 {
  color: #ad1457 !important;
}

.pink.darken-4 {
  background-color: #880e4f !important;
}

.pink-text.text-darken-4 {
  color: #880e4f !important;
}

.pink.accent-1 {
  background-color: #ff80ab !important;
}

.pink-text.text-accent-1 {
  color: #ff80ab !important;
}

.pink.accent-2 {
  background-color: #ff4081 !important;
}

.pink-text.text-accent-2 {
  color: #ff4081 !important;
}

.pink.accent-3 {
  background-color: #f50057 !important;
}

.pink-text.text-accent-3 {
  color: #f50057 !important;
}

.pink.accent-4 {
  background-color: #c51162 !important;
}

.pink-text.text-accent-4 {
  color: #c51162 !important;
}

.purple {
  background-color: #9c27b0 !important;
}

.purple-text {
  color: #9c27b0 !important;
}

.purple.lighten-5 {
  background-color: #f3e5f5 !important;
}

.purple-text.text-lighten-5 {
  color: #f3e5f5 !important;
}

.purple.lighten-4 {
  background-color: #e1bee7 !important;
}

.purple-text.text-lighten-4 {
  color: #e1bee7 !important;
}

.purple.lighten-3 {
  background-color: #ce93d8 !important;
}

.purple-text.text-lighten-3 {
  color: #ce93d8 !important;
}

.purple.lighten-2 {
  background-color: #ba68c8 !important;
}

.purple-text.text-lighten-2 {
  color: #ba68c8 !important;
}

.purple.lighten-1 {
  background-color: #ab47bc !important;
}

.purple-text.text-lighten-1 {
  color: #ab47bc !important;
}

.purple.darken-1 {
  background-color: #8e24aa !important;
}

.purple-text.text-darken-1 {
  color: #8e24aa !important;
}

.purple.darken-2 {
  background-color: #7b1fa2 !important;
}

.purple-text.text-darken-2 {
  color: #7b1fa2 !important;
}

.purple.darken-3 {
  background-color: #6a1b9a !important;
}

.purple-text.text-darken-3 {
  color: #6a1b9a !important;
}

.purple.darken-4 {
  background-color: #4a148c !important;
}

.purple-text.text-darken-4 {
  color: #4a148c !important;
}

.purple.accent-1 {
  background-color: #ea80fc !important;
}

.purple-text.text-accent-1 {
  color: #ea80fc !important;
}

.purple.accent-2 {
  background-color: #e040fb !important;
}

.purple-text.text-accent-2 {
  color: #e040fb !important;
}

.purple.accent-3 {
  background-color: #d500f9 !important;
}

.purple-text.text-accent-3 {
  color: #d500f9 !important;
}

.purple.accent-4 {
  background-color: #aa00ff !important;
}

.purple-text.text-accent-4 {
  color: #aa00ff !important;
}

.deep-purple {
  background-color: #673ab7 !important;
}

.deep-purple-text {
  color: #673ab7 !important;
}

.deep-purple.lighten-5 {
  background-color: #ede7f6 !important;
}

.deep-purple-text.text-lighten-5 {
  color: #ede7f6 !important;
}

.deep-purple.lighten-4 {
  background-color: #d1c4e9 !important;
}

.deep-purple-text.text-lighten-4 {
  color: #d1c4e9 !important;
}

.deep-purple.lighten-3 {
  background-color: #b39ddb !important;
}

.deep-purple-text.text-lighten-3 {
  color: #b39ddb !important;
}

.deep-purple.lighten-2 {
  background-color: #9575cd !important;
}

.deep-purple-text.text-lighten-2 {
  color: #9575cd !important;
}

.deep-purple.lighten-1 {
  background-color: #7e57c2 !important;
}

.deep-purple-text.text-lighten-1 {
  color: #7e57c2 !important;
}

.deep-purple.darken-1 {
  background-color: #5e35b1 !important;
}

.deep-purple-text.text-darken-1 {
  color: #5e35b1 !important;
}

.deep-purple.darken-2 {
  background-color: #512da8 !important;
}

.deep-purple-text.text-darken-2 {
  color: #512da8 !important;
}

.deep-purple.darken-3 {
  background-color: #4527a0 !important;
}

.deep-purple-text.text-darken-3 {
  color: #4527a0 !important;
}

.deep-purple.darken-4 {
  background-color: #311b92 !important;
}

.deep-purple-text.text-darken-4 {
  color: #311b92 !important;
}

.deep-purple.accent-1 {
  background-color: #b388ff !important;
}

.deep-purple-text.text-accent-1 {
  color: #b388ff !important;
}

.deep-purple.accent-2 {
  background-color: #7c4dff !important;
}

.deep-purple-text.text-accent-2 {
  color: #7c4dff !important;
}

.deep-purple.accent-3 {
  background-color: #651fff !important;
}

.deep-purple-text.text-accent-3 {
  color: #651fff !important;
}

.deep-purple.accent-4 {
  background-color: #6200ea !important;
}

.deep-purple-text.text-accent-4 {
  color: #6200ea !important;
}

.indigo {
  background-color: #3f51b5 !important;
}

.indigo-text {
  color: #3f51b5 !important;
}

.indigo.lighten-5 {
  background-color: #e8eaf6 !important;
}

.indigo-text.text-lighten-5 {
  color: #e8eaf6 !important;
}

.indigo.lighten-4 {
  background-color: #c5cae9 !important;
}

.indigo-text.text-lighten-4 {
  color: #c5cae9 !important;
}

.indigo.lighten-3 {
  background-color: #9fa8da !important;
}

.indigo-text.text-lighten-3 {
  color: #9fa8da !important;
}

.indigo.lighten-2 {
  background-color: #7986cb !important;
}

.indigo-text.text-lighten-2 {
  color: #7986cb !important;
}

.indigo.lighten-1 {
  background-color: #5c6bc0 !important;
}

.indigo-text.text-lighten-1 {
  color: #5c6bc0 !important;
}

.indigo.darken-1 {
  background-color: #3949ab !important;
}

.indigo-text.text-darken-1 {
  color: #3949ab !important;
}

.indigo.darken-2 {
  background-color: #303f9f !important;
}

.indigo-text.text-darken-2 {
  color: #303f9f !important;
}

.indigo.darken-3 {
  background-color: #283593 !important;
}

.indigo-text.text-darken-3 {
  color: #283593 !important;
}

.indigo.darken-4 {
  background-color: #1a237e !important;
}

.indigo-text.text-darken-4 {
  color: #1a237e !important;
}

.indigo.accent-1 {
  background-color: #8c9eff !important;
}

.indigo-text.text-accent-1 {
  color: #8c9eff !important;
}

.indigo.accent-2 {
  background-color: #536dfe !important;
}

.indigo-text.text-accent-2 {
  color: #536dfe !important;
}

.indigo.accent-3 {
  background-color: #3d5afe !important;
}

.indigo-text.text-accent-3 {
  color: #3d5afe !important;
}

.indigo.accent-4 {
  background-color: #304ffe !important;
}

.indigo-text.text-accent-4 {
  color: #304ffe !important;
}

.blue {
  background-color: #2196F3 !important;
}

.blue-text {
  color: #2196F3 !important;
}

.blue.lighten-5 {
  background-color: #E3F2FD !important;
}

.blue-text.text-lighten-5 {
  color: #E3F2FD !important;
}

.blue.lighten-4 {
  background-color: #BBDEFB !important;
}

.blue-text.text-lighten-4 {
  color: #BBDEFB !important;
}

.blue.lighten-3 {
  background-color: #90CAF9 !important;
}

.blue-text.text-lighten-3 {
  color: #90CAF9 !important;
}

.blue.lighten-2 {
  background-color: #64B5F6 !important;
}

.blue-text.text-lighten-2 {
  color: #64B5F6 !important;
}

.blue.lighten-1 {
  background-color: #42A5F5 !important;
}

.blue-text.text-lighten-1 {
  color: #42A5F5 !important;
}

.blue.darken-1 {
  background-color: #1E88E5 !important;
}

.blue-text.text-darken-1 {
  color: #1E88E5 !important;
}

.blue.darken-2 {
  background-color: #1976D2 !important;
}

.blue-text.text-darken-2 {
  color: #1976D2 !important;
}

.blue.darken-3 {
  background-color: #1565C0 !important;
}

.blue-text.text-darken-3 {
  color: #1565C0 !important;
}

.blue.darken-4 {
  background-color: #0D47A1 !important;
}

.blue-text.text-darken-4 {
  color: #0D47A1 !important;
}

.blue.accent-1 {
  background-color: #82B1FF !important;
}

.blue-text.text-accent-1 {
  color: #82B1FF !important;
}

.blue.accent-2 {
  background-color: #448AFF !important;
}

.blue-text.text-accent-2 {
  color: #448AFF !important;
}

.blue.accent-3 {
  background-color: #2979FF !important;
}

.blue-text.text-accent-3 {
  color: #2979FF !important;
}

.blue.accent-4 {
  background-color: #2962FF !important;
}

.blue-text.text-accent-4 {
  color: #2962FF !important;
}

.light-blue {
  background-color: #03a9f4 !important;
}

.light-blue-text {
  color: #03a9f4 !important;
}

.light-blue.lighten-5 {
  background-color: #e1f5fe !important;
}

.light-blue-text.text-lighten-5 {
  color: #e1f5fe !important;
}

.light-blue.lighten-4 {
  background-color: #b3e5fc !important;
}

.light-blue-text.text-lighten-4 {
  color: #b3e5fc !important;
}

.light-blue.lighten-3 {
  background-color: #81d4fa !important;
}

.light-blue-text.text-lighten-3 {
  color: #81d4fa !important;
}

.light-blue.lighten-2 {
  background-color: #4fc3f7 !important;
}

.light-blue-text.text-lighten-2 {
  color: #4fc3f7 !important;
}

.light-blue.lighten-1 {
  background-color: #29b6f6 !important;
}

.light-blue-text.text-lighten-1 {
  color: #29b6f6 !important;
}

.light-blue.darken-1 {
  background-color: #039be5 !important;
}

.light-blue-text.text-darken-1 {
  color: #039be5 !important;
}

.light-blue.darken-2 {
  background-color: #0288d1 !important;
}

.light-blue-text.text-darken-2 {
  color: #0288d1 !important;
}

.light-blue.darken-3 {
  background-color: #0277bd !important;
}

.light-blue-text.text-darken-3 {
  color: #0277bd !important;
}

.light-blue.darken-4 {
  background-color: #01579b !important;
}

.light-blue-text.text-darken-4 {
  color: #01579b !important;
}

.light-blue.accent-1 {
  background-color: #80d8ff !important;
}

.light-blue-text.text-accent-1 {
  color: #80d8ff !important;
}

.light-blue.accent-2 {
  background-color: #40c4ff !important;
}

.light-blue-text.text-accent-2 {
  color: #40c4ff !important;
}

.light-blue.accent-3 {
  background-color: #00b0ff !important;
}

.light-blue-text.text-accent-3 {
  color: #00b0ff !important;
}

.light-blue.accent-4 {
  background-color: #0091ea !important;
}

.light-blue-text.text-accent-4 {
  color: #0091ea !important;
}

.cyan {
  background-color: #00bcd4 !important;
}

.cyan-text {
  color: #00bcd4 !important;
}

.cyan.lighten-5 {
  background-color: #e0f7fa !important;
}

.cyan-text.text-lighten-5 {
  color: #e0f7fa !important;
}

.cyan.lighten-4 {
  background-color: #b2ebf2 !important;
}

.cyan-text.text-lighten-4 {
  color: #b2ebf2 !important;
}

.cyan.lighten-3 {
  background-color: #80deea !important;
}

.cyan-text.text-lighten-3 {
  color: #80deea !important;
}

.cyan.lighten-2 {
  background-color: #4dd0e1 !important;
}

.cyan-text.text-lighten-2 {
  color: #4dd0e1 !important;
}

.cyan.lighten-1 {
  background-color: #26c6da !important;
}

.cyan-text.text-lighten-1 {
  color: #26c6da !important;
}

.cyan.darken-1 {
  background-color: #00acc1 !important;
}

.cyan-text.text-darken-1 {
  color: #00acc1 !important;
}

.cyan.darken-2 {
  background-color: #0097a7 !important;
}

.cyan-text.text-darken-2 {
  color: #0097a7 !important;
}

.cyan.darken-3 {
  background-color: #00838f !important;
}

.cyan-text.text-darken-3 {
  color: #00838f !important;
}

.cyan.darken-4 {
  background-color: #006064 !important;
}

.cyan-text.text-darken-4 {
  color: #006064 !important;
}

.cyan.accent-1 {
  background-color: #84ffff !important;
}

.cyan-text.text-accent-1 {
  color: #84ffff !important;
}

.cyan.accent-2 {
  background-color: #18ffff !important;
}

.cyan-text.text-accent-2 {
  color: #18ffff !important;
}

.cyan.accent-3 {
  background-color: #00e5ff !important;
}

.cyan-text.text-accent-3 {
  color: #00e5ff !important;
}

.cyan.accent-4 {
  background-color: #00b8d4 !important;
}

.cyan-text.text-accent-4 {
  color: #00b8d4 !important;
}

.teal {
  background-color: #009688 !important;
}

.teal-text {
  color: #009688 !important;
}

.teal.lighten-5 {
  background-color: #e0f2f1 !important;
}

.teal-text.text-lighten-5 {
  color: #e0f2f1 !important;
}

.teal.lighten-4 {
  background-color: #b2dfdb !important;
}

.teal-text.text-lighten-4 {
  color: #b2dfdb !important;
}

.teal.lighten-3 {
  background-color: #80cbc4 !important;
}

.teal-text.text-lighten-3 {
  color: #80cbc4 !important;
}

.teal.lighten-2 {
  background-color: #4db6ac !important;
}

.teal-text.text-lighten-2 {
  color: #4db6ac !important;
}

.teal.lighten-1 {
  background-color: #26a69a !important;
}

.teal-text.text-lighten-1 {
  color: #26a69a !important;
}

.teal.darken-1 {
  background-color: #00897b !important;
}

.teal-text.text-darken-1 {
  color: #00897b !important;
}

.teal.darken-2 {
  background-color: #00796b !important;
}

.teal-text.text-darken-2 {
  color: #00796b !important;
}

.teal.darken-3 {
  background-color: #00695c !important;
}

.teal-text.text-darken-3 {
  color: #00695c !important;
}

.teal.darken-4 {
  background-color: #004d40 !important;
}

.teal-text.text-darken-4 {
  color: #004d40 !important;
}

.teal.accent-1 {
  background-color: #a7ffeb !important;
}

.teal-text.text-accent-1 {
  color: #a7ffeb !important;
}

.teal.accent-2 {
  background-color: #64ffda !important;
}

.teal-text.text-accent-2 {
  color: #64ffda !important;
}

.teal.accent-3 {
  background-color: #1de9b6 !important;
}

.teal-text.text-accent-3 {
  color: #1de9b6 !important;
}

.teal.accent-4 {
  background-color: #00bfa5 !important;
}

.teal-text.text-accent-4 {
  color: #00bfa5 !important;
}

.green {
  background-color: #4CAF50 !important;
}

.green-text {
  color: #4CAF50 !important;
}

.green.lighten-5 {
  background-color: #E8F5E9 !important;
}

.green-text.text-lighten-5 {
  color: #E8F5E9 !important;
}

.green.lighten-4 {
  background-color: #C8E6C9 !important;
}

.green-text.text-lighten-4 {
  color: #C8E6C9 !important;
}

.green.lighten-3 {
  background-color: #A5D6A7 !important;
}

.green-text.text-lighten-3 {
  color: #A5D6A7 !important;
}

.green.lighten-2 {
  background-color: #81C784 !important;
}

.green-text.text-lighten-2 {
  color: #81C784 !important;
}

.green.lighten-1 {
  background-color: #66BB6A !important;
}

.green-text.text-lighten-1 {
  color: #66BB6A !important;
}

.green.darken-1 {
  background-color: #43A047 !important;
}

.green-text.text-darken-1 {
  color: #43A047 !important;
}

.green.darken-2 {
  background-color: #388E3C !important;
}

.green-text.text-darken-2 {
  color: #388E3C !important;
}

.green.darken-3 {
  background-color: #2E7D32 !important;
}

.green-text.text-darken-3 {
  color: #2E7D32 !important;
}

.green.darken-4 {
  background-color: #1B5E20 !important;
}

.green-text.text-darken-4 {
  color: #1B5E20 !important;
}

.green.accent-1 {
  background-color: #B9F6CA !important;
}

.green-text.text-accent-1 {
  color: #B9F6CA !important;
}

.green.accent-2 {
  background-color: #69F0AE !important;
}

.green-text.text-accent-2 {
  color: #69F0AE !important;
}

.green.accent-3 {
  background-color: #00E676 !important;
}

.green-text.text-accent-3 {
  color: #00E676 !important;
}

.green.accent-4 {
  background-color: #00C853 !important;
}

.green-text.text-accent-4 {
  color: #00C853 !important;
}

.light-green {
  background-color: #8bc34a !important;
}

.light-green-text {
  color: #8bc34a !important;
}

.light-green.lighten-5 {
  background-color: #f1f8e9 !important;
}

.light-green-text.text-lighten-5 {
  color: #f1f8e9 !important;
}

.light-green.lighten-4 {
  background-color: #dcedc8 !important;
}

.light-green-text.text-lighten-4 {
  color: #dcedc8 !important;
}

.light-green.lighten-3 {
  background-color: #c5e1a5 !important;
}

.light-green-text.text-lighten-3 {
  color: #c5e1a5 !important;
}

.light-green.lighten-2 {
  background-color: #aed581 !important;
}

.light-green-text.text-lighten-2 {
  color: #aed581 !important;
}

.light-green.lighten-1 {
  background-color: #9ccc65 !important;
}

.light-green-text.text-lighten-1 {
  color: #9ccc65 !important;
}

.light-green.darken-1 {
  background-color: #7cb342 !important;
}

.light-green-text.text-darken-1 {
  color: #7cb342 !important;
}

.light-green.darken-2 {
  background-color: #689f38 !important;
}

.light-green-text.text-darken-2 {
  color: #689f38 !important;
}

.light-green.darken-3 {
  background-color: #558b2f !important;
}

.light-green-text.text-darken-3 {
  color: #558b2f !important;
}

.light-green.darken-4 {
  background-color: #33691e !important;
}

.light-green-text.text-darken-4 {
  color: #33691e !important;
}

.light-green.accent-1 {
  background-color: #ccff90 !important;
}

.light-green-text.text-accent-1 {
  color: #ccff90 !important;
}

.light-green.accent-2 {
  background-color: #b2ff59 !important;
}

.light-green-text.text-accent-2 {
  color: #b2ff59 !important;
}

.light-green.accent-3 {
  background-color: #76ff03 !important;
}

.light-green-text.text-accent-3 {
  color: #76ff03 !important;
}

.light-green.accent-4 {
  background-color: #64dd17 !important;
}

.light-green-text.text-accent-4 {
  color: #64dd17 !important;
}

.lime {
  background-color: #cddc39 !important;
}

.lime-text {
  color: #cddc39 !important;
}

.lime.lighten-5 {
  background-color: #f9fbe7 !important;
}

.lime-text.text-lighten-5 {
  color: #f9fbe7 !important;
}

.lime.lighten-4 {
  background-color: #f0f4c3 !important;
}

.lime-text.text-lighten-4 {
  color: #f0f4c3 !important;
}

.lime.lighten-3 {
  background-color: #e6ee9c !important;
}

.lime-text.text-lighten-3 {
  color: #e6ee9c !important;
}

.lime.lighten-2 {
  background-color: #dce775 !important;
}

.lime-text.text-lighten-2 {
  color: #dce775 !important;
}

.lime.lighten-1 {
  background-color: #d4e157 !important;
}

.lime-text.text-lighten-1 {
  color: #d4e157 !important;
}

.lime.darken-1 {
  background-color: #c0ca33 !important;
}

.lime-text.text-darken-1 {
  color: #c0ca33 !important;
}

.lime.darken-2 {
  background-color: #afb42b !important;
}

.lime-text.text-darken-2 {
  color: #afb42b !important;
}

.lime.darken-3 {
  background-color: #9e9d24 !important;
}

.lime-text.text-darken-3 {
  color: #9e9d24 !important;
}

.lime.darken-4 {
  background-color: #827717 !important;
}

.lime-text.text-darken-4 {
  color: #827717 !important;
}

.lime.accent-1 {
  background-color: #f4ff81 !important;
}

.lime-text.text-accent-1 {
  color: #f4ff81 !important;
}

.lime.accent-2 {
  background-color: #eeff41 !important;
}

.lime-text.text-accent-2 {
  color: #eeff41 !important;
}

.lime.accent-3 {
  background-color: #c6ff00 !important;
}

.lime-text.text-accent-3 {
  color: #c6ff00 !important;
}

.lime.accent-4 {
  background-color: #aeea00 !important;
}

.lime-text.text-accent-4 {
  color: #aeea00 !important;
}

.yellow {
  background-color: #ffeb3b !important;
}

.yellow-text {
  color: #ffeb3b !important;
}

.yellow.lighten-5 {
  background-color: #fffde7 !important;
}

.yellow-text.text-lighten-5 {
  color: #fffde7 !important;
}

.yellow.lighten-4 {
  background-color: #fff9c4 !important;
}

.yellow-text.text-lighten-4 {
  color: #fff9c4 !important;
}

.yellow.lighten-3 {
  background-color: #fff59d !important;
}

.yellow-text.text-lighten-3 {
  color: #fff59d !important;
}

.yellow.lighten-2 {
  background-color: #fff176 !important;
}

.yellow-text.text-lighten-2 {
  color: #fff176 !important;
}

.yellow.lighten-1 {
  background-color: #ffee58 !important;
}

.yellow-text.text-lighten-1 {
  color: #ffee58 !important;
}

.yellow.darken-1 {
  background-color: #fdd835 !important;
}

.yellow-text.text-darken-1 {
  color: #fdd835 !important;
}

.yellow.darken-2 {
  background-color: #fbc02d !important;
}

.yellow-text.text-darken-2 {
  color: #fbc02d !important;
}

.yellow.darken-3 {
  background-color: #f9a825 !important;
}

.yellow-text.text-darken-3 {
  color: #f9a825 !important;
}

.yellow.darken-4 {
  background-color: #f57f17 !important;
}

.yellow-text.text-darken-4 {
  color: #f57f17 !important;
}

.yellow.accent-1 {
  background-color: #ffff8d !important;
}

.yellow-text.text-accent-1 {
  color: #ffff8d !important;
}

.yellow.accent-2 {
  background-color: #ffff00 !important;
}

.yellow-text.text-accent-2 {
  color: #ffff00 !important;
}

.yellow.accent-3 {
  background-color: #ffea00 !important;
}

.yellow-text.text-accent-3 {
  color: #ffea00 !important;
}

.yellow.accent-4 {
  background-color: #ffd600 !important;
}

.yellow-text.text-accent-4 {
  color: #ffd600 !important;
}

.amber {
  background-color: #ffc107 !important;
}

.amber-text {
  color: #ffc107 !important;
}

.amber.lighten-5 {
  background-color: #fff8e1 !important;
}

.amber-text.text-lighten-5 {
  color: #fff8e1 !important;
}

.amber.lighten-4 {
  background-color: #ffecb3 !important;
}

.amber-text.text-lighten-4 {
  color: #ffecb3 !important;
}

.amber.lighten-3 {
  background-color: #ffe082 !important;
}

.amber-text.text-lighten-3 {
  color: #ffe082 !important;
}

.amber.lighten-2 {
  background-color: #ffd54f !important;
}

.amber-text.text-lighten-2 {
  color: #ffd54f !important;
}

.amber.lighten-1 {
  background-color: #ffca28 !important;
}

.amber-text.text-lighten-1 {
  color: #ffca28 !important;
}

.amber.darken-1 {
  background-color: #ffb300 !important;
}

.amber-text.text-darken-1 {
  color: #ffb300 !important;
}

.amber.darken-2 {
  background-color: #ffa000 !important;
}

.amber-text.text-darken-2 {
  color: #ffa000 !important;
}

.amber.darken-3 {
  background-color: #ff8f00 !important;
}

.amber-text.text-darken-3 {
  color: #ff8f00 !important;
}

.amber.darken-4 {
  background-color: #ff6f00 !important;
}

.amber-text.text-darken-4 {
  color: #ff6f00 !important;
}

.amber.accent-1 {
  background-color: #ffe57f !important;
}

.amber-text.text-accent-1 {
  color: #ffe57f !important;
}

.amber.accent-2 {
  background-color: #ffd740 !important;
}

.amber-text.text-accent-2 {
  color: #ffd740 !important;
}

.amber.accent-3 {
  background-color: #ffc400 !important;
}

.amber-text.text-accent-3 {
  color: #ffc400 !important;
}

.amber.accent-4 {
  background-color: #ffab00 !important;
}

.amber-text.text-accent-4 {
  color: #ffab00 !important;
}

.orange {
  background-color: #ff9800 !important;
}

.orange-text {
  color: #ff9800 !important;
}

.orange.lighten-5 {
  background-color: #fff3e0 !important;
}

.orange-text.text-lighten-5 {
  color: #fff3e0 !important;
}

.orange.lighten-4 {
  background-color: #ffe0b2 !important;
}

.orange-text.text-lighten-4 {
  color: #ffe0b2 !important;
}

.orange.lighten-3 {
  background-color: #ffcc80 !important;
}

.orange-text.text-lighten-3 {
  color: #ffcc80 !important;
}

.orange.lighten-2 {
  background-color: #ffb74d !important;
}

.orange-text.text-lighten-2 {
  color: #ffb74d !important;
}

.orange.lighten-1 {
  background-color: #ffa726 !important;
}

.orange-text.text-lighten-1 {
  color: #ffa726 !important;
}

.orange.darken-1 {
  background-color: #fb8c00 !important;
}

.orange-text.text-darken-1 {
  color: #fb8c00 !important;
}

.orange.darken-2 {
  background-color: #f57c00 !important;
}

.orange-text.text-darken-2 {
  color: #f57c00 !important;
}

.orange.darken-3 {
  background-color: #ef6c00 !important;
}

.orange-text.text-darken-3 {
  color: #ef6c00 !important;
}

.orange.darken-4 {
  background-color: #e65100 !important;
}

.orange-text.text-darken-4 {
  color: #e65100 !important;
}

.orange.accent-1 {
  background-color: #ffd180 !important;
}

.orange-text.text-accent-1 {
  color: #ffd180 !important;
}

.orange.accent-2 {
  background-color: #ffab40 !important;
}

.orange-text.text-accent-2 {
  color: #ffab40 !important;
}

.orange.accent-3 {
  background-color: #ff9100 !important;
}

.orange-text.text-accent-3 {
  color: #ff9100 !important;
}

.orange.accent-4 {
  background-color: #ff6d00 !important;
}

.orange-text.text-accent-4 {
  color: #ff6d00 !important;
}

.deep-orange {
  background-color: #ff5722 !important;
}

.deep-orange-text {
  color: #ff5722 !important;
}

.deep-orange.lighten-5 {
  background-color: #fbe9e7 !important;
}

.deep-orange-text.text-lighten-5 {
  color: #fbe9e7 !important;
}

.deep-orange.lighten-4 {
  background-color: #ffccbc !important;
}

.deep-orange-text.text-lighten-4 {
  color: #ffccbc !important;
}

.deep-orange.lighten-3 {
  background-color: #ffab91 !important;
}

.deep-orange-text.text-lighten-3 {
  color: #ffab91 !important;
}

.deep-orange.lighten-2 {
  background-color: #ff8a65 !important;
}

.deep-orange-text.text-lighten-2 {
  color: #ff8a65 !important;
}

.deep-orange.lighten-1 {
  background-color: #ff7043 !important;
}

.deep-orange-text.text-lighten-1 {
  color: #ff7043 !important;
}

.deep-orange.darken-1 {
  background-color: #f4511e !important;
}

.deep-orange-text.text-darken-1 {
  color: #f4511e !important;
}

.deep-orange.darken-2 {
  background-color: #e64a19 !important;
}

.deep-orange-text.text-darken-2 {
  color: #e64a19 !important;
}

.deep-orange.darken-3 {
  background-color: #d84315 !important;
}

.deep-orange-text.text-darken-3 {
  color: #d84315 !important;
}

.deep-orange.darken-4 {
  background-color: #bf360c !important;
}

.deep-orange-text.text-darken-4 {
  color: #bf360c !important;
}

.deep-orange.accent-1 {
  background-color: #ff9e80 !important;
}

.deep-orange-text.text-accent-1 {
  color: #ff9e80 !important;
}

.deep-orange.accent-2 {
  background-color: #ff6e40 !important;
}

.deep-orange-text.text-accent-2 {
  color: #ff6e40 !important;
}

.deep-orange.accent-3 {
  background-color: #ff3d00 !important;
}

.deep-orange-text.text-accent-3 {
  color: #ff3d00 !important;
}

.deep-orange.accent-4 {
  background-color: #dd2c00 !important;
}

.deep-orange-text.text-accent-4 {
  color: #dd2c00 !important;
}

.brown {
  background-color: #795548 !important;
}

.brown-text {
  color: #795548 !important;
}

.brown.lighten-5 {
  background-color: #efebe9 !important;
}

.brown-text.text-lighten-5 {
  color: #efebe9 !important;
}

.brown.lighten-4 {
  background-color: #d7ccc8 !important;
}

.brown-text.text-lighten-4 {
  color: #d7ccc8 !important;
}

.brown.lighten-3 {
  background-color: #bcaaa4 !important;
}

.brown-text.text-lighten-3 {
  color: #bcaaa4 !important;
}

.brown.lighten-2 {
  background-color: #a1887f !important;
}

.brown-text.text-lighten-2 {
  color: #a1887f !important;
}

.brown.lighten-1 {
  background-color: #8d6e63 !important;
}

.brown-text.text-lighten-1 {
  color: #8d6e63 !important;
}

.brown.darken-1 {
  background-color: #6d4c41 !important;
}

.brown-text.text-darken-1 {
  color: #6d4c41 !important;
}

.brown.darken-2 {
  background-color: #5d4037 !important;
}

.brown-text.text-darken-2 {
  color: #5d4037 !important;
}

.brown.darken-3 {
  background-color: #4e342e !important;
}

.brown-text.text-darken-3 {
  color: #4e342e !important;
}

.brown.darken-4 {
  background-color: #3e2723 !important;
}

.brown-text.text-darken-4 {
  color: #3e2723 !important;
}

.blue-grey {
  background-color: #607d8b !important;
}

.blue-grey-text {
  color: #607d8b !important;
}

.blue-grey.lighten-5 {
  background-color: #eceff1 !important;
}

.blue-grey-text.text-lighten-5 {
  color: #eceff1 !important;
}

.blue-grey.lighten-4 {
  background-color: #cfd8dc !important;
}

.blue-grey-text.text-lighten-4 {
  color: #cfd8dc !important;
}

.blue-grey.lighten-3 {
  background-color: #b0bec5 !important;
}

.blue-grey-text.text-lighten-3 {
  color: #b0bec5 !important;
}

.blue-grey.lighten-2 {
  background-color: #90a4ae !important;
}

.blue-grey-text.text-lighten-2 {
  color: #90a4ae !important;
}

.blue-grey.lighten-1 {
  background-color: #78909c !important;
}

.blue-grey-text.text-lighten-1 {
  color: #78909c !important;
}

.blue-grey.darken-1 {
  background-color: #546e7a !important;
}

.blue-grey-text.text-darken-1 {
  color: #546e7a !important;
}

.blue-grey.darken-2 {
  background-color: #455a64 !important;
}

.blue-grey-text.text-darken-2 {
  color: #455a64 !important;
}

.blue-grey.darken-3 {
  background-color: #37474f !important;
}

.blue-grey-text.text-darken-3 {
  color: #37474f !important;
}

.blue-grey.darken-4 {
  background-color: #263238 !important;
}

.blue-grey-text.text-darken-4 {
  color: #263238 !important;
}

.grey {
  background-color: #9e9e9e !important;
}

.grey-text {
  color: #9e9e9e !important;
}

.grey.lighten-5 {
  background-color: #fafafa !important;
}

.grey-text.text-lighten-5 {
  color: #fafafa !important;
}

.grey.lighten-4 {
  background-color: #f5f5f5 !important;
}

.grey-text.text-lighten-4 {
  color: #f5f5f5 !important;
}

.grey.lighten-3 {
  background-color: #eeeeee !important;
}

.grey-text.text-lighten-3 {
  color: #eeeeee !important;
}

.grey.lighten-2 {
  background-color: #e0e0e0 !important;
}

.grey-text.text-lighten-2 {
  color: #e0e0e0 !important;
}

.grey.lighten-1 {
  background-color: #bdbdbd !important;
}

.grey-text.text-lighten-1 {
  color: #bdbdbd !important;
}

.grey.darken-1 {
  background-color: #757575 !important;
}

.grey-text.text-darken-1 {
  color: #757575 !important;
}

.grey.darken-2 {
  background-color: #616161 !important;
}

.grey-text.text-darken-2 {
  color: #616161 !important;
}

.grey.darken-3 {
  background-color: #424242 !important;
}

.grey-text.text-darken-3 {
  color: #424242 !important;
}

.grey.darken-4 {
  background-color: #212121 !important;
}

.grey-text.text-darken-4 {
  color: #212121 !important;
}

.black {
  background-color: #000000 !important;
}

.black-text {
  color: #000000 !important;
}

.white {
  background-color: #FFFFFF !important;
}

.white-text {
  color: #FFFFFF !important;
}

.transparent {
  background-color: transparent !important;
}

.transparent-text {
  color: transparent !important;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: "icomoon";
  src: url("../../fonts//icomoon/icomoon.eot?d7djqb");
  src: url("../../fonts//icomoon/icomoon.eot?d7djqb#iefix") format("embedded-opentype"), url("../../fonts//icomoon/icomoon.woff2?d7djqb") format("woff2"), url("../../fonts//icomoon/icomoon.ttf?d7djqb") format("truetype"), url("../../fonts//icomoon/icomoon.woff?d7djqb") format("woff"), url("../../fonts//icomoon/icomoon.svg?d7djqb#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class*=icon-]:before {
  display: inline-block;
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-actualites:before {
  content: "A";
}

.icon-amis:before {
  content: "B";
}

.icon-anniversaire:before {
  content: "C";
}

.icon-arrow-right:before {
  content: "D";
}

.icon-arrow-up:before {
  content: "E";
}

.icon-boutique:before {
  content: "F";
}

.icon-casiers:before {
  content: "G";
}

.icon-chute-libre:before {
  content: "H";
}

.icon-collegues:before {
  content: "I";
}

.icon-combinaison:before {
  content: "J";
}

.icon-contact:before {
  content: "K";
}

.icon-activites:before {
  content: "L";
}

.icon-cross:before {
  content: "M";
}

.icon-download:before {
  content: "N";
}

.icon-entreprise:before {
  content: "O";
}

.icon-facebook:before {
  content: "P";
}

.icon-famille:before {
  content: "Q";
}

.icon-instagram:before {
  content: "R";
}

.icon-karting:before {
  content: "S";
}

.icon-menu:before {
  content: "T";
}

.icon-panier:before {
  content: "U";
}

.icon-photo:before {
  content: "V";
}

.icon-tarifs:before {
  content: "W";
}

.icon-twitter:before {
  content: "X";
}

.icon-video:before {
  content: "Y";
}

.icon-winscape:before {
  content: "Z";
}

.icon-youtube:before {
  content: "a";
}

.icon-moniteur:before {
  content: "b";
}

.icon-privatise:before {
  content: "c";
}

.icon-corbeille:before {
  content: "d";
}

.icon-arrow-down:before {
  content: "e";
}

.icon-arrow-left:before {
  content: "f";
}

.icon-child:before {
  content: "\e900";
}

.icon-adult:before {
  content: "\e901";
}

.icon-voucher:before {
  content: "\e902";
}

.icon-glass:before {
  content: "\e903";
}

.icon-laser:before {
  content: "g";
}

.icon-member:before {
  content: "h";
}

.header {
  background-color: rgba(0, 6, 10, 0.7);
  height: 110px;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.header .container {
  position: relative;
}
.headerLogo img {
  margin-top: 18px;
  width: 400px;
}
.headerBasket {
  position: absolute;
  top: 25px;
  right: 0;
  z-index: 2;
}
.headerBasketSee {
  color: #FFFFFF;
}
.headerBasketSee:hover {
  color: #747b86;
}

.menu {
  width: 170px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  display: block;
}
.menuContent {
  height: 100vh;
}
.menuItem, .menuItemSubMenuItem {
  height: 14.2857142857vh;
  list-style: none;
  text-align: center;
  background: #212631;
  color: #FFFFFF;
}
.menuItem:hover, .menuItemSubMenuItem:hover {
  background: #2A3646;
}
.menuItem:hover .menuItemIcon:before, .menuItemSubMenuItem:hover .menuItemIcon:before {
  background: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menuItemLibelle {
  font-size: 14px;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
}
.menuItemLink, .menuItemSubMenuItemLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #FFFFFF;
}
.menuItem i, .menuItemSubMenuItem i {
  font-size: 45px;
  font-size: 4.5rem;
}
.menuItemSubMenu {
  position: absolute;
  right: 170px;
  width: 170px;
  top: 0;
  display: none;
}
.menuItemSubMenuItem {
  background: #FFFFFF;
  color: #00060A;
}
.menuItemSubMenuItemLink {
  color: #00060A;
}
.menuItemSubMenuItemGreen i:before {
  background: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menuItemSubMenuItemGreen:hover {
  background-color: #1A9643;
  background-image: -webkit-gradient(linear, left top, right top, from(#1A9643), to(#C0CA51));
  background-image: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -moz-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -ms-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -o-linear-gradient(left, #1A9643, #C0CA51);
  background-image: linear-gradient(left, #1A9643, #C0CA51);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#1A9643", end-colourStr="#C0CA51", gradientType="1");
}
.menuItemSubMenuItemOrange i:before {
  background: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menuItemSubMenuItemOrange:hover {
  background-color: #EB6608;
  background-image: -webkit-gradient(linear, left top, right top, from(#EB6608), to(#FFC95C));
  background-image: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -moz-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -ms-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -o-linear-gradient(left, #EB6608, #FFC95C);
  background-image: linear-gradient(left, #EB6608, #FFC95C);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#EB6608", end-colourStr="#FFC95C", gradientType="1");
}
.menuItemSubMenuItemBlue i:before {
  background: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menuItemSubMenuItemBlue:hover {
  background-color: #2A4DAD;
  background-image: -webkit-gradient(linear, left top, right top, from(#2A4DAD), to(#04BAEE));
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -moz-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -ms-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -o-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: linear-gradient(left, #2A4DAD, #04BAEE);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#2A4DAD", end-colourStr="#04BAEE", gradientType="1");
}
.menuItemSubMenuItemPink i:before {
  background: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menuItemSubMenuItemPink:hover {
  background-color: #CC1E5E;
  background-image: -webkit-gradient(linear, left top, right top, from(#CC1E5E), to(#F0804D));
  background-image: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -moz-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -ms-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -o-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: linear-gradient(left, #CC1E5E, #F0804D);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#CC1E5E", end-colourStr="#F0804D", gradientType="1");
}
.menuItemSubMenuItem:hover {
  color: #FFFFFF;
}
.menuItemSubMenuItem:hover .menuItemSubMenuItemLink {
  color: #FFFFFF;
}
.menuItemSubMenuItem:hover i:before {
  background: -webkit-linear-gradient(left, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #FFFFFF;
}
.menuItem:first-child:hover .menuItemSubMenu, .menuItemSubMenuItem:first-child:hover .menuItemSubMenu {
  display: block !important;
}
.menuMobileOpen {
  background: #2A3646;
  color: #FFFFFF;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  padding-top: 15px;
  font-size: 25px;
  font-size: 2.5rem;
  z-index: 3000;
  display: none;
}
.menuMobile .menuItemLink, .menuMobile .menuItemSubMenuItemLink {
  flex-direction: row;
  justify-content: flex-start;
}

.footerBloc {
  padding: 50px;
}
.footerBlocTitle {
  margin-bottom: 15px;
}
.footerBlocList {
  margin-bottom: 10px;
}
.footerBlocListLink {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 600;
}
.footerBlocSocialLink {
  font-size: 24px;
  font-size: 2.4rem;
  margin-right: 15px;
}
.footerCopyright {
  background: #00060A;
  font-size: 14px;
  font-size: 1.4rem;
  height: 60px;
  padding: 20px 50px;
  color: #747b86;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 16px;
  font-size: 1.6rem;
  background-color: #0C1119;
  color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1, h2, h3, h4, .akTitle1, .akTitle2, .homeVideoInfosTitle, .akTitle3, .shopPicturesCardPrice, .homeActivityContentTitle, .akCardWinscapeInfosPrice, .akCardVirtualLaserInfosPrice, .akCardContentPrice, .akTitle4, .newsBlocTitleContentLibelle, .homeContactInfosTitle, .akCardTitleLibelle, .headerBasketPrice, .footerBlocTitle {
  font-weight: 800;
}

h5, h6, .akTitle5, .akTitle6, .memberKartingDetailPrice, .memberFreefallDetailPrice, .pricesFreeFallTitleAdult, .pricesFreeFallTitleChild, .pricesFreeFallTitleHours, .pricesFreeFallDetailPrice, .pricesKartingTitleAdult, .pricesKartingTitleChild, .pricesKartingTitleHours, .pricesKartingDetailPrice, .pricesVirtualLaserTitleAdult, .pricesVirtualLaserTitleChild, .pricesVirtualLaserTitleHours, .pricesVirtualLaserDetailPrice, .pricesWinscapeTitleAdult, .pricesWinscapeTitleChild, .pricesWinscapeTitleHours, .pricesWinscapeDetailPrice, .akCardContentText {
  font-weight: 400;
}

h1, .akTitle1 {
  font-size: 40px;
  font-size: 4rem;
  line-height: 5.2rem;
}

h1 > span {
  display: block;
}

h2, .akTitle2, .homeVideoInfosTitle {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 3.8rem;
  position: relative;
}

.akTitle2:before, .homeVideoInfosTitle:before {
  content: "";
  width: 500px;
  margin-left: -500px;
  height: 1px;
  background: #FFFFFF;
  position: absolute;
  left: -30px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.akTitle2.withNoLine:before, .withNoLine.homeVideoInfosTitle:before {
  display: none;
}

h3, .akTitle3, .shopPicturesCardPrice, .homeActivityContentTitle, .akCardWinscapeInfosPrice, .akCardVirtualLaserInfosPrice, .akCardContentPrice {
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 3.2rem;
}

h4, h5, .akTitle4, .newsBlocTitleContentLibelle, .homeContactInfosTitle, .akCardTitleLibelle, .headerBasketPrice, .footerBlocTitle, .akTitle5 {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 2.5rem;
  text-transform: uppercase;
}

h6, .akTitle6, .memberKartingDetailPrice, .memberFreefallDetailPrice, .pricesFreeFallTitleAdult, .pricesFreeFallTitleChild, .pricesFreeFallTitleHours, .pricesFreeFallDetailPrice, .pricesKartingTitleAdult, .pricesKartingTitleChild, .pricesKartingTitleHours, .pricesKartingDetailPrice, .pricesVirtualLaserTitleAdult, .pricesVirtualLaserTitleChild, .pricesVirtualLaserTitleHours, .pricesVirtualLaserDetailPrice, .pricesWinscapeTitleAdult, .pricesWinscapeTitleChild, .pricesWinscapeTitleHours, .pricesWinscapeDetailPrice, .akCardContentText {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2.2rem;
}

/* Titles responsive min 600px */
@media screen and (min-width: 600px) {
  h1, .akTitle1 {
    font-size: 50px;
    font-size: 5rem;
    line-height: 5.8rem;
  }

  h2, .akTitle2, .homeVideoInfosTitle {
    font-size: 40px;
    font-size: 4rem;
    line-height: 4.5rem;
    position: relative;
  }

  h3, .akTitle3, .shopPicturesCardPrice, .homeActivityContentTitle, .akCardWinscapeInfosPrice, .akCardVirtualLaserInfosPrice, .akCardContentPrice {
    font-size: 30px;
    font-size: 3rem;
    line-height: 3.3rem;
  }

  h4, h5, .akTitle4, .newsBlocTitleContentLibelle, .homeContactInfosTitle, .akCardTitleLibelle, .headerBasketPrice, .footerBlocTitle, .akTitle5 {
    font-size: 23px;
    font-size: 2.3rem;
    line-height: 2.6rem;
    text-transform: uppercase;
  }

  h6, .akTitle6, .memberKartingDetailPrice, .memberFreefallDetailPrice, .pricesFreeFallTitleAdult, .pricesFreeFallTitleChild, .pricesFreeFallTitleHours, .pricesFreeFallDetailPrice, .pricesKartingTitleAdult, .pricesKartingTitleChild, .pricesKartingTitleHours, .pricesKartingDetailPrice, .pricesVirtualLaserTitleAdult, .pricesVirtualLaserTitleChild, .pricesVirtualLaserTitleHours, .pricesVirtualLaserDetailPrice, .pricesWinscapeTitleAdult, .pricesWinscapeTitleChild, .pricesWinscapeTitleHours, .pricesWinscapeDetailPrice, .akCardContentText {
    font-size: 20px;
    font-size: 2rem;
    line-height: 2.4rem;
  }
}
/* Titles responsive min 992px */
@media screen and (min-width: 992px) {
  h1, .akTitle1 {
    font-size: 80px;
    font-size: 8rem;
    line-height: 8.4rem;
  }

  h2, .akTitle2, .homeVideoInfosTitle {
    font-size: 48px;
    font-size: 4.8rem;
    line-height: 5.5rem;
    position: relative;
  }

  h3, .akTitle3, .shopPicturesCardPrice, .homeActivityContentTitle, .akCardWinscapeInfosPrice, .akCardVirtualLaserInfosPrice, .akCardContentPrice {
    font-size: 36px;
    font-size: 3.6rem;
    line-height: 4rem;
  }

  h4, h5, .akTitle4, .newsBlocTitleContentLibelle, .homeContactInfosTitle, .akCardTitleLibelle, .headerBasketPrice, .footerBlocTitle, .akTitle5 {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 2.8rem;
    text-transform: uppercase;
  }

  h6, .akTitle6, .memberKartingDetailPrice, .memberFreefallDetailPrice, .pricesFreeFallTitleAdult, .pricesFreeFallTitleChild, .pricesFreeFallTitleHours, .pricesFreeFallDetailPrice, .pricesKartingTitleAdult, .pricesKartingTitleChild, .pricesKartingTitleHours, .pricesKartingDetailPrice, .pricesVirtualLaserTitleAdult, .pricesVirtualLaserTitleChild, .pricesVirtualLaserTitleHours, .pricesVirtualLaserDetailPrice, .pricesWinscapeTitleAdult, .pricesWinscapeTitleChild, .pricesWinscapeTitleHours, .pricesWinscapeDetailPrice, .akCardContentText {
    font-size: 21px;
    font-size: 2.1rem;
    line-height: 2.5rem;
  }
}
nav {
  box-shadow: none;
  z-index: 2;
}

strong {
  font-weight: bold;
}

.clear {
  clear: both;
}

.akTextSecondary {
  color: #747b86;
}
.akTextPrimary {
  font-weight: bold;
  text-transform: uppercase;
}

a {
  color: #747b86;
}
a:hover {
  color: #FFFFFF;
}

.akLinkPrimary {
  font-weight: bold;
  color: #FFFFFF;
  white-space: nowrap;
}
.akLinkFreeFall {
  color: #93C01F;
}
.akLinkKarting {
  color: #EB6608;
}
.akLinkWinscape {
  color: #04BAEE;
}
.akLinkVirtualLaser {
  color: #F0804D;
}

a:focus, input:focus {
  outline: 0 !important;
}

ul {
  padding: 0;
}

.akContent {
  width: calc(100% - 170px);
  position: relative;
}

.akMainContent {
  background: url("../../images/bg-content.png") repeat;
  position: relative;
}

.akContainerPage {
  padding-top: 160px;
  overflow: hidden;
  min-height: 560px;
}

.akBreadCrumb {
  color: #747b86;
  margin-bottom: 10px;
}

.akBgBloc, .shopPictures .pika-single, .shopPicturesAddPanier, .shopPicturesCard, .shopPicturesMenu .creneau .dropdown-content.select-dropdown, .shopPicturesMenu, .shopCartContent, .shopCardCalendar, .shopCardReservationBox, .shopCardSlots, .shopCardBooking, .homeVideoMask {
  background-color: rgba(0, 6, 10, 0.7);
}

.akBgBlocLight, .newsBlocContent, .shopPictures .pika-single thead, .shopCardCalendar thead, .shopCardGoToShop {
  background-color: rgba(153, 204, 255, 0.12);
}

.akTextGrey {
  color: #747b86;
}

.akTextRed {
  color: #D6002C;
}

.akTextOrange {
  color: #EB6608;
}

.akTextGreen {
  color: #93C01F;
}

.akError, .akSuccess {
  color: #D6002C;
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  margin: -15px 0 30px 5px;
  font-style: italic;
}

.akSuccess {
  color: #93C01F;
}

/* Ajustement de la grille materialize */
.container .row {
  margin-left: -20px;
  margin-right: -20px;
}

.row .col {
  padding: 0 20px;
}

/* Petite gouttière */
.container .gutter-s {
  margin-left: -10px;
  margin-right: -10px;
}

.gutter-s .col {
  padding: 0 10px;
}

.clearfix {
  height: 1%;
}

.clearfix:after {
  content: ".";
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}

.akFlexColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  /* added line */
  border: 0;
}

.mts {
  margin-top: 10px;
}

.mtm {
  margin-top: 20px;
}

.mtl {
  margin-top: 30px;
}

.mtn {
  margin-top: 0;
}

.mrs {
  margin-right: 10px;
}

.mrm {
  margin-right: 20px;
}

.mrl {
  margin-right: 30px;
}

.mrn {
  margin-right: 0;
}

.mbs {
  margin-bottom: 10px;
}

.mbm {
  margin-bottom: 20px;
}

.mbl {
  margin-bottom: 30px;
}

.mbn {
  margin-bottom: 0;
}

.mls {
  margin-left: 10px;
}

.mlm {
  margin-left: 20px;
}

.mll {
  margin-left: 30px;
}

.mln {
  margin-left: 0;
}

.nm {
  margin: 0;
}

.spacing {
  padding: 20px;
}

.pts {
  padding-top: 10px;
}

.ptm {
  padding-top: 20px;
}

.ptl {
  padding-top: 30px;
}

.ptn {
  padding-top: 0;
}

.prs {
  padding-right: 10px;
}

.prm {
  padding-right: 20px;
}

.prl {
  padding-right: 30px;
}

.prn {
  padding-right: 0;
}

.pbs {
  padding-bottom: 10px;
}

.pbm {
  padding-bottom: 20px;
}

.pbl {
  padding-bottom: 30px;
}

.pbn {
  padding-bottom: 0;
}

.pls {
  padding-left: 10px;
}

.plm {
  padding-left: 20px;
}

.pll {
  padding-left: 30px;
}

.pln {
  padding-left: 0;
}

.np {
  padding: 0;
}

.tdn {
  text-decoration: none;
}

.tdu {
  text-decoration: underline;
}

.ttu {
  text-transform: uppercase;
}

.row.ns {
  margin: 0;
}
.row.ns .col {
  padding: 0;
}

.dispInlb {
  display: inline-block;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

.underline {
  text-decoration: underline;
}

.header {
  background-color: rgba(0, 6, 10, 0.7);
  height: 110px;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.header .container {
  position: relative;
}
.headerLogo img {
  margin-top: 18px;
  width: 400px;
}
.headerBasket {
  position: absolute;
  top: 25px;
  right: 0;
  z-index: 2;
}
.headerBasketSee {
  color: #FFFFFF;
}
.headerBasketSee:hover {
  color: #747b86;
}

.menu {
  width: 170px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  display: block;
}
.menuContent {
  height: 100vh;
}
.menuItem, .menuItemSubMenuItem {
  height: 14.2857142857vh;
  list-style: none;
  text-align: center;
  background: #212631;
  color: #FFFFFF;
}
.menuItem:hover, .menuItemSubMenuItem:hover {
  background: #2A3646;
}
.menuItem:hover .menuItemIcon:before, .menuItemSubMenuItem:hover .menuItemIcon:before {
  background: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menuItemLibelle {
  font-size: 14px;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
}
.menuItemLink, .menuItemSubMenuItemLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #FFFFFF;
}
.menuItem i, .menuItemSubMenuItem i {
  font-size: 45px;
  font-size: 4.5rem;
}
.menuItemSubMenu {
  position: absolute;
  right: 170px;
  width: 170px;
  top: 0;
  display: none;
}
.menuItemSubMenuItem {
  background: #FFFFFF;
  color: #00060A;
}
.menuItemSubMenuItemLink {
  color: #00060A;
}
.menuItemSubMenuItemGreen i:before {
  background: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menuItemSubMenuItemGreen:hover {
  background-color: #1A9643;
  background-image: -webkit-gradient(linear, left top, right top, from(#1A9643), to(#C0CA51));
  background-image: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -moz-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -ms-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -o-linear-gradient(left, #1A9643, #C0CA51);
  background-image: linear-gradient(left, #1A9643, #C0CA51);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#1A9643", end-colourStr="#C0CA51", gradientType="1");
}
.menuItemSubMenuItemOrange i:before {
  background: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menuItemSubMenuItemOrange:hover {
  background-color: #EB6608;
  background-image: -webkit-gradient(linear, left top, right top, from(#EB6608), to(#FFC95C));
  background-image: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -moz-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -ms-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -o-linear-gradient(left, #EB6608, #FFC95C);
  background-image: linear-gradient(left, #EB6608, #FFC95C);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#EB6608", end-colourStr="#FFC95C", gradientType="1");
}
.menuItemSubMenuItemBlue i:before {
  background: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menuItemSubMenuItemBlue:hover {
  background-color: #2A4DAD;
  background-image: -webkit-gradient(linear, left top, right top, from(#2A4DAD), to(#04BAEE));
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -moz-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -ms-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -o-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: linear-gradient(left, #2A4DAD, #04BAEE);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#2A4DAD", end-colourStr="#04BAEE", gradientType="1");
}
.menuItemSubMenuItemPink i:before {
  background: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menuItemSubMenuItemPink:hover {
  background-color: #CC1E5E;
  background-image: -webkit-gradient(linear, left top, right top, from(#CC1E5E), to(#F0804D));
  background-image: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -moz-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -ms-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -o-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: linear-gradient(left, #CC1E5E, #F0804D);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#CC1E5E", end-colourStr="#F0804D", gradientType="1");
}
.menuItemSubMenuItem:hover {
  color: #FFFFFF;
}
.menuItemSubMenuItem:hover .menuItemSubMenuItemLink {
  color: #FFFFFF;
}
.menuItemSubMenuItem:hover i:before {
  background: -webkit-linear-gradient(left, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #FFFFFF;
}
.menuItem:first-child:hover .menuItemSubMenu, .menuItemSubMenuItem:first-child:hover .menuItemSubMenu {
  display: block !important;
}
.menuMobileOpen {
  background: #2A3646;
  color: #FFFFFF;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  padding-top: 15px;
  font-size: 25px;
  font-size: 2.5rem;
  z-index: 3000;
  display: none;
}
.menuMobile .menuItemLink, .menuMobile .menuItemSubMenuItemLink {
  flex-direction: row;
  justify-content: flex-start;
}

.footerBloc {
  padding: 50px;
}
.footerBlocTitle {
  margin-bottom: 15px;
}
.footerBlocList {
  margin-bottom: 10px;
}
.footerBlocListLink {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 600;
}
.footerBlocSocialLink {
  font-size: 24px;
  font-size: 2.4rem;
  margin-right: 15px;
}
.footerCopyright {
  background: #00060A;
  font-size: 14px;
  font-size: 1.4rem;
  height: 60px;
  padding: 20px 50px;
  color: #747b86;
}

.akBtn, .akFilterItemLinkAll, .akBtnDisabled, .shopCardSlotsItemImpossible, .akBtnWhite, .akFilterItem.active .shopNavFilterItemLink, .akBtnPink, .akBtnBlue, .akBtnOrange, .akBtnGreen, .akBtnFilter, .akFilterItemLink {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 8px 18px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #FFFFFF;
  text-align: center;
  background: #2A3646;
  border: 0;
  font-style: normal;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.akBtn:hover, .akFilterItemLinkAll:hover, .akBtnDisabled:hover, .shopCardSlotsItemImpossible:hover, .akBtnWhite:hover, .akFilterItem.active .shopNavFilterItemLink:hover, .akBtnPink:hover, .akBtnBlue:hover, .akBtnOrange:hover, .akBtnGreen:hover, .akBtnFilter:hover, .akFilterItemLink:hover {
  opacity: 0.9;
}
.akBtnFilter, .akFilterItemLink {
  background: transparent;
}
.akBtnFilterGreen, .akFilterItemLinkGreen {
  border-left: 1px solid #1A9643;
  border-right: 1px solid #C0CA51;
  background-image: -webkit-linear-gradient(left, #1A9643, #C0CA51), -webkit-linear-gradient(left, #1A9643, #C0CA51);
  background-size: 100% 1px;
  background-position: 0 100%, 0 0;
  background-repeat: no-repeat;
  background-clip: border-box;
}
.akBtnFilterGreen:hover, .akFilterItemLinkGreen:hover {
  background: none;
  background-color: #1A9643;
  background-image: -webkit-gradient(linear, left top, right top, from(#1A9643), to(#C0CA51));
  background-image: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -moz-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -ms-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -o-linear-gradient(left, #1A9643, #C0CA51);
  background-image: linear-gradient(left, #1A9643, #C0CA51);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#1A9643", end-colourStr="#C0CA51", gradientType="1");
}
.akBtnFilterOrange, .akFilterItemLinkOrange {
  border-left: 1px solid #EB6608;
  border-right: 1px solid #FFC95C;
  background-image: -webkit-linear-gradient(left, #EB6608, #FFC95C), -webkit-linear-gradient(left, #EB6608, #FFC95C);
  background-size: 100% 1px;
  background-position: 0 100%, 0 0;
  background-repeat: no-repeat;
  background-clip: border-box;
}
.akBtnFilterOrange:hover, .akFilterItemLinkOrange:hover {
  background: none;
  background-color: #EB6608;
  background-image: -webkit-gradient(linear, left top, right top, from(#EB6608), to(#FFC95C));
  background-image: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -moz-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -ms-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -o-linear-gradient(left, #EB6608, #FFC95C);
  background-image: linear-gradient(left, #EB6608, #FFC95C);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#EB6608", end-colourStr="#FFC95C", gradientType="1");
}
.akBtnFilterBlue, .shopCardSlotsItemChoose, .akFilterItemLinkBlue {
  border-left: 1px solid #2A4DAD;
  border-right: 1px solid #04BAEE;
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE), -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-size: 100% 1px;
  background-position: 0 100%, 0 0;
  background-repeat: no-repeat;
  background-clip: border-box;
}
.akBtnFilterBlue:hover, .shopCardSlotsItemChoose:hover, .akFilterItemLinkBlue:hover {
  background: none;
  background-color: #2A4DAD;
  background-image: -webkit-gradient(linear, left top, right top, from(#2A4DAD), to(#04BAEE));
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -moz-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -ms-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -o-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: linear-gradient(left, #2A4DAD, #04BAEE);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#2A4DAD", end-colourStr="#04BAEE", gradientType="1");
}
.akBtnFilterPink, .akFilterItemLinkPink {
  border-left: 1px solid #CC1E5E;
  border-right: 1px solid #F0804D;
  background-image: -webkit-linear-gradient(left, #CC1E5E, #F0804D), -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  background-size: 100% 1px;
  background-position: 0 100%, 0 0;
  background-repeat: no-repeat;
  background-clip: border-box;
}
.akBtnFilterPink:hover, .akFilterItemLinkPink:hover {
  background: none;
  background-color: #CC1E5E;
  background-image: -webkit-gradient(linear, left top, right top, from(#CC1E5E), to(#F0804D));
  background-image: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -moz-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -ms-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -o-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: linear-gradient(left, #CC1E5E, #F0804D);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#CC1E5E", end-colourStr="#F0804D", gradientType="1");
}
.akBtnGreen {
  background-color: #1A9643;
  background-image: -webkit-gradient(linear, left top, right top, from(#1A9643), to(#C0CA51));
  background-image: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -moz-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -ms-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -o-linear-gradient(left, #1A9643, #C0CA51);
  background-image: linear-gradient(left, #1A9643, #C0CA51);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#1A9643", end-colourStr="#C0CA51", gradientType="1");
}
.akBtnOrange {
  background-color: #EB6608;
  background-image: -webkit-gradient(linear, left top, right top, from(#EB6608), to(#FFC95C));
  background-image: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -moz-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -ms-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -o-linear-gradient(left, #EB6608, #FFC95C);
  background-image: linear-gradient(left, #EB6608, #FFC95C);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#EB6608", end-colourStr="#FFC95C", gradientType="1");
}
.akBtnBlue {
  background-color: #2A4DAD;
  background-image: -webkit-gradient(linear, left top, right top, from(#2A4DAD), to(#04BAEE));
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -moz-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -ms-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -o-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: linear-gradient(left, #2A4DAD, #04BAEE);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#2A4DAD", end-colourStr="#04BAEE", gradientType="1");
}
.akBtnPink {
  background-color: #CC1E5E;
  background-image: -webkit-gradient(linear, left top, right top, from(#CC1E5E), to(#F0804D));
  background-image: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -moz-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -ms-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -o-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: linear-gradient(left, #CC1E5E, #F0804D);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#CC1E5E", end-colourStr="#F0804D", gradientType="1");
}
.akBtnWhite, .akFilterItem.active .shopNavFilterItemLink {
  color: #00060A;
  background: #FFFFFF;
}
.akBtnWithIcon, .akBtnWithIconLeft {
  padding-right: 40px;
}
.akBtnWithIcon i:before, .akBtnWithIconLeft i:before {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  right: 15px;
  top: 11px;
}
.akBtnWithIcon:hover i:before, .akBtnWithIconLeft:hover i:before {
  right: 10px;
}
.akBtnWithIconLeft {
  padding-right: 18px;
  padding-left: 40px;
}
.akBtnWithIconLeft i:before {
  left: 15px;
  right: inherit;
}
.akBtnWithIconLeft:hover i:before {
  left: 10px;
  right: inherit;
}
.akBtnDisabled, .shopCardSlotsItemImpossible {
  background: #747b86;
  color: #00060A;
  cursor: none;
}
.akBtnDisabled:hover, .shopCardSlotsItemImpossible:hover {
  color: #00060A;
  opacity: 1;
}

.akForm [type=checkbox].filled-in:checked + label:after {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}
.akForm [type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #00060A;
  border-bottom: 2px solid #00060A;
}
.akForm [type=checkbox] + label {
  font-size: 16px;
  font-size: 1.6rem;
  color: #FFFFFF;
}
.akForm [type=checkbox] + label a {
  color: #747b86;
}
.akForm [type=checkbox] + label a:hover {
  color: #FFFFFF;
}
.akFormInputField {
  background-color: rgba(153, 204, 255, 0.12);
  margin-top: 0;
}
.akFormInputField input:not([type]),
.akFormInputField input[type=text],
.akFormInputField input[type=password],
.akFormInputField input[type=email],
.akFormInputField input[type=url],
.akFormInputField input[type=time],
.akFormInputField input[type=date],
.akFormInputField input[type=datetime],
.akFormInputField input[type=datetime-local],
.akFormInputField input[type=tel],
.akFormInputField input[type=number],
.akFormInputField input[type=search],
.akFormInputField textarea.materialize-textarea {
  margin-bottom: 0;
  border-bottom: 0;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 400;
  padding: 10px 20px 0 20px;
  min-height: 45px;
  position: relative;
  box-sizing: border-box;
}
.akFormInputField label {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #747b86;
  left: 20px;
  top: 10px;
}
.akFormInputField label:not(.label-icon).active {
  font-size: 10px;
  font-size: 1rem;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  top: 3px;
  color: #747b86;
}
.akFormInputField input:not([type]):focus:not([readonly]) + label, .akFormInputField input[type=text]:focus:not([readonly]) + label, .akFormInputField input[type=password]:focus:not([readonly]) + label, .akFormInputField input[type=email]:focus:not([readonly]) + label, .akFormInputField input[type=url]:focus:not([readonly]) + label, .akFormInputField input[type=time]:focus:not([readonly]) + label, .akFormInputField input[type=date]:focus:not([readonly]) + label, .akFormInputField input[type=datetime]:focus:not([readonly]) + label, .akFormInputField input[type=datetime-local]:focus:not([readonly]) + label, .akFormInputField input[type=tel]:focus:not([readonly]) + label, .akFormInputField input[type=number]:focus:not([readonly]) + label, .akFormInputField input[type=search]:focus:not([readonly]) + label, .akFormInputField textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #04BAEE;
}
.akFormInputField input:not([type]):focus:not([readonly]), .akFormInputField input[type=text]:focus:not([readonly]), .akFormInputField input[type=password]:focus:not([readonly]), .akFormInputField input[type=email]:focus:not([readonly]), .akFormInputField input[type=url]:focus:not([readonly]), .akFormInputField input[type=time]:focus:not([readonly]), .akFormInputField input[type=date]:focus:not([readonly]), .akFormInputField input[type=datetime]:focus:not([readonly]), .akFormInputField input[type=datetime-local]:focus:not([readonly]), .akFormInputField input[type=tel]:focus:not([readonly]), .akFormInputField input[type=number]:focus:not([readonly]), .akFormInputField input[type=search]:focus:not([readonly]), .akFormInputField textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #04BAEE;
  box-shadow: 0 1px 0 0 #04BAEE;
}
.akFormInputField .select-wrapper + label {
  font-size: 10px;
  font-size: 1rem;
  top: 3px;
}
.akFormInputField .select-wrapper span.caret {
  right: 15px;
  color: #747b86;
}
.akFormInputField .dropdown-content {
  background-color: #0C1119;
}
.akFormInputField .dropdown-content li > a, .akFormInputField .dropdown-content li > span {
  color: #FFFFFF;
}
.akFormInputField .dropdown-content li:hover, .akFormInputField .dropdown-content li.selected {
  background-color: #111824;
}
.akFormTextArea, .akFormTextAreaSmall {
  height: 150px;
}
.akFormTextArea textarea.materialize-textarea, .akFormTextAreaSmall textarea.materialize-textarea {
  height: 100% !important;
  padding: 20px;
}
.akFormTextAreaSmall {
  height: 100px;
}

.akCard {
  color: #FFFFFF;
  background-color: rgba(0, 6, 10, 0.5);
  margin-bottom: 40px;
  /* Cards page winscape */
}
.akCard.akCardNoBtn .akCardContentPrice {
  float: right;
}
.akCard.akCardBasket .akCardContentPrice {
  font-size: 21px;
  font-size: 2.1rem;
  font-weight: 400;
  float: right;
}
.akCard.akCardBlue .akCardTitle {
  background-image: url("../../images/cards/header-winscape.jpg");
}
.akCard.akCardOrange .akCardTitle {
  background-image: url("../../images/cards/header-karting.jpg");
}
.akCard.akCardPink .akCardTitle {
  background-image: url("../../images/cards/header-event.jpg");
}
.akCard.akCardGreen .akCardTitle {
  background-image: url("../../images/cards/header-chute-libre.jpg");
}
.akCardTitle {
  padding: 11px 20px;
  background-size: cover;
  position: relative;
}
.akCardTitleDelete {
  position: absolute;
  right: 20px;
  top: 13px;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 14px;
  font-size: 1.4rem;
}
.akCardTitleDelete i {
  font-size: 16px;
  font-size: 1.6rem;
  vertical-align: middle;
}
.akCardContent {
  padding: 15px 20px;
  overflow: hidden;
}
.akCardContentContainer {
  display: flex;
  flex-direction: column;
}
.akCardContentText {
  margin-bottom: 10px;
}
.akCardContentTextSmall {
  font-weight: 300;
  font-size: 16px;
  font-size: 1.6rem;
  color: #747b86;
  margin-bottom: 10px;
}
.akCardContentRow {
  width: 100%;
  content: "";
  display: table;
  clear: both;
}
.akCardContentPrice {
  margin-left: auto;
  display: inline-block;
}
.akCardContentPriceFrom {
  font-weight: 400;
  font-size: 16px;
  font-size: 1.6rem;
}
.akCardContentPriceDetail {
  font-size: 16px;
  font-size: 1.6rem;
  color: #FFFFFF;
  font-weight: 400;
}
.akCardContentAdult, .akCardContentChild {
  float: left;
  width: 50%;
}
.akCardContentInfos {
  float: left;
}
.akCardIconChild, .akCardIconAdult {
  margin-top: 10px;
}
.akCardIconChild, .akCardIconAdult, .akCardIconVoucher {
  font-size: 45px;
  font-size: 4.5rem;
  display: inline-block;
  color: #747b86;
  margin-right: 10px;
  float: left;
}
.akCardAdult, .akCardChild {
  display: block;
  color: #747b86;
}
.akCard [class^=akBtn] {
  float: right;
}
.akCardWinscape, .akCardVirtualLaser {
  margin-bottom: 0;
}
.akCardWinscapeImg, .akCardVirtualLaserImg {
  width: 100%;
}
.akCardWinscapeInfos, .akCardVirtualLaserInfos {
  text-align: center;
  padding: 20px;
}
.akFilter {
  background: none;
  line-height: inherit;
  margin: 50px 0;
}
.akFilter:after {
  content: "";
  display: table;
  clear: both;
}
.akFilter .categories-container.pinned {
  padding: 20px 20px 10px 20px;
  background: #0C1119;
  width: 100%;
  left: 0;
  padding-left: 6%;
}
.akFilterTarget {
  float: right;
  margin-top: 10px;
}
.akFilterTarget label {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 600;
  color: #FFFFFF;
  margin-left: 10px;
}
.akFilterTarget [type=checkbox].filled-in:checked + label:after {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}
.akFilterTarget [type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #00060A;
  border-bottom: 2px solid #00060A;
}
.akFilterItem {
  height: 50px;
}
.akFilterItem.active > .akFilterItemLinkGreen {
  background: none;
  background-color: #1A9643;
  background-image: -webkit-gradient(linear, left top, right top, from(#1A9643), to(#C0CA51));
  background-image: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -moz-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -ms-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -o-linear-gradient(left, #1A9643, #C0CA51);
  background-image: linear-gradient(left, #1A9643, #C0CA51);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#1A9643", end-colourStr="#C0CA51", gradientType="1");
}
.akFilterItem.active > .akFilterItemLinkOrange {
  background: none;
  background-color: #EB6608;
  background-image: -webkit-gradient(linear, left top, right top, from(#EB6608), to(#FFC95C));
  background-image: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -moz-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -ms-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -o-linear-gradient(left, #EB6608, #FFC95C);
  background-image: linear-gradient(left, #EB6608, #FFC95C);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#EB6608", end-colourStr="#FFC95C", gradientType="1");
}
.akFilterItem.active > .akFilterItemLinkBlue {
  background: none;
  background-color: #2A4DAD;
  background-image: -webkit-gradient(linear, left top, right top, from(#2A4DAD), to(#04BAEE));
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -moz-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -ms-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -o-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: linear-gradient(left, #2A4DAD, #04BAEE);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#2A4DAD", end-colourStr="#04BAEE", gradientType="1");
}
.akFilterItem.active > .akFilterItemLinkPink {
  background: none;
  background-color: #CC1E5E;
  background-image: -webkit-gradient(linear, left top, right top, from(#CC1E5E), to(#F0804D));
  background-image: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -moz-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -ms-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -o-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: linear-gradient(left, #CC1E5E, #F0804D);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#CC1E5E", end-colourStr="#F0804D", gradientType="1");
}
.akFilterItem.active:before {
  display: none;
}
.akFilterItem.active .shopNavFilterItemLinkGreen {
  background: none;
  color: #FFFFFF;
  background-color: #1A9643;
  background-image: -webkit-gradient(linear, left top, right top, from(#1A9643), to(#C0CA51));
  background-image: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -moz-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -ms-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -o-linear-gradient(left, #1A9643, #C0CA51);
  background-image: linear-gradient(left, #1A9643, #C0CA51);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#1A9643", end-colourStr="#C0CA51", gradientType="1");
}
.akFilterItem.active .shopNavFilterItemLinkBlue {
  background: none;
  color: #FFFFFF;
  background-color: #2A4DAD;
  background-image: -webkit-gradient(linear, left top, right top, from(#2A4DAD), to(#04BAEE));
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -moz-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -ms-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -o-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: linear-gradient(left, #2A4DAD, #04BAEE);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#2A4DAD", end-colourStr="#04BAEE", gradientType="1");
}
.akFilterItem.active .shopNavFilterItemLinkOrange {
  background: none;
  color: #FFFFFF;
  background-color: #EB6608;
  background-image: -webkit-gradient(linear, left top, right top, from(#EB6608), to(#FFC95C));
  background-image: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -moz-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -ms-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -o-linear-gradient(left, #EB6608, #FFC95C);
  background-image: linear-gradient(left, #EB6608, #FFC95C);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#EB6608", end-colourStr="#FFC95C", gradientType="1");
}
.akFilterItem.active .shopNavFilterItemLinkPink {
  background: none;
  color: #FFFFFF;
  background-color: #CC1E5E;
  background-image: -webkit-gradient(linear, left top, right top, from(#CC1E5E), to(#F0804D));
  background-image: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -moz-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -ms-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -o-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: linear-gradient(left, #CC1E5E, #F0804D);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#CC1E5E", end-colourStr="#F0804D", gradientType="1");
}
.akFilterItemLink {
  margin: 0 20px 10px 0;
}
.akFilterItemLinkAll {
  background: transparent;
  border: 1px solid #FFFFFF;
}
.slideshow {
  overflow: hidden;
  padding-bottom: 40px;
  text-align: center;
  /* Dots */
}
.slideshowContent {
  width: calc(100% * 4);
}
.slideshow [class^=slideshowDot] {
  margin: 20px 15px;
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #FFFFFF;
  transition: all 0.3s ease;
  cursor: pointer;
}
.slideshow #dot1:checked ~ .slideshowDot1,
.slideshow #dot2:checked ~ .slideshowDot2,
.slideshow #dot3:checked ~ .slideshowDot3,
.slideshow #dot4:checked ~ .slideshowDot4 {
  background: none;
  border: solid 2px #FFFFFF;
}
.slideshow #dot1:checked ~ .slideshowContent [class^=slideshowItem] {
  transform: translateX(0);
}
.slideshow #dot2:checked ~ .slideshowContent [class^=slideshowItem] {
  transform: translateX(-100%);
}
.slideshow #dot3:checked ~ .slideshowContent [class^=slideshowItem] {
  transform: translateX(-200%);
}
.slideshow #dot4:checked ~ .slideshowContent [class^=slideshowItem] {
  transform: translateX(-300%);
}
.slideshow [class^=slideshowItem] {
  width: 25%;
  height: 100%;
  float: left;
  transition: transform 0.2s ease;
}
.slideshow [class^=slideshowItem] img {
  height: auto;
  width: 100%;
  display: block;
}

html,
body {
  height: 100%;
}

body.gallery {
  font-family: "Lato", sans-serif;
}

ul {
  margin: 0;
}

.caption {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 30px;
  color: #666;
}

.section {
  padding: 1.5rem 0;
}

.container {
  max-width: none;
}

.valign-wrapper {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.dropdown-button i.right {
  margin-left: 5px;
}

nav {
  position: relative;
  top: 0;
  transition: background-color 0.3s, opacity 0.2s, visibility 0s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
}
nav.fadeOut {
  transition: background-color 0.3s, opacity 0.2s, visibility 0s 0.2s;
  opacity: 0;
  visibility: hidden;
}
nav.nav-full-header {
  height: 100%;
}
nav.nav-full-header .nav-header {
  height: calc(100% - 128px);
}
nav.nav-full-header ~ .nav-categories {
  top: -64px;
}
nav ul:not(.indicators) li.active {
  position: relative;
  background-color: transparent;
}
nav ul:not(.indicators) li.active::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: #fff;
}
nav .nav-background {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.1;
}
nav .nav-background img,
nav .nav-background .pattern {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  margin: 0 auto;
  max-width: 100%;
  filter: grayscale(100%);
  transition: opacity 0.7s;
}
nav .nav-background img.active,
nav .nav-background .pattern.active {
  opacity: 1;
}
nav .nav-background .pattern {
  top: 0;
  bottom: 0;
  transform: none;
  background-repeat: repeat;
}
nav .nav-background .full-width {
  min-width: 100%;
}
nav .nav-header {
  word-wrap: break-word;
  clear: both;
  padding: 40px 0;
}
nav .nav-header h1 {
  font-size: 56px;
  font-weight: 600;
}
nav .nav-header .tagline {
  display: block;
  margin: -40px 0 40px 0;
  color: rgba(255, 255, 255, 0.8);
}
nav .categories-wrapper {
  background-color: inherit;
  height: 64px;
}
nav .categories-wrapper .categories-container {
  background-color: inherit;
  white-space: nowrap;
  overflow: auto;
}
nav .categories-wrapper .categories-container.pinned {
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
nav .categories-wrapper .categories-container .categories > li {
  float: none;
  display: inline-block;
}

.gallery {
  position: relative;
}
.gallery.gallery-collection .gallery-item {
  margin-bottom: 1px;
}
.gallery .gallery-item {
  margin-bottom: 1.5rem;
}
.gallery .gallery-item .card {
  margin: 0;
}
.gallery .gallery-horizontal .gallery-curve-wrapper {
  display: flex;
  display: -ms-flexbox;
}
.gallery .gallery-horizontal .gallery-cover {
  height: 100%;
  width: auto;
  max-width: 50%;
}
.gallery .gallery-horizontal .gallery-header {
  flex: 1;
}
.gallery .gallery-expand.active {
  z-index: 9;
}
.gallery .gallery-expand.active .gallery-curve-wrapper {
  padding-top: 64px;
  cursor: default;
  box-shadow: none;
}
.gallery .gallery-expand.active .gallery-curve-wrapper .gallery-cover {
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.gallery .gallery-expand[data-fillscreen=true] .gallery-curve-wrapper .gallery-header,
.gallery .gallery-expand[data-fillscreen=true] .gallery-curve-wrapper .gallery-body,
.gallery .gallery-expand[data-fillscreen=true] .gallery-curve-wrapper .gallery-action,
.gallery .gallery-expand[data-fillscreen=true] .gallery-curve-wrapper .gallery-cover {
  z-index: 1;
}
.gallery .gallery-expand[data-fillscreen=true] .gallery-curve-wrapper .gallery-cover {
  box-shadow: none;
  pointer-events: none;
}
.gallery .gallery-expand[data-fillscreen=true] .gallery-curve-wrapper .gallery-cover.hover {
  opacity: 0;
}
.gallery .gallery-expand[data-fillscreen=true] .gallery-curve-wrapper .gallery-cover.static {
  margin: 0;
}
.gallery .gallery-expand[data-fillscreen=true] .gallery-curve-wrapper .gallery-cover .gradient {
  background: transparent;
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
}
.gallery .gallery-expand .gallery-curve-wrapper {
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.gallery .gallery-cover {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  transform-origin: 0 0;
  transition: top 0.5s;
  z-index: 2;
}
.gallery .gallery-cover.static {
  margin-left: 15%;
}
.gallery .gallery-cover.static img {
  min-width: 100%;
}
.gallery .gallery-cover img {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  min-width: 100%;
  width: auto;
  display: block;
  user-select: none;
}
.gallery .gallery-header {
  position: relative;
  padding: 20px;
  background-color: #fff;
  color: #444;
}
.gallery .gallery-header h1, .gallery .gallery-header h2, .gallery .gallery-header h3, .gallery .gallery-header h4, .gallery .gallery-header h5 {
  margin-top: 0;
}
.gallery .gallery-header p {
  color: #777;
}
.gallery .gallery-body {
  text-align: left;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  margin: 0 15%;
  background-color: #fff;
  z-index: 1;
}
.gallery .gallery-body .title-wrapper {
  margin-bottom: 30px;
}
.gallery .gallery-body .title-wrapper h1, .gallery .gallery-body .title-wrapper h2, .gallery .gallery-body .title-wrapper h3, .gallery .gallery-body .title-wrapper h4, .gallery .gallery-body .title-wrapper h5 {
  margin: 0 0 10px 0;
}
.gallery .gallery-body .title-wrapper .price {
  color: #777;
}
.gallery .gallery-body .description {
  font-size: 22px;
  font-weight: 300;
}
.gallery .gallery-action {
  position: absolute;
  transform: translate(-50%, -50%);
  right: 15%;
  z-index: 2;
}
.gallery .gallery-action .btn {
  margin-left: 10px;
  transform: scale(0);
  transition: none;
}
.gallery .gallery-action .btn.active {
  transform: scale(1);
  transition: transform 0.4s cubic-bezier(0.53, 0.01, 0.36, 1.63), box-shadow 0.3s;
}

#placeholder-navbar {
  position: fixed;
  top: 0;
  z-index: 3;
  transition: background-color 0.3s;
}
#placeholder-navbar .back-btn {
  padding: 0;
  border: none;
  background-color: transparent;
}
#placeholder-navbar .back-btn i {
  float: left;
  margin-right: 10px;
}

#placeholder-overlay {
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  opacity: 0;
  transition: opacity 0.3s;
  will-change: opacity;
}
#placeholder-overlay.visible {
  opacity: 1;
}
#placeholder-overlay:hover {
  opacity: 0.9;
  transition: opacity 0.6s;
}

/*********************************
 *    COMPONENT COMPATIBILITY    *
 *********************************/
.gallery-body .carousel-wrapper {
  margin: 30px 0 20px -35px;
  width: calc(100% + 70px);
}
.gallery-body .carousel-wrapper .carousel {
  height: 200px;
}
.gallery-body .carousel-wrapper .carousel-item {
  opacity: 1 !important;
  width: 300px;
  height: 200px;
}

.nav-header .carousel {
  height: 100%;
}
.nav-header .carousel .carousel-item {
  word-break: break-word;
}
.nav-header .carousel .indicators {
  height: 56px;
}
.nav-header .carousel .indicators .indicator-item {
  float: none;
}

@media only screen and (max-width : 992px) {
  .gallery .gallery-cover.static {
    margin: 0;
  }
  .gallery .gallery-body {
    width: 100%;
    margin: 0;
  }
  .gallery .gallery-action {
    right: 0;
  }

  .gallery-body .carousel-wrapper {
    margin: 30px 0 20px -15px;
    width: calc(100% + 30px);
  }
}
@media only screen and (max-width : 600px) {
  nav.nav-full-header .nav-header {
    height: calc(100% - 112px);
  }
  nav.nav-full-header ~ .nav-categories {
    top: -56px;
  }
  nav .categories-wrapper {
    height: 56px;
  }
  nav .nav-header h1 {
    font-size: 40px;
  }

  .gallery .gallery-expand.active .gallery-curve-wrapper {
    padding-top: 56px;
  }
}
.home .header {
  background: none;
}
.home .headerLogo {
  display: none;
}
.homeVideo {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 0;
}
.homeVideo video {
  display: block;
  width: 100%;
  height: auto;
}
.homeVideoMask {
  position: absolute;
  width: 100%;
  height: 100%;
}
.homeVideoLogo {
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.homeVideoLogo img {
  width: 700px;
}
.homeVideoInfos {
  max-width: 515px;
  position: absolute;
  bottom: 0;
  border-left: 1px solid #FFFFFF;
  padding: 0 0 60px 40px;
}
.homeVideoInfosTitle:before {
  display: none;
}
.homeVideoInfosContent {
  font-size: 18px;
  font-size: 1.8rem;
}
.homeTitle {
  display: block;
  width: 100%;
  height: 100px;
  position: absolute;
  z-index: 1000;
}
.homeBanner {
  height: 150px;
  width: 100%;
  background-size: cover;
  z-index: 300;
  display: block;
}
.homeBanner + .homeVideo {
  display: block;
  width: 100%;
  height: auto;
}
.homeActivity {
  height: 320px;
  color: #FFFFFF;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  position: relative;
  display: block;
}
.homeActivity picture {
  display: flex;
  height: 100%;
  width: 100%;
}
.homeActivityImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.homeActivityHover {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.homeActivityHover:hover {
  opacity: 0.85;
}
.homeActivityKartingHover {
  background-color: #EB6608;
  background-image: -webkit-gradient(linear, left top, right top, from(#EB6608), to(#FFC95C));
  background-image: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -moz-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -ms-linear-gradient(left, #EB6608, #FFC95C);
  background-image: -o-linear-gradient(left, #EB6608, #FFC95C);
  background-image: linear-gradient(left, #EB6608, #FFC95C);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#EB6608", end-colourStr="#FFC95C", gradientType="1");
}
.homeActivityFreeFallHover {
  background-color: #1A9643;
  background-image: -webkit-gradient(linear, left top, right top, from(#1A9643), to(#C0CA51));
  background-image: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -moz-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -ms-linear-gradient(left, #1A9643, #C0CA51);
  background-image: -o-linear-gradient(left, #1A9643, #C0CA51);
  background-image: linear-gradient(left, #1A9643, #C0CA51);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#1A9643", end-colourStr="#C0CA51", gradientType="1");
}
.homeActivityWinscapeHover {
  background-color: #2A4DAD;
  background-image: -webkit-gradient(linear, left top, right top, from(#2A4DAD), to(#04BAEE));
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -moz-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -ms-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -o-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: linear-gradient(left, #2A4DAD, #04BAEE);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#2A4DAD", end-colourStr="#04BAEE", gradientType="1");
}
.homeActivityVirtualLaserHover {
  background-color: #CC1E5E;
  background-image: -webkit-gradient(linear, left top, right top, from(#CC1E5E), to(#F0804D));
  background-image: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -moz-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -ms-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: -o-linear-gradient(left, #CC1E5E, #F0804D);
  background-image: linear-gradient(left, #CC1E5E, #F0804D);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#CC1E5E", end-colourStr="#F0804D", gradientType="1");
}
.homeActivityContent {
  position: absolute;
  bottom: 0;
  min-height: 132px;
  padding: 30px;
}
.homeActivityContentTitle {
  position: relative;
  margin: 0 0 20px 20px;
}
.homeActivityContentTitle:before {
  content: "";
  width: 30px;
  margin-left: -30px;
  height: 1px;
  background: #FFFFFF;
  position: absolute;
  left: -20px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.homePublic {
  margin: 80px 0 30px 0;
}
.homePublicTitle {
  background: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  margin-bottom: 80px;
}
.homePublicBloc {
  text-align: center;
  margin-bottom: 50px;
}
.homePublicBlocIcon {
  font-size: 80px;
  font-size: 8rem;
  line-height: 8rem;
}
.homePublicBlocLibelle {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 400;
  text-transform: uppercase;
  margin: 10px 0 20px 0;
}
.homeContact {
  margin: 80px 0;
}
.homeContactInfos {
  margin-bottom: 40px;
}
.homeContactInfosMorePrecisions a {
  color: #686868;
}
.homeContactInfosMorePrecisions a:hover {
  color: white;
}
.homeContactInfosTitleDirection {
  font-style: italic;
}
.homeContactInfosTitleUnder {
  font-weight: bold;
  color: #FFFFFF;
}
.homeContactInfosText {
  color: c;
  font-weight: 400;
}
.homeContactInfosTextNumber {
  font-weight: 700;
  color: #FFFFFF;
  float: right;
}
.homeContactForm .akFormInputField {
  margin-bottom: 20px;
}
.homeContactForm [type=checkbox].filled-in + label {
  color: #747b86;
  line-height: 1.2;
}

.shopProducts {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
}
@media screen and (min-width: 601px) {
  .shopProducts {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1201px) {
  .shopProducts {
    grid-template-columns: repeat(3, 1fr);
  }
}
.shop .akContainerPage {
  overflow: inherit;
}
.shopLinkShopPictures {
  background: none;
  text-align: left;
}
.shopLinkShopPictures:hover .icon-photo::before {
  right: 100%;
}
.shopLinkShopPictures i::before {
  top: 50%;
  transform: translateY(-50%);
}
.shopLinkShopPictures .icon-photo::before {
  right: 100%;
  text-align: left;
  font-size: 35px;
}
.shopCardError {
  color: #ffffff;
  font-size: 18px;
  border: 1px solid red;
  margin-bottom: 40px;
  TEXT-ALIGN: CENTER;
}
.shopCardAvertissement {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid red;
  margin-bottom: 40px;
  TEXT-ALIGN: CENTER;
}
.shopCardInfos {
  margin: 30px 0;
}
.shopCardBooking {
  padding: 20px 180px 20px 30px;
  margin-bottom: 20px;
  position: relative;
}
.shopCardBooking.shopCardBookingGift {
  margin-bottom: 20px;
}
.shopCardBookingAge {
  color: #747b86;
  margin-top: 10px;
}
.shopCardBookingSelection {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.shopCardBookingSelectionBtn {
  background: #FFFFFF;
  color: #00060A;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 4rem;
  font-size: 50px;
  font-size: 5rem;
  font-weight: 300;
  cursor: pointer;
}
.shopCardBookingSelectionBtn:hover {
  background: #2A3646;
}
.shopCardBookingSelectionBtnDisabled {
  background: #747b86;
  cursor: none;
}
.shopCardBookingSelectionBtnDisabled:hover {
  background: #747b86;
  color: #00060A;
}
.shopCardBookingSelectionBtnLess {
  line-height: 3.4rem;
}
.shopCardBookingSelectionChoice {
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px;
  font-size: 21px;
  font-size: 2.1rem;
}
.shopCardGoToShop {
  margin: 20px 0;
  padding: 30px;
  position: relative;
}
.shopCardGoToShop:after {
  content: "";
  display: table;
  clear: both;
}
.shopCardGoToShopCheckbox {
  position: absolute !important;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.shopCardGoToShopText {
  width: 75%;
  font-weight: 400;
  font-size: 16px;
  font-size: 1.6rem;
  display: inline-block;
  margin: 0 10px 0 0;
}
.shopCardGoToShopLink {
  float: right;
}
.shopCardSlots {
  padding: 20px 30px;
}
.shopCardSlotsTitle {
  text-align: center;
  font-size: 21px;
  font-size: 2.1rem;
}
.shopCardSlotsTitle span {
  font-weight: 800;
}
.shopCardSlotsItem {
  margin: 20px 0;
}
.shopCardSlotsItem:after {
  content: "";
  display: table;
  clear: both;
}
.shopCardSlotsItemIcon {
  font-size: 22px;
  font-size: 2.2rem;
  color: #747b86;
  display: inline-block;
  vertical-align: text-top;
  margin-top: 5px;
}
.shopCardSlotsItemHours {
  font-size: 21px;
  font-size: 2.1rem;
  display: inline-block;
  vertical-align: text-top;
  margin-left: 10px;
}
.shopCardSlotsItemHoursNumber, .shopCardSlotsItemHoursNumberNone {
  color: #747b86;
  font-size: 14px;
  font-size: 1.4rem;
}
.shopCardSlotsItemHoursNumberNone {
  color: #D6002C;
}
.shopCardSlotsItemChoose {
  color: #FFFFFF;
  font-weight: 600;
  padding: 5px 8px;
  float: right;
  margin-top: 5px;
}
.shopCardSlotsItemImpossible {
  font-size: 14px;
  font-size: 1.4rem;
  padding: 5px 8px;
  float: right;
}
.shopCardReservation:after {
  content: "";
  display: table;
  clear: both;
}
.shopCardReservationBox {
  margin: 30px 0 20px 0;
  padding: 20px 30px;
}
.shopCardReservationBoxTitle {
  font-size: 21px;
  font-size: 2.1rem;
  font-weight: 400;
}
.shopCardReservationBoxContent {
  color: #747b86;
}
.shopCardReservationBoxContentPrice {
  font-weight: 800;
  color: #FFFFFF;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.6rem;
  float: right;
}
.shopCardCalendar {
  margin-bottom: 20px;
}
.shopCardCalendar thead {
  border-bottom: 0;
}
.shopCardCalendar th {
  padding: 13px 0;
  border-radius: 0;
}
.shopCardCalendar td {
  padding: 5px 0;
}
.shopCardCalendar td, .shopCardCalendar th {
  text-align: center;
  width: 14.2857142857%;
}
.shopCardCalendar abbr {
  border: 0;
  text-decoration: none;
  font-weight: 400;
}
.shopCardCalendar .pika-title {
  min-height: 60px;
  line-height: 2.5rem;
  position: relative;
  padding: 15px 50px;
  text-align: center;
  font-size: 21px;
  font-size: 2.1rem;
}
.shopCardCalendar .pika-label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.shopCardCalendar .pika-button {
  background: none;
  border: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  text-align: center;
}
.shopCardCalendar .pika-prev, .shopCardCalendar .pika-next {
  background: none;
  border: 0;
  position: absolute;
  width: 20px;
  height: 60px;
  top: 0px;
  display: block;
}
.shopCardCalendar .pika-prev:before, .shopCardCalendar .pika-next:before {
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 6rem;
}
.shopCardCalendar .pika-prev {
  left: 20px;
}
.shopCardCalendar .pika-prev:before {
  content: "f";
}
.shopCardCalendar .pika-next {
  right: 20px;
}
.shopCardCalendar .pika-next:before {
  content: "D";
}
.shopCardCalendar .is-today .pika-button {
  background-color: #2A4DAD;
  background-image: -webkit-gradient(linear, left top, right top, from(#2A4DAD), to(#04BAEE));
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -moz-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -ms-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -o-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: linear-gradient(left, #2A4DAD, #04BAEE);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#2A4DAD", end-colourStr="#04BAEE", gradientType="1");
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-weight: 700;
}
.shopCardCalendar .available .pika-button {
  background: #FFFFFF;
  color: #00060A;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-weight: 700;
}
.shopCart {
  margin-bottom: 40px;
}
.shopCartTitle {
  margin: 25px 0 30px 0;
}
.shopCartContent {
  padding: 20px;
  color: #747b86;
}
.shopCartContentPromoCode {
  font-weight: bold;
}
.shopCartContent:after {
  content: "";
  display: table;
  clear: both;
}
.shopCartContentTotal {
  text-transform: uppercase;
  margin-top: 20px;
}
.shopCartContentTotal span {
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: 800;
  color: #FFFFFF;
  float: right;
  line-height: 2rem;
}
.shopPayment {
  background: #FFFFFF;
  padding: 20px;
  height: 160px;
  margin-bottom: 20px;
}
.shopPayment img {
  margin: 20px auto;
  display: block;
}
.shopPayment [type=radio]:not(:checked) + label, .shopPayment [type=radio]:checked + label {
  font-size: 21px;
  font-size: 2.1rem;
  font-weight: 400;
  color: #0C1119;
}
.shopPayment [type=radio].with-gap:checked + label:before {
  border-left: 4px solid #2A4DAD;
  border-right: 4px solid #04BAEE;
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE), -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-size: 100% 4px;
  background-position: 0 100%, 0 0;
  background-repeat: no-repeat;
  background-clip: border-box;
}
.shopPayment [type=radio]:checked + label:after, .shopPayment [type=radio].with-gap:checked + label:after {
  background-color: #FFFFFF;
}
.shopPaymentSelected {
  border-left: 3px solid #2A4DAD;
  border-right: 3px solid #04BAEE;
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE), -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-size: 100% 3px;
  background-position: 0 100%, 0 0;
  background-repeat: no-repeat;
  background-clip: border-box;
}
.shopMoreInfos {
  margin-top: 10px;
  position: relative;
}
.shopMoreInfosToolTip {
  display: none;
  z-index: 10;
  position: absolute;
  background: #0C1119;
  padding: 20px;
  margin-top: 16px;
  margin-right: 30px;
}
.shopMoreInfosToolTip:before {
  content: "";
  background: #0C1119;
  display: block;
  position: absolute;
  top: 0;
  left: 13px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%) rotate(45deg);
}
.shopMoreInfosToolTip * {
  display: block;
}
.shopMoreInfos:hover ~ .shopMoreInfosToolTip {
  display: block;
}
.shopCodePromo .akFormInputField {
  width: calc(100% - 90px);
  float: left;
}
.shopCodePromo button {
  width: 90px;
  height: 45px;
  float: left;
}

[type=checkbox]:checked + .shopCardGoToShopCheckbox:before {
  border-right: 2px solid #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
}

.shopPictures {
  /* Shop Pictures Cards */
  /* Form */
  /* Date Picker */
}
.shopPictures .akContainerPage {
  overflow: initial;
}
.shopPicturesMenu {
  padding-top: 20px;
  /*créneau*/
}
.shopPicturesMenu nav {
  background: none;
  height: 100%;
  float: left;
  width: auto;
}
.shopPicturesMenu .categories {
  margin-bottom: 10px;
  display: inline-block;
}
.shopPicturesMenu .categories-container {
  line-height: 0;
}
.shopPicturesMenu .categories-container.pinned {
  position: relative !important;
}
.shopPicturesMenu .categories .akFilterItem {
  height: 45px;
  line-height: 1;
  margin: 0px 20px 10px 0;
}
.shopPicturesMenu .categories .akFilterItem .akFilterItemLink {
  height: 45px;
  line-height: 45px;
  padding: 0 18px;
  margin: 0;
}
.shopPicturesMenu .creneau label {
  font-size: 11px;
}
.shopPicturesMenu .creneau .caret {
  display: none;
}
.shopPicturesMenu .creneau input {
  font-size: 16px;
}
.shopPicturesMenu .creneau .dropdown-content.select-dropdown li span {
  color: #FFFFFF;
}
.shopPicturesMenu .creneau .dropdown-content.select-dropdown li:hover {
  background: #0C1119;
}
.shopPicturesMenu .creneau .dropdown-content.select-dropdown li.active {
  background: #FFFFFF;
}
.shopPicturesMenu .creneau .dropdown-content.select-dropdown li.active span {
  color: #00060A;
}
.shopPicturesMenu .creneau .dropdown-content.select-dropdown li:first-child {
  background: #2A3646;
}
.shopPicturesMenu .creneau .dropdown-content.select-dropdown li:first-child span {
  color: #747b86;
}
.shopPicturesMenuError {
  color: #D6002C;
  margin-bottom: 20px;
  display: block;
  float: right;
}
.shopPicturesInputs {
  float: right;
  width: 100%;
}
.shopPicturesInputs > * {
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}
.shopPicturesInputs > *:last-child {
  margin-right: 0;
}
.shopPicturesSubmit {
  float: right;
}
.shopPictures .gallery-item.Karting.select .shopPicturesCard {
  border-left: 4px solid #EB6608;
  border-right: 4px solid #FFC95C;
  background-image: -webkit-linear-gradient(left, #EB6608, #FFC95C), -webkit-linear-gradient(left, #EB6608, #FFC95C);
  background-size: 100% 4px;
  background-position: 0 100%, 0 0;
  background-repeat: no-repeat;
  background-clip: border-box;
}
.shopPictures .gallery-item.Winscape.select .shopPicturesCard {
  border-left: 4px solid #2A4DAD;
  border-right: 4px solid #04BAEE;
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE), -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-size: 100% 4px;
  background-position: 0 100%, 0 0;
  background-repeat: no-repeat;
  background-clip: border-box;
}
.shopPictures .gallery-item.ChuteLibre.select .shopPicturesCard {
  border-left: 4px solid #1A9643;
  border-right: 4px solid #C0CA51;
  background-image: -webkit-linear-gradient(left, #1A9643, #C0CA51), -webkit-linear-gradient(left, #1A9643, #C0CA51);
  background-size: 100% 4px;
  background-position: 0 100%, 0 0;
  background-repeat: no-repeat;
  background-clip: border-box;
}
.shopPicturesCard {
  padding: 20px 20px 0 20px;
  margin-bottom: 20px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  background-image: -webkit-linear-gradient(left, transparent, transparent), -webkit-linear-gradient(left, transparent, transparent);
}
.shopPicturesCardImg {
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}
.shopPicturesCardImg:before {
  content: "";
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: #FFFFFF;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.shopPicturesCardImg:after {
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 45px;
  color: #2A3646;
  content: "\e903";
  position: absolute;
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}
.shopPicturesCardImg img {
  display: block;
  width: 100%;
  transition: all 0.3s ease;
}
.shopPicturesCardImg:hover img {
  transform: scale(1.2);
}
.shopPicturesCardImg:hover:before {
  opacity: 0.3;
}
.shopPicturesCardImg:hover:after {
  opacity: 1;
}
.shopPicturesCardInfos {
  float: left;
}
.shopPicturesCardCategory {
  display: block;
  font-size: 1.5rem;
}
.shopPicturesCardDate {
  color: #747b86;
}
.shopPicturesCardPrice {
  float: right;
  margin-bottom: 20px;
}
.shopPicturesCardControl {
  margin-bottom: 0;
}
.shopPicturesCardControl .akBtn, .shopPicturesCardControl .akBtnFilter, .shopPicturesCardControl .akBtnGreen, .shopPicturesCardControl .akBtnOrange, .shopPicturesCardControl .akBtnBlue, .shopPicturesCardControl .akBtnPink, .shopPicturesCardControl .akBtnWhite, .shopPicturesCardControl .akBtnDisabled, .shopPicturesCardControl .akFilterItem.active .shopNavFilterItemLink, .akFilterItem.active .shopPicturesCardControl .shopNavFilterItemLink, .shopPicturesCardControl .akFilterItemLink, .shopPicturesCardControl .akFilterItemLinkAll, .shopPicturesCardControl .shopCardSlotsItemImpossible {
  width: 100%;
  margin-bottom: 20px;
}
.shopPicturesCardControlSelect[type=checkbox] + label {
  font-size: 16px;
  font-size: 1.6rem;
  height: 40px;
  padding: 8px 18px;
}
.shopPicturesCardControlSelect[type=checkbox] + label:before, .shopPicturesCardControlSelect[type=checkbox] + label:after {
  margin-top: 10px;
  margin-left: 10px;
}
.shopPicturesCardControlSelect[type=checkbox]:checked + label:before {
  border-right: 2px solid #2A3646;
  border-bottom: 2px solid #2A3646;
}
.shopPicturesCardControlSelect[type=checkbox]:checked + label:after {
  border: 2px solid #FFFFFF;
  background-color: #FFFFFF;
}
.shopPicturesAddPanier {
  position: fixed;
  z-index: 900;
  bottom: 0;
  left: 0;
  width: 100%;
}
.shopPicturesAddPanier.no-stick {
  position: relative;
}
.shopPicturesAddPanier .row {
  margin: 0;
}
.shopPicturesAddPanierAmount, .shopPicturesAddPanierBtn {
  margin: 10px;
  float: right;
}
.shopPicturesAddPanierAmountPrice {
  font-weight: bold;
}
.shopPicturesAddPanier .js-scrollTo {
  float: right;
  display: inline-block;
  line-height: 40px;
  margin: 10px 0;
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  color: #00060A;
  text-align: center;
}
.shopPicturesInput {
  margin-bottom: 20px;
  position: relative;
}
.shopPicturesInput span {
  position: absolute;
  font-size: 11px;
}
.shopPicturesInput Input[type=text] {
  margin-bottom: 0;
}
.shopPictures .inputNumPicture .input-field {
  overflow: hidden;
}
.shopPictures .inputNumPicture label {
  white-space: nowrap;
}
.shopPictures #datepicker {
  font-size: 16px;
  margin-top: 1rem;
}
.shopPictures .pika-single {
  z-index: 1000;
  margin-bottom: 20px;
}
.shopPictures .pika-single.is-hidden {
  display: none;
}
.shopPictures .pika-single thead {
  border-bottom: 0;
}
.shopPictures .pika-single th {
  padding: 13px 0;
  border-radius: 0;
}
.shopPictures .pika-single td {
  padding: 5px 0;
}
.shopPictures .pika-single td, .shopPictures .pika-single th {
  text-align: center;
  width: 14.2857142857%;
}
.shopPictures .pika-single abbr {
  border: 0;
  text-decoration: none;
  font-weight: 400;
}
.shopPictures .pika-single .pika-title {
  min-height: 60px;
  line-height: 2.5rem;
  position: relative;
  padding: 15px 50px;
  text-align: center;
  font-size: 21px;
  font-size: 2.1rem;
}
.shopPictures .pika-single .pika-label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.shopPictures .pika-single .pika-button {
  background: none;
  border: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  text-align: center;
}
.shopPictures .pika-single .pika-prev, .shopPictures .pika-single .pika-next {
  background: none;
  border: 0;
  position: absolute;
  width: 20px;
  height: 60px;
  top: 0px;
  display: block;
}
.shopPictures .pika-single .pika-prev:before, .shopPictures .pika-single .pika-next:before {
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 6rem;
}
.shopPictures .pika-single .pika-prev {
  left: 20px;
}
.shopPictures .pika-single .pika-prev:before {
  content: "f";
}
.shopPictures .pika-single .pika-next {
  right: 20px;
}
.shopPictures .pika-single .pika-next:before {
  content: "D";
}
.shopPictures .pika-single .is-today .pika-button {
  background-color: #2A4DAD;
  background-image: -webkit-gradient(linear, left top, right top, from(#2A4DAD), to(#04BAEE));
  background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -moz-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -ms-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: -o-linear-gradient(left, #2A4DAD, #04BAEE);
  background-image: linear-gradient(left, #2A4DAD, #04BAEE);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#2A4DAD", end-colourStr="#04BAEE", gradientType="1");
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-weight: 700;
}
.shopPictures .pika-single .available .pika-button {
  background: #FFFFFF;
  color: #00060A;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-weight: 700;
}
.shopPicturesLoader {
  position: absolute;
  border: 5px solid #FFFFFF;
  border-radius: 50%;
  border-top: 5px solid #04BAEE;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.shopPicturesLoader ~ p {
  padding-top: 45px;
}

@media screen and (min-width: 601px) {
  .shopPicturesAddPanier .row {
    margin-right: 160px;
  }
  .shopPicturesAddPanierAmount {
    height: 40px;
    display: inline-block;
    line-height: 40px;
  }
  .shopPicturesAddPanier.no-stick .row {
    margin-right: 0;
  }
  .shopPicturesAddPanier.no-stick .js-scrollTo {
    right: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .shopPicturesInputs {
    width: auto;
  }
}
.buisnessTitle {
  background: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}
.buisnessTitleUnder {
  margin-bottom: 60px;
}
.buisness .akTitle4, .buisness .newsBlocTitleContentLibelle, .buisness .headerBasketPrice, .buisness .footerBlocTitle, .buisness .akCardTitleLibelle, .buisness .homeContactInfosTitle {
  margin-bottom: 10px;
}
.buisnessBackgroundImg {
  background: url("../../images/buisness/HeaderImage_Entreprise_mobile.png") no-repeat;
  -webkit-background-size: 100%;
  background-size: 100%;
}
@media screen and (min-width: 601px) {
  .buisnessBackgroundImg {
    background: url("../../images/buisness/HeaderImage_Entreprise.png") no-repeat;
  }
}
.buisnessListe {
  position: relative;
  margin-left: 10px;
  margin-bottom: 10px;
}
.buisnessListe li {
  margin-bottom: 5px;
}
.buisnessListe li:before {
  content: "- ";
  position: absolute;
  left: -10px;
}
.buisnessImg {
  display: flex;
  height: auto;
  width: 100%;
  margin: 20px 0;
}
.buisnessImg img {
  height: auto;
  max-width: 100%;
  width: 100%;
}
.buisnessDownload {
  margin-top: 40px;
}
.buisnessDownloadBtn {
  margin-left: 40px;
}
.buisnessHours {
  margin-top: 100px;
}
.buisnessHoursDay {
  margin: 0;
}
.buisnessGalleryImg, .buisnessHeaventParis {
  height: auto;
  width: 100%;
}

.birthdayTitle {
  background: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}
.birthdayTitleBirthday, .birthdayTitleEvj {
  margin: 40px 0 20px 0;
}
.birthdayTitleUnder {
  margin-bottom: 20px;
}
.birthdayPromoBanner {
  font-weight: bold;
}
.birthday .akTitle4, .birthday .newsBlocTitleContentLibelle, .birthday .headerBasketPrice, .birthday .footerBlocTitle, .birthday .akCardTitleLibelle, .birthday .homeContactInfosTitle {
  margin-bottom: 10px;
}
.birthdayBackgroundImg {
  background: url("../../images/birthday/HeaderImage_Anniversaire_mobile.png") no-repeat;
  -webkit-background-size: 100%;
  background-size: 100%;
}
@media screen and (min-width: 601px) {
  .birthdayBackgroundImg {
    background: url("../../images/birthday/HeaderImage_Anniversaire.png") no-repeat;
  }
}
.birthdayImg {
  display: flex;
  height: auto;
  width: 100%;
  margin: 10px 0 40px 0;
}
.birthdayImg img {
  height: auto;
  max-width: 100%;
  width: 100%;
}
.birthdayPackPetitAventurier .akTextSecondary, .birthdayPackKarting .akTextSecondary, .birthdayPackFreeFall .akTextSecondary, .birthdayPackVirtualLaser .akTextSecondary, .birthdayPackWinscape .akTextSecondary {
  margin-bottom: 20px;
}
.birthdayPackKartingTitle {
  background: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: none;
}
.birthdayPackWinscapeTitle, .birthdayPackVirtualLaserTitle {
  background: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: none;
}
.birthdayPackPetitAventurierTitle {
  background: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: none;
}
.birthdayPackFreeFallTitle {
  background: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.birthdayRow {
  display: flex;
}
.birthdayCol {
  display: flex;
  flex: 1 1;
}
.birthdayInfos {
  margin-top: 100px;
}

.akCardContentInfos {
  flex: 1;
}

.news .akFilter {
  margin: 0 0 30px 0;
}
.newsBloc {
  overflow: hidden;
  color: #FFFFFF;
  display: block;
  width: 100%;
  /* 1er item */
}
.newsBloc.gallery-item {
  margin-bottom: 40px;
}
.newsBlocPicture {
  overflow: hidden;
  position: relative;
  /*height: 360px;*/
  height: 210px;
  float: left;
  width: 100%;
}
.newsBlocMain {
  float: left;
  width: 100%;
}
.newsBlocTitle {
  padding: 0 40px 0 40px;
  background-color: rgba(0, 6, 10, 0.5);
  height: 100px;
  overflow: hidden;
  position: relative;
}
.newsBlocTitleContent {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.newsBlocTitleContentLibelle {
  position: relative;
}
.newsBlocTitleContentLibelle:before {
  content: "";
  width: 20px;
  margin-left: -20px;
  height: 1px;
  background: #FFFFFF;
  position: absolute;
  left: -20px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.newsBlocTitleContentDate {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 400;
}
.newsBlocContent {
  /*height: 260px;*/
  height: 110px;
  padding: 20px;
}
.newsBlocContentText {
  /*height: 220px;*/
  height: 70px;
  overflow: hidden;
}
.newsBlocContentText * {
  display: none;
}
.newsBlocContentText *:first-child {
  display: block;
}
.newsBloc:first-child .newsBlocPicture {
  float: none;
  width: 100%;
  height: 360px;
}
.newsBloc:first-child .newsBlocMain {
  float: none;
  width: 100%;
}
.newsBloc:first-child .newsBlocContent {
  height: 140px;
}
.newsBloc:first-child .newsBlocContentText {
  height: 70px;
}
.newsMore {
  margin-bottom: 40px;
  display: block;
}
.newsSocialWall {
  background-color: rgba(0, 6, 10, 0.5);
  padding: 20px;
  height: 695px;
  overflow-y: auto;
}
.newsSocialWall.newsSocialWallBig {
  height: auto;
  max-height: 965px;
  margin-bottom: 30px;
}
.newsSocialWallBloc {
  margin-bottom: 30px;
}
.newsSocialWallBloc:after {
  content: "";
  display: table;
  clear: both;
}
.newsSocialWallBlocIcon {
  float: left;
  width: 15%;
  font-size: 24px;
  font-size: 2.4rem;
}
.newsSocialWallBlocContent {
  float: left;
  width: 85%;
}
.newsSocialWallBlocContentText {
  font-size: 14px;
  font-size: 1.4rem;
  margin-bottom: 20px;
}
.newsDetailContainer {
  max-width: 760px;
}
.newsDetailContainer ul {
  position: relative;
  margin-left: 10px;
}
.newsDetailContainer ul li:before {
  content: "• ";
  position: absolute;
  left: -10px;
}
.newsDetailContainer ul li {
  margin-bottom: 10px;
}
.newsDetailContainer ul li:last-child {
  margin-bottom: 20px;
}
.newsDetailContent {
  background: #FFFFFF;
  color: #0C1119;
  margin: 50px 0 10px 0;
}
.newsDetailContentTitle {
  padding: 40px;
  font-size: 24px;
  font-size: 2.4rem;
}
.newsDetailContent img {
  width: 100%;
}
.newsDetailContentText {
  padding: 40px;
}
.newsDetailContentText p {
  margin-bottom: 25px;
}
.newsDetailContentText a {
  color: #04BAEE;
  font-weight: 700;
}
.newsDetailTag {
  color: #747b86;
  font-size: 14px;
  font-size: 1.4rem;
}
.newsDetailBack {
  float: right;
  margin: 10px 0 50px 0;
}

.home .newsBloc:first-child .newsBlocPicture {
  width: 100%;
  float: left;
}
.home .newsBloc:first-child .newsBlocMain {
  width: 100%;
  float: left;
}
.home .newsBloc:first-child .newsBlocContent {
  height: 100px;
}

@media screen and (min-width: 600px) {
  .newsBlocPicture {
    width: 50%;
  }
  .newsBlocMain {
    width: 50%;
  }

  .newsBloc:first-child .newsBlocContentText {
    height: 100px;
  }

  .home .newsBloc:first-child .newsBlocPicture {
    width: 50%;
  }
  .home .newsBloc:first-child .newsBlocMain {
    width: 50%;
  }
  .home .newsBloc:first-child .newsBlocContent {
    height: 260px;
  }
  .home .newsBloc:first-child .newsBlocContentText {
    height: 215px;
  }
}
.freeFallTitle,
.kartingTitle,
.winscapeTitle,
.virtualLaserTitle {
  text-transform: uppercase;
  margin-bottom: 10px;
}
.freeFallLogo,
.kartingLogo,
.winscapeLogo,
.virtualLaserLogo {
  height: auto;
  width: 100%;
  max-width: 300px;
}
.freeFallVideo,
.kartingVideo,
.winscapeVideo,
.virtualLaserVideo {
  margin: 40px 0 10px 0;
}
.freeFallImg,
.kartingImg,
.winscapeImg,
.virtualLaserImg {
  display: block;
  margin-top: 40px;
}
.freeFallImg img,
.kartingImg img,
.winscapeImg img,
.virtualLaserImg img {
  height: auto;
  max-width: 100%;
}
.freeFallUnderImg,
.kartingUnderImg,
.winscapeUnderImg,
.virtualLaserUnderImg {
  margin-top: 10px;
}
.freeFallUnderImg, .freeFallDownloadText,
.kartingUnderImg,
.kartingDownloadText,
.winscapeUnderImg,
.winscapeDownloadText,
.virtualLaserUnderImg,
.virtualLaserDownloadText {
  font-style: italic;
}
.freeFallDownloadText,
.kartingDownloadText,
.winscapeDownloadText,
.virtualLaserDownloadText {
  display: inline-block;
  width: auto;
  padding-top: 10px;
}
.freeFallDownload,
.kartingDownload,
.winscapeDownload,
.virtualLaserDownload {
  margin-top: 40px;
}
.freeFallDownloadBtn,
.kartingDownloadBtn,
.winscapeDownloadBtn,
.virtualLaserDownloadBtn {
  margin-left: 40px;
}
.freeFallPricesTitle,
.kartingPricesTitle,
.winscapePricesTitle,
.virtualLaserPricesTitle {
  margin: 60px 0 40px 0;
}
.freeFallHoursDay,
.kartingHoursDay,
.winscapeHoursDay,
.virtualLaserHoursDay {
  margin: 0;
}
.freeFallGalleryImg,
.kartingGalleryImg,
.winscapeGalleryImg,
.virtualLaserGalleryImg {
  width: 100%;
}
.freeFallLittleMore,
.kartingLittleMore,
.winscapeLittleMore,
.virtualLaserLittleMore {
  background: #00060A;
  padding: 10px 30px;
  text-align: center;
}
.freeFallLittleMore .akTitle4, .freeFallLittleMore .headerBasketPrice, .freeFallLittleMore .footerBlocTitle, .freeFallLittleMore .akCardTitleLibelle, .freeFallLittleMore .homeContactInfosTitle, .freeFallLittleMore .newsBlocTitleContentLibelle,
.kartingLittleMore .akTitle4,
.kartingLittleMore .headerBasketPrice,
.kartingLittleMore .footerBlocTitle,
.kartingLittleMore .akCardTitleLibelle,
.kartingLittleMore .homeContactInfosTitle,
.kartingLittleMore .newsBlocTitleContentLibelle,
.winscapeLittleMore .akTitle4,
.winscapeLittleMore .headerBasketPrice,
.winscapeLittleMore .footerBlocTitle,
.winscapeLittleMore .akCardTitleLibelle,
.winscapeLittleMore .homeContactInfosTitle,
.winscapeLittleMore .newsBlocTitleContentLibelle,
.virtualLaserLittleMore .akTitle4,
.virtualLaserLittleMore .headerBasketPrice,
.virtualLaserLittleMore .footerBlocTitle,
.virtualLaserLittleMore .akCardTitleLibelle,
.virtualLaserLittleMore .homeContactInfosTitle,
.virtualLaserLittleMore .newsBlocTitleContentLibelle {
  margin-bottom: 30px;
}
.freeFallLittleMore [class^=icon-],
.kartingLittleMore [class^=icon-],
.winscapeLittleMore [class^=icon-],
.virtualLaserLittleMore [class^=icon-] {
  font-size: 45px;
  font-size: 4.5rem;
}
.freeFall ul,
.karting ul,
.winscape ul,
.virtualLaser ul {
  position: relative;
  margin-left: 10px;
}
.freeFall ul li:before,
.karting ul li:before,
.winscape ul li:before,
.virtualLaser ul li:before {
  content: "• ";
  position: absolute;
  left: -10px;
}

.freeFallTitle, .freeFallUnderImg {
  background: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.freeFallBackgroundImg {
  -webkit-background-size: 100%;
  background-size: 100% !important;
}
.freeFallHours .akTextSecondary {
  line-height: 0.7;
}
.freeFallLogoSoufflerie {
  width: 200px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.freeFallInfosContactForm .akFormInputField {
  margin-bottom: 20px;
}
.freeFallInfosBackgroundImg {
  Height: 100vh;
  overflow: hidden;
}
.freeFallInfosReglementInterieur {
  font-size: 14px;
  margin-bottom: 30px;
}
.freeFallInfosReglementInterieurText {
  margin-bottom: 50px;
}
.freeFallInfosInformations {
  border: 1px solid white;
}
.freeFallInfosInformationsTexte {
  padding: 20px 0;
}
.freeFallInfosCheckboxNewspaper {
  margin-bottom: 40px;
}
.freeFallInfosTextHideFormText {
  margin-top: 20px;
  margin-bottom: -30px;
}
.freeFallInfosFlyDate {
  text-align: center;
  margin-top: 10px;
}

.otherPrecisionsPlease {
  margin-bottom: 30px;
}

.confirmFormSend {
  border: 1px solid white;
  color: #ffffff;
  padding: 15px;
  text-align: center;
}

.LabelCustom {
  font: normal 17px "Raleway", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif !important;
}

labelfreeFallInfosCheckboxConditionsRead {
  color: #c6737e;
}

.kartingTitle, .kartingUnderImg {
  background: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.kartingBackgroundImg {
  -webkit-background-size: 100%;
  background-size: 100% !important;
}

.winscapeTitle, .winscapeUnderImg {
  background: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.winscapeBackgroundImg {
  -webkit-background-size: 100%;
  background-size: 100% !important;
}
.winscapeBackgroundImg .akContainerPage {
  overflow: inherit;
}
.winscape #parcours {
  position: relative;
}
.winscapeTooltipHover {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 10;
  background: #00060A;
  padding: 30px;
  display: none;
}
.winscapeTooltipHide {
  position: relative;
}
.winscapeTooltipHide:after {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 150%) rotate(45deg);
  background: #00060A;
  display: none;
}
.winscapeTooltipHide:hover ~ .winscapeTooltipHover, .winscapeTooltipHide:hover:after {
  display: block;
}

.technologicBackgroundImg,
.brainstormBackgroundImg,
.fastactionBackgroundImg,
.finaltrainingBackgroundImg {
  background: url(../../images/winscape/HeaderImage_Winscape.png) no-repeat;
  -webkit-background-size: 100%;
  background-size: 100%;
}
.technologicLogo,
.brainstormLogo,
.fastactionLogo,
.finaltrainingLogo {
  width: 100%;
  max-width: 300px;
}
.technologicTitleUnder,
.brainstormTitleUnder,
.fastactionTitleUnder,
.finaltrainingTitleUnder {
  margin-bottom: 30px;
}
.technologicImg,
.brainstormImg,
.fastactionImg,
.finaltrainingImg {
  margin-bottom: 40px;
  width: 100%;
}
.technologicHoursDay,
.brainstormHoursDay,
.fastactionHoursDay,
.finaltrainingHoursDay {
  margin: 0;
}

.prices {
  /* COLORS */
}
.pricesFreeFallTitle {
  background: -webkit-linear-gradient(left, #1A9643, #C0CA51);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pricesKartingTitle {
  background: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pricesWinscapeTitle {
  background: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pricesVirtualLaserTitle {
  background: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pricesBankLogos {
  height: auto;
  max-width: 360px;
  width: 100%;
  margin-top: 47px;
}
.pricesFreeFall .bottomRow a {
  color: #93C01F;
}
.pricesFreeFall, .pricesKarting, .pricesVirtualLaser, .pricesWinscape {
  margin-bottom: 40px;
}
.pricesFreeFallTitle, .pricesFreeFallTitleAdult, .pricesFreeFallTitleChild, .pricesFreeFallTitleHours, .pricesKartingTitle, .pricesKartingTitleAdult, .pricesKartingTitleChild, .pricesKartingTitleHours, .pricesVirtualLaserTitle, .pricesVirtualLaserTitleAdult, .pricesVirtualLaserTitleChild, .pricesVirtualLaserTitleHours, .pricesWinscapeTitle, .pricesWinscapeTitleAdult, .pricesWinscapeTitleChild, .pricesWinscapeTitleHours {
  text-transform: uppercase;
}
.pricesFreeFallTitleAdult, .pricesFreeFallTitleChild, .pricesFreeFallTitleHours, .pricesFreeFallDetailPrice, .pricesKartingTitleAdult, .pricesKartingTitleChild, .pricesKartingTitleHours, .pricesKartingDetailPrice, .pricesVirtualLaserTitleAdult, .pricesVirtualLaserTitleChild, .pricesVirtualLaserTitleHours, .pricesVirtualLaserDetailPrice, .pricesWinscapeTitleAdult, .pricesWinscapeTitleChild, .pricesWinscapeTitleHours, .pricesWinscapeDetailPrice {
  font-weight: bold;
}
.pricesFreeFallDetail.row, .pricesKartingDetail.row, .pricesVirtualLaserDetail.row, .pricesWinscapeDetail.row {
  margin-bottom: 0;
}
.pricesFreeFallDetailLibelle, .pricesFreeFallDetailPrice, .pricesKartingDetailLibelle, .pricesKartingDetailPrice, .pricesVirtualLaserDetailLibelle, .pricesVirtualLaserDetailPrice, .pricesWinscapeDetailLibelle, .pricesWinscapeDetailPrice {
  font-weight: bold;
}
.pricesFreeFallDetailLibelleForSession, .pricesFreeFallDetailPriceForSession, .pricesKartingDetailLibelleForSession, .pricesKartingDetailPriceForSession, .pricesVirtualLaserDetailLibelleForSession, .pricesVirtualLaserDetailPriceForSession, .pricesWinscapeDetailLibelleForSession, .pricesWinscapeDetailPriceForSession {
  color: #747b86;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 400;
}
.pricesFreeFallDetailLibelleForSessionOffer, .pricesFreeFallDetailPriceForSessionOffer, .pricesKartingDetailLibelleForSessionOffer, .pricesKartingDetailPriceForSessionOffer, .pricesVirtualLaserDetailLibelleForSessionOffer, .pricesVirtualLaserDetailPriceForSessionOffer, .pricesWinscapeDetailLibelleForSessionOffer, .pricesWinscapeDetailPriceForSessionOffer {
  background: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pricesFreeFallDetail a, .pricesKartingDetail a, .pricesVirtualLaserDetail a, .pricesWinscapeDetail a {
  font-weight: bold;
}
.pricesFreeFallTitleUnder, .pricesFreeFallDetailUnder, .pricesKartingTitleUnder, .pricesKartingDetailUnder, .pricesVirtualLaserTitleUnder, .pricesVirtualLaserDetailUnder, .pricesWinscapeTitleUnder, .pricesWinscapeDetailUnder {
  color: #747b86;
  margin-top: 0;
}
.pricesFreeFallDownloadText, .pricesKartingDownloadText, .pricesVirtualLaserDownloadText, .pricesWinscapeDownloadText {
  font-style: italic;
}
.pricesFreeFallDownloadBtn, .pricesKartingDownloadBtn, .pricesVirtualLaserDownloadBtn, .pricesWinscapeDownloadBtn {
  margin-left: 40px;
}
.pricesFreeFallHours .akTextSecondary, .pricesKartingHours .akTextSecondary, .pricesVirtualLaserHours .akTextSecondary, .pricesWinscapeHours .akTextSecondary {
  line-height: 0.7;
}
.pricesFreeFallHoursDay, .pricesKartingHoursDay, .pricesVirtualLaserHoursDay, .pricesWinscapeHoursDay {
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

.galleryPicture img {
  width: 100%;
}

.memberImg {
  width: 100%;
}
.memberKartingTitleSecondary, .memberFreefallTitleSecondary {
  margin-bottom: 0;
}
.memberKartingDetail, .memberFreefallDetail {
  margin-bottom: 0;
}
.memberKartingDetailFormule, .memberFreefallDetailFormule {
  font-weight: bold;
}
.memberKartingDetailPrice, .memberFreefallDetailPrice {
  font-weight: bold;
}
.memberKartingDetailSession, .memberFreefallDetailSession {
  color: #747b86;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 400;
}
.memberKartingAdvantage, .memberFreefallAdvantage {
  margin: 60px 0 30px 0;
}
.memberKartingAdvantageThursday, .memberFreefallAdvantageThursday {
  position: relative;
  margin-left: 10px;
  margin-bottom: 40px;
}
.memberKartingAdvantageThursday li:before, .memberFreefallAdvantageThursday li:before {
  content: "• ";
  position: absolute;
  left: -10px;
}
.memberKartingStartUp, .memberFreefallStartUp {
  margin-top: 60px;
  margin-bottom: 20px;
}
.memberHoursDay {
  margin-bottom: 0;
}

.cgvTitle {
  font-weight: bold;
  text-decoration: underline;
  margin-top: 40px;
}
.cgvListe li {
  list-style: disc !important;
}

.legalnoticeTitleUnder {
  font-weight: bold;
}
.legalnotice .akTitle4, .legalnotice .headerBasketPrice, .legalnotice .footerBlocTitle, .legalnotice .akCardTitleLibelle, .legalnotice .homeContactInfosTitle, .legalnotice .newsBlocTitleContentLibelle {
  margin: 60px 0 20px 0;
}

.errorTitle {
  background: -webkit-linear-gradient(left, #EB6608, #FFC95C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}
.errorBlock {
  margin: 150px 0;
}

.kartingValDoiseTitre {
  margin-bottom: 50px;
}
.kartingValDoiseLiensItineraire a {
  color: #686868;
}
.kartingValDoiseLiensItineraire a:hover {
  color: white;
}

.kartingNanterreTitre {
  margin-bottom: 50px;
}
.kartingNanterreLienAutresItineraires a {
  color: #686868;
}
.kartingNanterreLienAutresItineraires a:hover {
  color: white;
}

.kartingCormeillesLienAutresItineraires {
  color: white;
}
.kartingCormeillesLienAutresItineraires a {
  color: #686868;
}
.kartingCormeillesLienAutresItineraires a:hover {
  color: white;
}

.kartingCormeillesTitre {
  color: red;
}

.kartingPontoiseLiensItineraire a {
  color: #686868;
}
.kartingPontoiseLiensItineraire a:hover {
  color: white;
}

.karting95LiensItineraire a {
  color: #686868;
}
.karting95LiensItineraire a:hover {
  color: white;
}

.virtualLaserTitle, .virtualLaserUnderImg {
  background: -webkit-linear-gradient(left, #CC1E5E, #F0804D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.virtualLaserBackgroundImg {
  -webkit-background-size: 100%;
  background-size: 100% !important;
}
.virtualLaserVideo {
  margin: 40px 0 10px 0;
}
.virtualLaserLogo {
  width: 100%;
  max-width: 300px;
}
.virtualLaserPrices .row .col.m6:nth-child(2n+3) {
  clear: left;
}

.akBoutiqueErrorText {
  margin-top: 100px;
  text-align: center;
}

@media only screen and (min-width : 601px) {
  .container {
    width: 87%;
  }
}
@media only screen and (max-width : 992px) {
  .row .col.m6:nth-child(2n+3) {
    clear: left;
  }
}
@media only screen and (min-width: 1200px) {
  .akBirthdayTextDetail {
    margin-right: -2px;
  }
}
@media only screen and (max-width: 992px) {
  .homeHeader {
    display: flex;
    flex-direction: column-reverse;
  }
  .homeTitle {
    position: relative;
    height: auto;
  }
  .homeActivityContent {
    min-height: inherit;
  }
  .homeVideoLogo {
    text-align: center;
    position: relative;
    top: 10px;
    margin-bottom: 10px;
    z-index: 1;
    left: 50%;
    width: 60%;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  .headerLogo img {
    width: 240px;
    margin-top: 30px;
  }

  .footerBloc {
    padding-bottom: 0;
  }
  .footerBlocTitle {
    font-size: 20px;
    font-size: 2rem;
  }
}
@media screen and (max-height: 750px) {
  .akContent {
    width: 100%;
  }

  .menu {
    display: none;
  }
  .menuMobile {
    width: 100%;
    position: absolute;
    padding-top: 60px;
    background: #212631;
    line-height: 2;
  }
  .menuMobile .menuItem, .menuMobile .menuItemSubMenuItem {
    height: auto;
    min-height: 100px;
    line-height: 100px;
    background: #212631;
  }
  .menuMobile .menuItem:hover, .menuMobile .menuItemSubMenuItem:hover {
    background: #2A3646;
  }
  .menuMobile .menuItem .menuItemSubMenuItemGreen:hover, .menuMobile .menuItemSubMenuItem .menuItemSubMenuItemGreen:hover {
    background-color: #1A9643;
    background-image: -webkit-gradient(linear, left top, right top, from(#1A9643), to(#C0CA51));
    background-image: -webkit-linear-gradient(left, #1A9643, #C0CA51);
    background-image: -moz-linear-gradient(left, #1A9643, #C0CA51);
    background-image: -ms-linear-gradient(left, #1A9643, #C0CA51);
    background-image: -o-linear-gradient(left, #1A9643, #C0CA51);
    background-image: linear-gradient(left, #1A9643, #C0CA51);
    filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#1A9643", end-colourStr="#C0CA51", gradientType="1");
  }
  .menuMobile .menuItem .menuItemSubMenuItemOrange:hover, .menuMobile .menuItemSubMenuItem .menuItemSubMenuItemOrange:hover {
    background-color: #EB6608;
    background-image: -webkit-gradient(linear, left top, right top, from(#EB6608), to(#FFC95C));
    background-image: -webkit-linear-gradient(left, #EB6608, #FFC95C);
    background-image: -moz-linear-gradient(left, #EB6608, #FFC95C);
    background-image: -ms-linear-gradient(left, #EB6608, #FFC95C);
    background-image: -o-linear-gradient(left, #EB6608, #FFC95C);
    background-image: linear-gradient(left, #EB6608, #FFC95C);
    filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#EB6608", end-colourStr="#FFC95C", gradientType="1");
  }
  .menuMobile .menuItem .menuItemSubMenuItemBlue:hover, .menuMobile .menuItemSubMenuItem .menuItemSubMenuItemBlue:hover {
    background-color: #2A4DAD;
    background-image: -webkit-gradient(linear, left top, right top, from(#2A4DAD), to(#04BAEE));
    background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
    background-image: -moz-linear-gradient(left, #2A4DAD, #04BAEE);
    background-image: -ms-linear-gradient(left, #2A4DAD, #04BAEE);
    background-image: -o-linear-gradient(left, #2A4DAD, #04BAEE);
    background-image: linear-gradient(left, #2A4DAD, #04BAEE);
    filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#2A4DAD", end-colourStr="#04BAEE", gradientType="1");
  }
  .menuMobile .menuItemLink, .menuMobile .menuItemSubMenuItemLink {
    padding-left: 30px;
    position: relative;
  }
  .menuMobile .menuItemLink:after, .menuMobile .menuItemSubMenuItemLink:after {
    content: "D";
    font-family: "icomoon";
    position: absolute;
    right: 20px;
  }
  .menuMobile .menuItemLibelle {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2;
    text-align: center;
  }
  .menuMobile .menuItemSubMenu {
    position: relative;
    width: 100%;
    right: 0;
  }
  .menuMobile .menuItemSelected .menuItemLink:after, .menuMobile .menuItemSelected .menuItemSubMenuItemLink:after {
    content: "E";
  }
  .menuMobile .menuItemSelected .menuItemSubMenuItemLink:after {
    content: "D";
  }
  .menuMobile .menuItemSubMenuItem {
    background: #FFFFFF;
  }
  .menuMobile .menuItem, .menuMobile .menuItemSubMenuItem {
    text-align: left;
  }
  .menuMobile .menuItem i, .menuMobile .menuItemSubMenuItem i {
    font-size: 40px;
    font-size: 4rem;
    margin-top: 0;
    vertical-align: middle;
    margin-right: 30px;
  }
  .menuMobileOpen {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .birthdayRow {
    display: block;
  }

  .pricesBankLogos {
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }

  .akContent {
    width: 100%;
  }

  .header {
    height: auto;
  }
  .headerBasket {
    position: relative;
    right: inherit;
    top: inherit;
    float: right;
    margin: 10px;
  }
  .headerLogo {
    height: 60px;
    padding: 10px 0;
  }
  .headerLogo img {
    width: 220px;
    margin-top: 0px;
  }

  .menu {
    display: none;
  }
  .menuContent {
    height: 100%;
    overflow: auto;
  }
  .menuMobile {
    width: 100%;
    position: fixed;
    padding-top: 60px;
    background: #212631;
    z-index: 400;
  }
  .menuMobile .menuItem, .menuMobile .menuItemSubMenuItem {
    height: auto;
    min-height: 100px;
    line-height: 100px;
    background: #212631;
  }
  .menuMobile .menuItem:hover, .menuMobile .menuItemSubMenuItem:hover {
    background: #2A3646;
  }
  .menuMobile .menuItem .menuItemSubMenuItemGreen:hover, .menuMobile .menuItemSubMenuItem .menuItemSubMenuItemGreen:hover {
    background-color: #1A9643;
    background-image: -webkit-gradient(linear, left top, right top, from(#1A9643), to(#C0CA51));
    background-image: -webkit-linear-gradient(left, #1A9643, #C0CA51);
    background-image: -moz-linear-gradient(left, #1A9643, #C0CA51);
    background-image: -ms-linear-gradient(left, #1A9643, #C0CA51);
    background-image: -o-linear-gradient(left, #1A9643, #C0CA51);
    background-image: linear-gradient(left, #1A9643, #C0CA51);
    filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#1A9643", end-colourStr="#C0CA51", gradientType="1");
  }
  .menuMobile .menuItem .menuItemSubMenuItemOrange:hover, .menuMobile .menuItemSubMenuItem .menuItemSubMenuItemOrange:hover {
    background-color: #EB6608;
    background-image: -webkit-gradient(linear, left top, right top, from(#EB6608), to(#FFC95C));
    background-image: -webkit-linear-gradient(left, #EB6608, #FFC95C);
    background-image: -moz-linear-gradient(left, #EB6608, #FFC95C);
    background-image: -ms-linear-gradient(left, #EB6608, #FFC95C);
    background-image: -o-linear-gradient(left, #EB6608, #FFC95C);
    background-image: linear-gradient(left, #EB6608, #FFC95C);
    filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#EB6608", end-colourStr="#FFC95C", gradientType="1");
  }
  .menuMobile .menuItem .menuItemSubMenuItemBlue:hover, .menuMobile .menuItemSubMenuItem .menuItemSubMenuItemBlue:hover {
    background-color: #2A4DAD;
    background-image: -webkit-gradient(linear, left top, right top, from(#2A4DAD), to(#04BAEE));
    background-image: -webkit-linear-gradient(left, #2A4DAD, #04BAEE);
    background-image: -moz-linear-gradient(left, #2A4DAD, #04BAEE);
    background-image: -ms-linear-gradient(left, #2A4DAD, #04BAEE);
    background-image: -o-linear-gradient(left, #2A4DAD, #04BAEE);
    background-image: linear-gradient(left, #2A4DAD, #04BAEE);
    filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr="#2A4DAD", end-colourStr="#04BAEE", gradientType="1");
  }
  .menuMobile .menuItemLink, .menuMobile .menuItemSubMenuItemLink {
    padding-left: 30px;
    position: relative;
  }
  .menuMobile .menuItemLink:after, .menuMobile .menuItemSubMenuItemLink:after {
    content: "D";
    font-family: "icomoon";
    position: absolute;
    right: 20px;
  }
  .menuMobile .menuItemLibelle {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 400;
  }
  .menuMobile .menuItemSubMenu {
    position: relative;
    width: 100%;
    right: 0;
  }
  .menuMobile .menuItemSelected .menuItemLink:after, .menuMobile .menuItemSelected .menuItemSubMenuItemLink:after {
    content: "E";
  }
  .menuMobile .menuItemSelected .menuItemSubMenuItemLink:after {
    content: "D";
  }
  .menuMobile .menuItemSubMenuItem {
    background: #FFFFFF;
  }
  .menuMobile .menuItem, .menuMobile .menuItemSubMenuItem {
    text-align: left;
  }
  .menuMobile .menuItem i, .menuMobile .menuItemSubMenuItem i {
    font-size: 40px;
    font-size: 4rem;
    margin-top: 0;
    vertical-align: middle;
    margin-right: 30px;
  }
  .menuMobileOpen {
    display: block;
    position: fixed;
  }

  .shopCardBooking {
    padding: 20px 30px;
  }
  .shopCardBookingSelection {
    position: relative;
    top: inherit;
    transform: none;
    right: inherit;
    margin-top: 15px;
  }
}