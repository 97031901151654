.shop {

    &Products {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 40px;
        @media screen and ( min-width: $small-screen-up ) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: $large-screen-up) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

	.akContainerPage {
		overflow: inherit;
	}
	&LinkShopPictures{
		&:hover{
			.icon-photo::before{
				right: 100%;
			}
		}
		background: none;
		text-align: left;
		i::before{
			top:50%;
			transform: translateY(-50%);
		}
		.icon-photo::before{
			right: 100%;
			text-align: left;
			font-size: 35px;
		}
	}
	&Card {
		&Error {
			color: #ffffff;
			font-size: 18px;
			border: 1px solid red;
			margin-bottom: 40px;
			TEXT-ALIGN: CENTER;
		}
        &Avertissement {
            color: #ffffff;
            font-size: 16px;
            font-weight: bold;
            border: 1px solid red;
            margin-bottom: 40px;
            TEXT-ALIGN: CENTER;
        }
		&Infos {
			margin: 30px 0;
		}
		&Booking {
			@extend .akBgBloc;
			padding: 20px 180px 20px 30px;
			margin-bottom: 20px;
			position: relative;
			&.shopCardBookingGift {
				margin-bottom: 20px;
			}
			&Age {
				color: $akGreyText;
				margin-top: 10px;
			}
			&Selection {
				position: absolute;
				right: 20px;
				top: 50%;
				@include translate(0,-50%);
				&Btn {
					background: $akWhite;
					color: $akBlack;
					width: 40px;
					height: 40px;
					@include border-radius(50%);
					display: inline-block;
					vertical-align: middle;
					text-align: center;
					line-height: 4rem;
					@include font-size(5);
					font-weight: 300;
					cursor: pointer;
					&:hover {
						background: $akGrey;
					}
					&Disabled {
						background: $akGreyText;
						cursor: none;
						&:hover {
							background: $akGreyText;
							color: $akBlack;
						}
					}
					&Less {
						line-height: 3.4rem;
					}
				}
				&Choice {
					display: inline-block;
					vertical-align: middle;
					margin: 0 20px;
					@include font-size(2.1);
				}
			}
		}
		&GoToShop {
			@extend .akBgBlocLight;
			margin: 20px 0;
			padding: 30px;
			position: relative;
			&:after {
				content: "";
				display: table;
				clear: both;
			}
			&Checkbox{
				position: absolute!important;
				right: 15px;
				top:50%;
				transform: translateY(-50%);
			}
			&Text {
				width: 75%;
				font-weight: 400;
				@include font-size(1.6);
				display: inline-block;
				margin: 0 10px 0 0;
			}
			&Link {
				float: right;
			}
		}
		&Slots {
			@extend .akBgBloc;
			padding: 20px 30px;
			&Title {
				text-align: center;
				@include font-size(2.1);
				span {
					font-weight: 800;
				}
			}
			&Item {
				margin: 20px 0;
				&:after {
					content: "";
					display: table;
					clear: both;
				}
				&Icon {
					@include font-size(2.2);
					color: $akGreyText;
					display: inline-block;
					vertical-align: text-top;
					margin-top: 5px;
				}
				&Hours {
					@include font-size(2.1);
					display: inline-block;
					vertical-align: text-top;
					margin-left: 10px;
					&Number {
						color: $akGreyText;
						@include font-size(1.4);
						&None {
							@extend .shopCardSlotsItemHoursNumber;
							color: $akRed;
						}
					}
				}
				&Choose {
					@extend .akBtnFilterBlue;
					color: $akWhite;
					font-weight: 600;
					padding: 5px 8px;
					float: right;
					margin-top: 5px;
				}
				&Impossible {
					@extend .akBtnDisabled;
					@include font-size(1.4);
					padding: 5px 8px;
					float: right;
				}
			}
		}
		&Reservation {
			&:after {
				content: "";
				display: table;
				clear: both;
			}
			&Box {
				@extend .akBgBloc;
				margin: 30px 0 20px 0;
				padding: 20px 30px;
				&Title {
					@include font-size(2.1);
					font-weight: 400;
				}
				&Content {
					color: $akGreyText;
					&Price {
						font-weight: 800;
						color: $akWhite;
						@include font-size(3.6);
						line-height: 1.6rem;
						float: right;
					}
				}
			}
		}
		&Calendar {
			@extend .akBgBloc;
			margin-bottom: 20px;
			thead {
				@extend .akBgBlocLight;
				border-bottom: 0;
			}
			th {
				padding: 13px 0;
				border-radius: 0;
			}
			td {
				padding: 5px 0;
			}
			td, th {
				text-align: center;
				width: calc(100%/7);
			}
			abbr {
				border: 0;
				text-decoration: none;
				font-weight: 400;
			}
			.pika-title {
				min-height: 60px;
				line-height: 2.5rem;
				position: relative;
				padding: 15px 50px;
				text-align: center;
				@include font-size(2.1);
			}
			.pika-label {
				display: inline-block;
				vertical-align: middle;
				margin-right: 5px;
			}
			.pika-button {
				background: none;
				border: 0;
				padding: 0;
				width: 30px;
				height: 30px;
				text-align: center;
				// min-height: 30px;
				// min-width: 30px;
			}
			.pika-prev, .pika-next {
				background: none;
				border: 0;
				position: absolute;
				width: 20px;
				height: 60px;
				top: 0px;
				display: block;
				&:before {
					font-family: 'icomoon';
					display: inline-block;
					position: absolute;
					top: 0;
					left: 0;
					line-height: 6rem;
				}
			}
			.pika-prev {
				left: 20px;
				&:before {
					content: "\0066";
				}
			}
			.pika-next {
				right: 20px;
				&:before {
					content: "\0044";
				}
			}
			.is-today .pika-button {
				@include bg-gradient-l2r($akGradientBlueDark, $akGradientBlueLight);
				@include border-radius(50%);
				font-weight: 700;
			}
			.available .pika-button {
				background: $akWhite;
				color: $akBlack;
				@include border-radius(50%);
				font-weight: 700;
			}
		}
	}
	&Cart {
		margin-bottom: 40px;
		&Title {
			margin: 25px 0 30px 0;
		}
		&Content {
			@extend .akBgBloc;
			padding: 20px;
			color: $akGreyText;
			&PromoCode {
				font-weight: bold;
			}

			&:after {
				content: "";
				display: table;
				clear: both;

			}
			&Total {
				text-transform: uppercase;
				margin-top: 20px;
				span {
					@include font-size(3.6);
					font-weight: 800;
					color: $akWhite;
					float: right;
					line-height: 2rem;
				}
			}
		}
	}
	&Payment {
		background: $akWhite;
		padding: 20px;
		height: 160px;
		margin-bottom: 20px;
		img {
			margin: 20px auto;
			display: block;
		}
		[type="radio"]:not(:checked)+label, [type="radio"]:checked+label {
			@include font-size(2.1);
			font-weight: 400;
			color: $akBlackLight;
		}
		[type="radio"].with-gap:checked+label:before {
			@include border-gradient(left, $akGradientBlueDark, $akGradientBlueLight, 4px);
		}
		[type="radio"]:checked+label:after, [type="radio"].with-gap:checked+label:after {
			background-color: $akWhite;
		}
		&Selected {
			@include border-gradient(left, $akGradientBlueDark, $akGradientBlueLight, 3px);
		}
	}
	&MoreInfos {
		margin-top: 10px;
		position: relative;
		&ToolTip{
			display: none;
			z-index: 10;
			position: absolute;
			background: $akBlackLight;
			padding:20px;
			margin-top: 16px;
			margin-right: 30px;
			&:before {
				content: '';
				background: $akBlackLight;
				display: block;
				position: absolute;
				top: 0;
				left: 13px;
				width: 20px;
				height: 20px;
				transform: translateY(-50%) rotate(45deg);
			}
			* {
				display: block;
			}
		}
		&:hover ~ .shopMoreInfosToolTip {
			display: block;
		}
	}
	&CodePromo {
		.akFormInputField {
			width: calc(100% - 90px);
			float: left;
		}
		button {
			width: 90px;
			height: 45px;
			float: left;
		}
	}
}

[type="checkbox"]:checked + .shopCardGoToShopCheckbox:before{
	border-right: 2px solid $akWhite;
	border-bottom: 2px solid $akWhite;
}
