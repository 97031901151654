.birthday{
  &Title{
    @include text-gradient(left, $akGradientPinkDark, $akGradientPinkLight);
    text-transform: uppercase;
    &Birthday,
    &Evj{
      margin: 40px 0 20px 0;
    }
    &Under{
      margin-bottom: 20px;
    }
  }

  &PromoBanner {
    font-weight: bold;
  }

  .akTitle4{
    margin-bottom: 10px;
  }

  &BackgroundImg{
    background: url("../../images/birthday/HeaderImage_Anniversaire_mobile.png") no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
      @media screen and ( min-width: $small-screen-up ) {
          background: url("../../images/birthday/HeaderImage_Anniversaire.png") no-repeat;
      }
  }

    &Img{
        display: flex;
        height: auto;
        width:100%;
        margin: 10px 0 40px 0;

        & img {
            height: auto;
            max-width: 100%;
            width: 100%;
        }
    }

  &Pack{
    &PetitAventurier,
    &Karting,
    &FreeFall,
    &VirtualLaser,
    &Winscape{
      .akTextSecondary{
        margin-bottom: 20px;
      }
    }
    &KartingTitle{
      @include text-gradient(left, $akGradientOrangeDark, $akGradientOrangeLight);
      text-transform: none;
    }
    &WinscapeTitle,
    &VirtualLaserTitle{
      @include text-gradient(left, $akGradientBlueDark, $akGradientBlueLight);
      text-transform: none;
    }
    &PetitAventurierTitle {
      @include text-gradient(left, $akGradientPinkDark, $akGradientPinkLight);
      text-transform: none;
    }
    &FreeFallTitle {
      @include  text-gradient(left, $akGradientGreenDark,$akGradientGreenLight );
    }
  }
  &Row {
    display: flex;
  }
  &Col {
    display: flex;
    flex: 1 1;
  }
  &Infos{
    margin-top: 100px;
  }
}

.akCardContentInfos {
  flex: 1;
}
