.buisness{
  &Title{
    @include text-gradient(left, $akGradientBlueDark, $akGradientBlueLight);
    text-transform: uppercase;

    &Under{
      margin-bottom: 60px;
    }
  }

  .akTitle4{
    margin-bottom: 10px;
  }

  &BackgroundImg{
    background: url("../../images/buisness/HeaderImage_Entreprise_mobile.png") no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    @media screen and ( min-width: $small-screen-up ) {
      background: url("../../images/buisness/HeaderImage_Entreprise.png") no-repeat;
    }
  }

  &Liste{
    position: relative;
    margin-left: 10px;
    margin-bottom: 10px;

    li{
      margin-bottom: 5px;
    }
    li:before {
      content: "- ";
      position: absolute;
      left: -10px;
    }
  }

  &Img{
    display: flex;
    height: auto;
    width:100%;
    margin: 20px 0;

      & img {
          height: auto;
          max-width: 100%;
          width: 100%;
      }
  }

  &Download{
    margin-top: 40px;
    &Btn{
      margin-left:40px;
    }
  }

  &Hours{
    margin-top: 100px;
    &Day{
      margin:0;
    }
  }
  &GalleryImg, &HeaventParis{
    height: auto;
    width: 100%;
  }
}
