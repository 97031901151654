.header {
	background-color: rgba(0, 6, 10, 0.7);
	height: 110px;
	position: absolute;
	width: 100%;
	z-index: 1;
	.container {
		position: relative;
	}
	&Logo {
		img {
			margin-top: 18px;
			width: 400px;
		}
	}
	&Basket {
		position: absolute;
		top: 25px;
		right: 0;
		z-index: 2;
		&Price {
			@extend .akTitle4;
		}
		&See {
			color: $akWhite;
			&:hover {
				color: $akGreyText;
			}
		}
	}
}