.freeFall {
    &Title, &UnderImg {
        @include text-gradient(left, $akGradientGreenDark, $akGradientGreenLight);
    }

    &BackgroundImg {
        -webkit-background-size: 100%;
        background-size: 100% !important;
    }

    &Hours {
        .akTextSecondary {
            line-height: .7;
        }
    }

    &LogoSoufflerie {
        width: 200px !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
