.akBtn {
	@include font-size(1.6);
	font-weight: 600;
	padding: 8px 18px;
	position: relative;
	display: inline-block;
	cursor:pointer;
	color: $akWhite;
	text-align: center;
	background: $akGrey;
	border: 0;
	font-style: normal;
	@include transition(all 0.3s);
	&:hover {
		opacity: 0.9;
	}
	&Filter {
		@extend .akBtn;
		background: transparent;
		&Green {
			@include border-gradient(left, $akGradientGreenDark, $akGradientGreenLight, 1px);
			&:hover {
				background: none;
				@include bg-gradient-l2r($akGradientGreenDark, $akGradientGreenLight);
			}
		}
		&Orange {
			@include border-gradient(left, $akGradientOrangeDark, $akGradientOrangeLight, 1px);
			&:hover{
				background: none;
				@include bg-gradient-l2r($akGradientOrangeDark, $akGradientOrangeLight);
			}
		}
		&Blue {
			@include border-gradient(left, $akGradientBlueDark, $akGradientBlueLight, 1px);
			&:hover {
				background: none;
				@include bg-gradient-l2r($akGradientBlueDark, $akGradientBlueLight);
			}
		}
		&Pink {
			@include border-gradient(left, $akGradientPinkDark, $akGradientPinkLight, 1px);
			&:hover {
				background: none;
				@include bg-gradient-l2r($akGradientPinkDark, $akGradientPinkLight);
			}
		}
	}
	&Green {
		@extend .akBtn;
		@include bg-gradient-l2r($akGradientGreenDark, $akGradientGreenLight);
	}
	&Orange {
		@extend .akBtn;
		@include bg-gradient-l2r($akGradientOrangeDark, $akGradientOrangeLight);
	}
	&Blue {
		@extend .akBtn;
		@include bg-gradient-l2r($akGradientBlueDark, $akGradientBlueLight);
	}
	&Pink {
		@extend .akBtn;
		@include bg-gradient-l2r($akGradientPinkDark, $akGradientPinkLight);
	}
	&White {
		@extend .akBtn;
		color: $akBlack;
		background: $akWhite
	}
	&WithIcon {
		padding-right: 40px;
		i {
			&:before {
				@include transition(all 0.3s);
			    position: absolute;
			    right: 15px;
			    top: 11px;
			}
		}
		&:hover {
			i:before {
				right: 10px;
			}
		}
	}
	&WithIconLeft {
		@extend .akBtnWithIcon;
		padding-right: 18px;
		padding-left: 40px;
		i {
			&:before {
			    left: 15px;
			    right: inherit;
			}
		}
		&:hover {
			i:before {
				left: 10px;
				right: inherit;
			}
		}
	}
	&Disabled {
		@extend .akBtn;
		background: $akGreyText;
		color: $akBlack;
		cursor: none;
		&:hover {
			color: $akBlack;
			opacity: 1;
		}
	}
}
