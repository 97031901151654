$nbSlideshowItem : 4;

.slideshow{
  overflow: hidden;
  padding-bottom: 40px;
  text-align: center;
  &Content{
    width: calc(100% * #{$nbSlideshowItem});
  }

  /* Dots */
  [class^="slideshowDot"]{
    margin:20px 15px;
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: $akWhite;
    transition: all .3s ease;
    cursor: pointer;
  }
  #dot1:checked ~ .slideshowDot1,
  #dot2:checked ~ .slideshowDot2,
  #dot3:checked ~ .slideshowDot3,
  #dot4:checked ~ .slideshowDot4{
    background: none;
    border: solid 2px $akWhite;
  }

  #dot1:checked ~ .slideshowContent [class^="slideshowItem"]{
    transform: translateX(0);
  }
  #dot2:checked ~ .slideshowContent [class^="slideshowItem"]{
    transform: translateX(-100%);
  }
  #dot3:checked ~ .slideshowContent [class^="slideshowItem"]{
    transform: translateX(-200%);
  }
  #dot4:checked ~ .slideshowContent [class^="slideshowItem"]{
    transform: translateX(-300%);
  }

  [class^="slideshowItem"]{
    width: calc(100% / 4);
    height:100%;
    float: left;
    transition: transform .2s ease;

    img{
      height: auto;
      width: 100%;
      display: block;
    }
  }
}
