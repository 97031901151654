.shopPictures{
  .akContainerPage{
    overflow: initial;
  }
  &Menu{
    padding-top:20px;
    @extend .akBgBloc;
    nav{
      background: none;
      height:100%;
      float: left;
      width: auto;
    }
    .categories{
      margin-bottom: 10px;
      display: inline-block;
      &-container{
        line-height:0;
        &.pinned{
          position: relative !important;
        }
      }
      .akFilterItem{
        height: 45px;
        line-height: 1;
        margin: 0px 20px 10px 0;
        .akFilterItemLink{
          height: 45px;
          line-height: 45px;
          padding: 0 18px;
          margin: 0;
        }
      }
    }
    /*créneau*/
    .creneau{
      label{
        font-size: 11px;
      }
      .caret{
        display: none;
      }
      input{
        font-size: 16px;
      }
      .dropdown-content.select-dropdown{
        @extend .akBgBloc;
        li{
          span{
            color: $akWhite;
          }
          &:hover{
            background: $akBlackLight;
          }
          &.active{
            background: $akWhite;
            span{
              color: $akBlack;
            }
          }
          &:first-child{
            background: $akGrey;
            span{
              color: $akGreyText;
            }
          }
        }
      }
    }
    &Error{
      color: $akRed;
      margin-bottom: 20px;
      display: block;
      float: right;
    }
  }

  &Inputs{
    float: right;
    width: 100%;
    > *{
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  &Submit{
    float: right;
  }
  .gallery-item{
    &.Karting.select .shopPicturesCard{
      @include border-gradient(left, $akGradientOrangeDark, $akGradientOrangeLight, 4px);
    }
    &.Winscape.select .shopPicturesCard{
      @include border-gradient(left, $akGradientBlueDark, $akGradientBlueLight, 4px);
    }
    &.ChuteLibre.select .shopPicturesCard{
      @include border-gradient(left, $akGradientGreenDark, $akGradientGreenLight, 4px);
    }
  }

  /* Shop Pictures Cards */
  &Card{
    padding: 20px 20px 0 20px;
    margin-bottom: 20px;
    @extend .akBgBloc;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    background-image: -webkit-linear-gradient(left, transparent, transparent), -webkit-linear-gradient(left, transparent, transparent);
    &Img{
      display: block;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
      &:before{
        content: '';
        position: absolute;
        z-index:100;
        height:100%;
        width: 100%;
        background: $akWhite;
        opacity: 0;
        transition: opacity .3s ease;
      }
      &:after{
        font-family: 'icomoon';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 45px;
        color: $akGrey;
        content: '\e903';
        position: absolute;
        z-index:200;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity .3s ease;
      }
      img{
        display: block;
        width: 100%;
        transition: all .3s ease;
      }
      &:hover{
        img{
          transform: scale(1.2);
        }
        &:before{
          opacity: .3;
        }
        &:after{
          opacity: 1;
        }
      }
    }
    &Infos{
      float: left;
    }
    &Category{
      display: block;
      font-size: 1.5rem;
    }
    &Date{
      color: $akGreyText;
    }
    &Price{
      float: right;
      margin-bottom: 20px;
      @extend .akTitle3;
    }
    &Control {
      margin-bottom: 0;
      .akBtn {
        width: 100%;
        margin-bottom: 20px;
      }
      &Select[type="checkbox"] + label {
        @include font-size(1.6);
        height: 40px;
        padding: 8px 18px;
        &:before, &:after {
          margin-top: 10px;
          margin-left: 10px;
        }
      }
      &Select[type="checkbox"]:checked+label{
        &:before{
          border-right: 2px solid $akGrey;
          border-bottom: 2px solid $akGrey;
        }
        &:after {
          border: 2px solid $akWhite;
          background-color: $akWhite;
        }
      }
    }
  }

  &AddPanier{
    @extend .akBgBloc;
    position: fixed;
    z-index: 900;
    bottom: 0;
    left:0;
    width: 100%;
    &.no-stick{
      position: relative;
    }
    .row{
      margin: 0;
    }
    &Amount, &Btn{
      margin: 10px;
      float: right;
    }
    &AmountPrice {
      font-weight: bold;
    }
    .js-scrollTo{
      float: right;
      display: inline-block;
      line-height: 40px;
      margin: 10px 0;
      width: 40px;
      height: 40px;
      background: $akWhite;
      color: $akBlack;
      text-align: center;
    }
  }

  /* Form */
  &Input{
    margin-bottom: 20px;
    position: relative;
    span{
      position: absolute;
      font-size: 11px;
    }
    Input[type="text"]{
      margin-bottom: 0;
    }
  }

  .inputNumPicture{
    .input-field{
      overflow: hidden;
    }
    label{
      white-space: nowrap;
    }
  }

  /* Date Picker */
  #datepicker{
    font-size: 16px;
    margin-top: 1rem;
  }
  .pika-single {
    z-index:1000;
    &.is-hidden{
      display: none;
    }
    @extend .akBgBloc;
    margin-bottom: 20px;
    thead {
      @extend .akBgBlocLight;
      border-bottom: 0;
    }
    th {
      padding: 13px 0;
      border-radius: 0;
    }
    td {
      padding: 5px 0;
    }
    td, th {
      text-align: center;
      width: calc(100%/7);
    }
    abbr {
      border: 0;
      text-decoration: none;
      font-weight: 400;
    }
    .pika-title {
      min-height: 60px;
      line-height: 2.5rem;
      position: relative;
      padding: 15px 50px;
      text-align: center;
      @include font-size(2.1);
    }
    .pika-label {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    .pika-button {
      background: none;
      border: 0;
      padding: 0;
      width: 30px;
      height: 30px;
      text-align: center;
      // min-height: 30px;
      // min-width: 30px;
    }
    .pika-prev, .pika-next {
      background: none;
      border: 0;
      position: absolute;
      width: 20px;
      height: 60px;
      top: 0px;
      display: block;
      &:before {
        font-family: 'icomoon';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        line-height: 6rem;
      }
    }
    .pika-prev {
      left: 20px;
      &:before {
        content: "\0066";
      }
    }
    .pika-next {
      right: 20px;
      &:before {
        content: "\0044";
      }
    }
    .is-today .pika-button {
      @include bg-gradient-l2r($akGradientBlueDark, $akGradientBlueLight);
      @include border-radius(50%);
      font-weight: 700;
    }
    .available .pika-button {
      background: $akWhite;
      color: $akBlack;
      @include border-radius(50%);
      font-weight: 700;
    }
  }
  &Loader {
    position: absolute;
    border: 5px solid $akWhite;
    border-radius: 50%;
    border-top: 5px solid $akGradientBlueLight;
    width: 40px;
    height: 40px;
    margin-left: -20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    & ~ p {
      padding-top: 45px;
    }
  }
}

@media screen and ( min-width: $small-screen-up ) {
  .shopPictures{
    &AddPanier{
      .row{
        margin-right: 160px;
      }
      &Amount {
        height: 40px;
        display: inline-block;
        line-height: 40px;
      }
      &.no-stick{
        .row{
          margin-right: 0;
        }
        .js-scrollTo{
          right: 10px;
        }
      }
    }
  }
}

@media screen and ( min-width: $large-screen ) {
  .shopPicturesInputs{
      width:auto;
    }
}
