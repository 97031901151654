.footer {
	&Bloc {
		padding: 50px;
		&Title {
			@extend .akTitle4;
			margin-bottom: 15px;
		}
		&List {
			margin-bottom: 10px;
			&Link {
				@include font-size(1.8);
				font-weight: 600;
			}
		}
		&SocialLink {
			@include font-size(2.4);
			margin-right: 15px;
		}
	}
	&Copyright {
		background: $akBlack;
		@include font-size(1.4);
		height: 60px;
		padding: 20px 50px;
		color: $akGreyText;
	}
}