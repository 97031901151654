.virtualLaser {
	&Title, &UnderImg {
		@include text-gradient(left, $akGradientPinkDark, $akGradientPinkLight);
	}
	&BackgroundImg {
		-webkit-background-size: 100%;
		background-size: 100% !important;
	}
	&Video{
		margin: 40px 0 10px 0;
	}
	&Logo {
		width: 100%;
		max-width: 300px;
	}
	&Prices {
		//pour éviter que le dernier encart se retrouve sur la droite
		.row .col.m6:nth-child(2n+3) {
			clear: left;
		}
	}
}
