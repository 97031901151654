.winscape{
  &Title, &UnderImg{
    @include text-gradient(left, $akGradientBlueDark, $akGradientBlueLight);
  }
  &BackgroundImg{
    //background: url(../../images/winscape/HeaderImage_Winscape.png) no-repeat;
    -webkit-background-size: 100%;
    background-size: 100% !important;
    .akContainerPage{
      overflow: inherit;
    }
  }
  #parcours{
    position: relative;
  }
  &Tooltip{
    &Hover{
      position: absolute;
      width:100%;
      left:0;
      z-index:10;
      background: $akBlack;
      padding: 30px;
      display: none;
    }
    &Hide{
      position: relative;
      &:after{
        content: '';
        position: absolute;
        height:20px;
        width: 20px;
        left:50%;
        bottom:0;
        transform: translate(-50%, 150%) rotate(45deg);
        background: $akBlack;
        display: none;
      }
      &:hover ~ .winscapeTooltipHover, &:hover:after{
        display: block;
      }
    }
  }
}
