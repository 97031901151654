$pathFont: '../../fonts/';

@font-face {
    font-family: 'icomoon';
    src:  url('#{$pathFont}/icomoon/icomoon.eot?d7djqb');
    src:  url('#{$pathFont}/icomoon/icomoon.eot?d7djqb#iefix') format('embedded-opentype'),
    url('#{$pathFont}/icomoon/icomoon.woff2?d7djqb') format('woff2'),
    url('#{$pathFont}/icomoon/icomoon.ttf?d7djqb') format('truetype'),
    url('#{$pathFont}/icomoon/icomoon.woff?d7djqb') format('woff'),
    url('#{$pathFont}/icomoon/icomoon.svg?d7djqb#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class*='icon-']:before {
	display: inline-block;
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.icon-actualites:before {
	content: '\0041';
}

.icon-amis:before {
	content: '\0042';
}

.icon-anniversaire:before {
	content: '\0043';
}

.icon-arrow-right:before {
	content: '\0044';
}

.icon-arrow-up:before {
	content: '\0045';
}

.icon-boutique:before {
	content: '\0046';
}

.icon-casiers:before {
	content: '\0047';
}

.icon-chute-libre:before {
	content: '\0048';
}

.icon-collegues:before {
	content: '\0049';
}

.icon-combinaison:before {
	content: '\004a';
}

.icon-contact:before {
	content: '\004b';
}

.icon-activites:before {
	content: '\004c';
}

.icon-cross:before {
	content: '\004d';
}

.icon-download:before {
	content: '\004e';
}

.icon-entreprise:before {
	content: '\004f';
}

.icon-facebook:before {
	content: '\0050';
}

.icon-famille:before {
	content: '\0051';
}

.icon-instagram:before {
	content: '\0052';
}

.icon-karting:before {
	content: '\0053';
}

.icon-menu:before {
	content: '\0054';
}

.icon-panier:before {
	content: '\0055';
}

.icon-photo:before {
	content: '\0056';
}

.icon-tarifs:before {
	content: '\0057';
}

.icon-twitter:before {
	content: '\0058';
}

.icon-video:before {
	content: '\0059';
}

.icon-winscape:before {
	content: '\005a';
}

.icon-youtube:before {
	content: '\0061';
}

.icon-moniteur:before {
	content: '\0062';
}

.icon-privatise:before {
	content: '\0063';
}

.icon-corbeille:before {
	content: '\0064';
}

.icon-arrow-down:before {
	content: '\0065';
}

.icon-arrow-left:before {
	content: '\0066';
}

.icon-child:before {
	content: '\e900';
}

.icon-adult:before {
	content: '\e901';
}

.icon-voucher:before {
	content: '\e902';
}

.icon-glass:before {
	content: '\e903';
}

.icon-laser:before {
	content: '\0067';
}

.icon-member:before {
	content: '\0068';
}
