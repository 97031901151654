.kartingValDoise {

  &Titre {
    margin-bottom: 50px;
  }
  &LiensItineraire{
    a {
      color: #686868;
    }
    a:hover {
      color: white;
    }
  }
}



