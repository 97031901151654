.home {
	.header {
		background: none;
		&Logo {
			display: none;
		}
	}
	&Video {
		position: relative;
		overflow: hidden;
		width: 100%;
		z-index: 0;
		video {
			display: block;
			width: 100%;
			height: auto;
		}
		&Mask {
			position: absolute;
			width: 100%;
			height: 100%;
			@extend .akBgBloc;
		}
		&Logo {
			text-align: center;
			position: absolute;
			z-index: 1;
			top: 20px;
			left: 50%;
			width: 90%;
			@include translate(-50%, 0);
			img {
				width: 700px;
			}
		}
		&Infos {
			max-width: 515px;
			position: absolute;
			bottom: 0;
			border-left: 1px solid $akWhite;
			padding: 0 0 60px 40px;
			&Title {
				@extend .akTitle2;
				&:before {
					display: none;
				}
			}
			&Content {
				@include font-size(1.8);
			}
		}
	}
	&Title {
		display: block;
		width: 100%;
		height: 100px;
		position: absolute;
		z-index: 1000;
	}
	&Banner {
		height: 150px;
		width: 100%;
		background-size: cover;
		z-index: 300;
		display: block;
		& + .homeVideo {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	&Activity {
		height: 320px;
		color: $akWhite;
		background-size: cover;
		background-position: bottom center;
		background-repeat: no-repeat;
		position: relative;
		display: block;

        & picture {
            display: flex;
            height: 100%;
            width: 100%;
        }

        &Image {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &Hover {
            width: 100%;
            height: 100%;
            opacity: 0;
            position: absolute;
            top: 0;
            @include transition(all 0.3s);

            &:hover {
                opacity: 0.85;
            }
        }

		&KartingHover {
            @include bg-gradient-l2r($akGradientOrangeDark, $akGradientOrangeLight);
		}
		&FreeFallHover {
            @include bg-gradient-l2r($akGradientGreenDark, $akGradientGreenLight);
		}
		&WinscapeHover {
            @include bg-gradient-l2r($akGradientBlueDark, $akGradientBlueLight);
		}
		&VirtualLaserHover {
            @include bg-gradient-l2r($akGradientPinkDark, $akGradientPinkLight);
		}
		&Content {
			position: absolute;
			bottom: 0;
			min-height: 132px;
			padding: 30px;
			&Title {
				@extend .akTitle3;
				position: relative;
				margin: 0 0 20px 20px;
				&:before {
					content: "";
					width: 30px;
					margin-left: -30px;
					height: 1px;
					background: $akWhite;
					position: absolute;
					left: -20px;
					top: 50%;
					@include translate(0, -50%);
				}
			}
		}
	}
	&Public {
		margin: 80px 0 30px 0;
		&Title {
			@include text-gradient(left, $akGradientGreenDark, $akGradientGreenLight);
			text-transform: uppercase;
			margin-bottom: 80px;
		}
		&Bloc {
			text-align: center;
			margin-bottom: 50px;
			&Icon {
				@include font-size(8);
				line-height: 8rem;
			}
			&Libelle {
				@include font-size(2.4);
				font-weight: 400;
				text-transform: uppercase;
				margin: 10px 0 20px 0;
			}
		}
	}
	&Contact {
		margin: 80px 0;
		&Infos {
			margin-bottom: 40px;
			&MorePrecisions {
				a {
					color: #686868;
				}
				a:hover {
					color: white;
				}

			}
			&Title {
				@extend .akTitle4;
				&Direction {
					font-style: italic;
				}
				&Under {
					font-weight: bold;
					color: $akWhite;
				}
			}
			&Text {
				color: c;
				font-weight: 400;
				&Number {
					font-weight: 700;
					color: $akWhite;
					float: right;
				}
			}
		}
		&Form {
			.akFormInputField {
				margin-bottom: 20px;
			}
			[type="checkbox"].filled-in + label {
				color: $akGreyText;
				line-height: 1.2;
			}
		}
	}
}
