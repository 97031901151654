.freeFall,
.karting,
.winscape,
.virtualLaser {
	&Title {
		text-transform: uppercase;
		margin-bottom: 10px;
	}
	&Logo {
        height: auto;
		width: 100%;
		max-width: 300px;
	}
    &Video{
        margin: 40px 0 10px 0;
    }
	&Img {
		display: block;
		margin-top: 40px;

        & img {
            height: auto;
            max-width: 100%;
        }
	}
	&UnderImg {
		margin-top: 10px;
	}
	&UnderImg, &DownloadText {
		font-style: italic;
	}
	&DownloadText {
		display: inline-block;
		width: auto;
		padding-top: 10px;
	}
	&Download {
		margin-top: 40px;
		&Btn {
			margin-left: 40px;
		}
	}
	&Prices {
		&Title {
			margin: 60px 0 40px 0;
		}
	}
	&Hours {
		&Day {
			margin: 0;
		}
	}
	&GalleryImg {
		width: 100%;
	}
	&LittleMore {
		background: $akBlack;
		padding: 10px 30px;
		text-align: center;

		.akTitle4 {
			margin-bottom: 30px;
		}
		[class^="icon-"] {
			font-size: 45px;
			font-size: 4.5rem;
		}

	}

  ul{
    position: relative;
    margin-left: 10px;

    li:before {
      content: "• ";
      position: absolute;
      left: -10px;
    }
  }
}
