@import '../layout/_header.scss';
@import '../layout/_menu.scss';
@import '../layout/_footer.scss';

html {
	font-size: 62.5%;
}

body {
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
	@include font-size(1.6);
	background-color: $akBlackLight;
	color: $akWhite;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

h1, h2, h3, h4, .akTitle1, .akTitle2, .akTitle3, .akTitle4 {
	font-weight: 800;
}

h5, h6, .akTitle5, .akTitle6 {
	font-weight: 400;
}

h1, .akTitle1 {
	@include font-size(4);
	line-height: 5.2rem;
}

h1 > span {
	display: block;
}

h2, .akTitle2 {
	@include font-size(3.6);
	line-height: 3.8rem;
	position: relative;
}

.akTitle2:before {
	content: "";
	width: 500px;
	margin-left: -500px;
	height: 1px;
	background: $akWhite;
	position: absolute;
	left: -30px;
	top: 50%;
	@include translate(0, -50%);
}

.akTitle2.withNoLine:before {
	display: none;
}

h3, .akTitle3 {
	@include font-size(2.8);
	line-height: 3.2rem;
}

h4, h5, .akTitle4, .akTitle5 {
	@include font-size(2.1);
	line-height: 2.5rem;
	text-transform: uppercase;
}

h6, .akTitle6 {
	@include font-size(1.8);
	line-height: 2.2rem;
}

/* Titles responsive min 600px */
@media screen and (min-width: 600px) {
	h1, .akTitle1 {
		@include font-size(5);
		line-height: 5.8rem;
	}
	h2, .akTitle2 {
		@include font-size(4);
		line-height: 4.5rem;
		position: relative;
	}
	h3, .akTitle3 {
		@include font-size(3);
		line-height: 3.3rem;
	}
	h4, h5, .akTitle4, .akTitle5 {
		@include font-size(2.3);
		line-height: 2.6rem;
		text-transform: uppercase;
	}
	h6, .akTitle6 {
		@include font-size(2);
		line-height: 2.4rem;
	}
}

/* Titles responsive min 992px */
@media screen and (min-width: 992px) {
	h1, .akTitle1 {
		@include font-size(8);
		line-height: 8.4rem;
	}
	h2, .akTitle2 {
		@include font-size(4.8);
		line-height: 5.5rem;
		position: relative;
	}
	h3, .akTitle3 {
		@include font-size(3.6);
		line-height: 4rem;
	}
	h4, h5, .akTitle4, .akTitle5 {
		@include font-size(2.4);
		line-height: 2.8rem;
		text-transform: uppercase;
	}
	h6, .akTitle6 {
		@include font-size(2.1);
		line-height: 2.5rem;
	}
}

nav {
	box-shadow: none;
	z-index: 2;
}

strong {
	font-weight: bold;
}

.clear {
	clear: both;
}

.akText {
	&Secondary {
		color: $akGreyText;
	}
	&Primary {
		font-weight: bold;
		text-transform: uppercase;
	}
}

a {
	color: $akGreyText;
	&:hover {
		color: $akWhite;
	}
}

.akLink {
	&Primary {
		font-weight: bold;
		color: $akWhite;
		white-space: nowrap;
	}
	&FreeFall {
		color: $akGreen;
	}
	&Karting {
		color: $akOrange
	}
	&Winscape {
		color: $akBlue;
	}
	&VirtualLaser {
		color: $akGradientPinkLight;
	}
}

a:focus, input:focus {
	outline: 0 !important;
}

ul {
	padding: 0;
}

.akContent {
	width: calc(100% - 170px);
	position: relative;
}

.akMainContent {
	background: url("../../images/bg-content.png") repeat;
	position: relative;

}

.akContainerPage {
	padding-top: 160px;
	overflow: hidden;
	min-height: 560px;
}

.akBreadCrumb {
	color: $akGreyText;
	margin-bottom: 10px;
}

.akBgBloc {
	background-color: rgba(0, 6, 10, 0.7);
}

.akBgBlocLight {
	background-color: rgba(153, 204, 255, 0.12);
}

.akTextGrey {
	color: $akGreyText;
}

.akTextRed {
	color: $akRed;
}

.akTextOrange {
	color: $akOrange;
}

.akTextGreen {
	color: $akGreen;
}

.akError {
	color: $akRed;
	display: block;
	@include font-size(1.2);
	margin: -15px 0 30px 5px;
	font-style: italic;
}

.akSuccess {
	@extend .akError;
	color: $akGreen;
}

/* Ajustement de la grille materialize */
.container .row {
	margin-left: -20px;
	margin-right: -20px;
}

.row .col {
	padding: 0 20px;
}

/* Petite gouttière */
.container .gutter-s {
	margin-left: -10px;
	margin-right: -10px;
}

.gutter-s .col {
	padding: 0 10px;
}

.clearfix {
	height: 1%
}

.clearfix:after {
	content: ".";
	height: 0;
	line-height: 0;
	display: block;
	visibility: hidden;
	clear: both;
}

.akFlexColumn {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
}
