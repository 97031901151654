.akCard {
	color: $akWhite;
	background-color: rgba(0, 6, 10, 0.5);
	margin-bottom: 40px;
	&.akCardNoBtn {
		.akCardContentPrice {
			float: right;
		}
	}
	&.akCardBasket {
		.akCardContentPrice {
			@include font-size(2.1);
			font-weight: 400;
			float: right;
		}
	}
	&.akCardBlue {
		.akCardTitle {
			background-image: url("../../images/cards/header-winscape.jpg");
		}
	}
	&.akCardOrange {
		.akCardTitle {
			background-image: url("../../images/cards/header-karting.jpg");
		}
	}
	&.akCardPink {
		.akCardTitle {
			background-image: url("../../images/cards/header-event.jpg");
		}
	}
	&.akCardGreen {
		.akCardTitle {
			background-image: url("../../images/cards/header-chute-libre.jpg");
		}
	}
	&Title {
		padding: 11px 20px;
		background-size: cover;
		position: relative;
		&Libelle {
			@extend .akTitle4;
		}
		&Delete {
			position: absolute;
			right: 20px;
			top: 13px;
			color: $akWhite;
			font-weight: 600;
			@include font-size(1.4);
			i {
				@include font-size(1.6);
				vertical-align: middle;
			}
		}
	}
	&Content {
		padding: 15px 20px;
		overflow: hidden;
		&Container {
			display: flex;
			flex-direction: column;
		}
		&Text {
			@extend .akTitle6;
			margin-bottom: 10px;
			&Small {
				font-weight: 300;
				@include font-size(1.6);
				color: $akGreyText;
				margin-bottom: 10px;
			}
		}
		&Row{
			width:100%;
			content: "";
			display: table;
			clear: both;
		}
		&Price {
			margin-left: auto;
			@extend .akTitle3;
			display: inline-block;
			&From {
				font-weight: 400;
				@include font-size(1.6);
			}
			&Detail {
				@include font-size(1.6);
				color: $akWhite;
				font-weight: 400;
			}
		}
		&Adult,
		&Child{
			float: left;
			width:50%;
		}
		&Infos{
			float: left;
		}
	}
	&IconChild,
	&IconAdult{
		margin-top: 10px;
	}
	&IconChild,
	&IconAdult,
	&IconVoucher{
		font-size: 45px;
		font-size: 4.5rem;
		display: inline-block;
		color: $akGreyText;
		margin-right: 10px;
		float: left;
	}
	&Adult,
	&Child{
		display: block;
		color: $akGreyText;
	}
	[class^="akBtn"] {
		float: right;
	}

	/* Cards page winscape */
	&Winscape,
	&VirtualLaser{
		margin-bottom: 0;
		&Img{
			width: 100%;
		}
		&Infos{
			text-align: center;
			padding:20px;
			&Price{
				@extend .akTitle3;
			}
		}
	}
}
