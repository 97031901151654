.technologic,
.brainstorm,
.fastaction,
.finaltraining{
  &BackgroundImg{
    background: url(../../images/winscape/HeaderImage_Winscape.png) no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
  }

  &Logo{
    width:100%;
    max-width:300px;
  }
  &Title{
    &Under{
      margin-bottom: 30px;
    }
  }
  &Img{
    margin-bottom: 40px;
    width:100%;
  }

  &Hours{
    &Day{
      margin:0;
    }
  }
}
