.akForm {
	[type="checkbox"].filled-in:checked+label:after {
		background-color: $akWhite;
		border-color: $akWhite;
	}
	[type="checkbox"].filled-in:checked+label:before {
		border-right: 2px solid $akBlack;
		border-bottom: 2px solid $akBlack;
	}
	[type="checkbox"]+label {
		@include font-size(1.6);
		color: $akWhite;
		a {
			color: $akGreyText;
			&:hover {
				color: $akWhite;
			}
		}
	}
	&InputField {
		background-color: rgba(153, 204, 255, 0.12);
		margin-top: 0;
		input:not([type]),
		input[type=text],
		input[type=password],
		input[type=email],
		input[type=url],
		input[type=time],
		input[type=date],
		input[type=datetime],
		input[type=datetime-local],
		input[type=tel],
		input[type=number],
		input[type=search],
		textarea.materialize-textarea {
			margin-bottom: 0;
			border-bottom: 0;
			@include font-size(1.6);
			font-weight: 400;
			padding: 10px 20px 0 20px;
			min-height: 45px;
			position: relative;
			box-sizing: border-box;
		}
		label {
			@include font-size(1.6);
			font-weight: 400;
			color: $akGreyText;
			left: 20px;
			top: 10px;
			&:not(.label-icon).active {
				@include font-size(1);
				// @include translate(0, -50%);
				@include translate(0, 0);
				top: 3px;
				color: $akGreyText;
			}
		}
		input:not([type]):focus:not([readonly])+label, input[type=text]:focus:not([readonly])+label, input[type=password]:focus:not([readonly])+label, input[type=email]:focus:not([readonly])+label, input[type=url]:focus:not([readonly])+label, input[type=time]:focus:not([readonly])+label, input[type=date]:focus:not([readonly])+label, input[type=datetime]:focus:not([readonly])+label, input[type=datetime-local]:focus:not([readonly])+label, input[type=tel]:focus:not([readonly])+label, input[type=number]:focus:not([readonly])+label, input[type=search]:focus:not([readonly])+label, textarea.materialize-textarea:focus:not([readonly])+label {
			color: $akBlue;
		}
		input:not([type]):focus:not([readonly]), input[type=text]:focus:not([readonly]), input[type=password]:focus:not([readonly]), input[type=email]:focus:not([readonly]), input[type=url]:focus:not([readonly]), input[type=time]:focus:not([readonly]), input[type=date]:focus:not([readonly]), input[type=datetime]:focus:not([readonly]), input[type=datetime-local]:focus:not([readonly]), input[type=tel]:focus:not([readonly]), input[type=number]:focus:not([readonly]), input[type=search]:focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
		    border-bottom: 1px solid $akBlue;
    		box-shadow: 0 1px 0 0 $akBlue;
		}
		.select-wrapper+label {
			@include font-size(1);
			top: 3px;
		}
		.select-wrapper span.caret {
			right: 15px;
			color: $akGreyText;
		}
		.dropdown-content {
			background-color: $akBlackLight;
			li>a, li>span {
				color: $akWhite;
			}
			li:hover, li.selected {
				background-color: $akGreyBg;
			}
		}
	}
	&TextArea {
		height: 150px;
		textarea.materialize-textarea {
			height: 100% !important;
			padding: 20px;
		}
	}
	&TextAreaSmall {
		@extend .akFormTextArea;
		height: 100px;
	}
}