.akFilter {
	background: none;
	line-height: inherit;
	margin: 50px 0;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	.categories-container.pinned {
		padding: 20px 20px 10px 20px;
		background: $akBlackLight;
		width: 100%;
		left: 0;
		padding-left: 6%;
	}
	&Target {
		float: right;
		margin-top: 10px;
		label {
			@include font-size(1.6);
			font-weight: 600;
			color: $akWhite;
			margin-left: 10px;
		}
		[type="checkbox"].filled-in:checked+label:after {
			background-color: $akWhite;
			border-color: $akWhite;
		}
		[type="checkbox"].filled-in:checked+label:before {
			border-right: 2px solid $akBlack;
			border-bottom: 2px solid $akBlack;
		}
	}
	&Item {
		&.active > .akFilterItemLinkGreen{
			background: none;
			@include bg-gradient-l2r($akGradientGreenDark, $akGradientGreenLight);
		}
		&.active > .akFilterItemLinkOrange{
			background: none;
			@include bg-gradient-l2r($akGradientOrangeDark, $akGradientOrangeLight);
		}
		&.active > .akFilterItemLinkBlue{
			background: none;
			@include bg-gradient-l2r($akGradientBlueDark, $akGradientBlueLight);
		}
		&.active > .akFilterItemLinkPink{
			background: none;
			@include bg-gradient-l2r($akGradientPinkDark, $akGradientPinkLight);
		}
		height:50px;
		&.active {
			&:before {
				display: none;
			}
			.shopNavFilterItemLink {
				@extend .akBtnWhite;
			}
			.shopNavFilterItemLinkGreen {
				background: none;
				color: $akWhite;
				@include bg-gradient-l2r($akGradientGreenDark, $akGradientGreenLight);
			}
			.shopNavFilterItemLinkBlue {
				background: none;
				color: $akWhite;
				@include bg-gradient-l2r($akGradientBlueDark, $akGradientBlueLight);
			}
			.shopNavFilterItemLinkOrange {
				background: none;
				color: $akWhite;
				@include bg-gradient-l2r($akGradientOrangeDark, $akGradientOrangeLight);
			}
			.shopNavFilterItemLinkPink {
				background: none;
				color: $akWhite;
				@include bg-gradient-l2r($akGradientPinkDark, $akGradientPinkLight);
			}
		}
		&Link{
			@extend .akBtnFilter;
			margin: 0 20px 10px 0;
			&All {
				@extend .akBtn;
				background: transparent;
				border: 1px solid $akWhite;
			}
			&Green {
				@extend .akBtnFilterGreen;
			}
			&Orange {
				@extend .akBtnFilterOrange;
			}
			&Blue {
				@extend .akBtnFilterBlue;
			}
			&Pink {
				@extend .akBtnFilterPink;
			}
		}
	}
}