@media #{$medium-and-up} {
	// styles for medium screens and larger
	.container {
		width: 87%;
	}
}

@media #{$medium-and-down} {
	// styles for medium screens and down
	.row .col.m6:nth-child(2n+3) {
		clear: left;
	}
}

// Media Query Ranges custom max-width
$layout-breakpoint-xl: 1200px !default;
$layout-breakpoint-l: 992px !default;
$layout-breakpoint-m: 601px !default;
$layout-breakpoint-s: 600px !default;

@media only screen and (min-width: #{$layout-breakpoint-xl}) {
	.akBirthdayTextDetail {
		margin-right: -2px;
	}
}

@media only screen and (max-width: #{$layout-breakpoint-xl}) {

}

@media only screen and (max-width: #{$layout-breakpoint-l}) {
	.home {
		&Header {
			display: flex;
			flex-direction: column-reverse;
		}
		&Title {
			position: relative;
			height: auto;
		}
		&ActivityContent {
			min-height: inherit;
		}
		&Video {
			&Logo {
				text-align: center;
				position: relative;
				top: 10px;
				margin-bottom: 10px;
				z-index: 1;
				left: 50%;
				width: 60%;
				-webkit-transform: translate(-50%, 0);
				-moz-transform: translate(-50%, 0);
				-ms-transform: translate(-50%, 0);
				transform: translate(-50%, 0);
			}
		}
	}
	.headerLogo img {
		width: 240px;
		margin-top: 30px;
	}
	.footerBloc {
		padding-bottom: 0;
		&Title {
			@include font-size(2);
		}
	}
}

@media screen and (max-height: 750px) {
	.akContent {
		width: 100%;
	}
	.menu {
		display: none;
		&Mobile {
			width: 100%;
			position: absolute;
			padding-top: 60px;
			background: $akGreyDark;
			line-height: 2;
			.menuItem {
				height: auto;
				min-height: 100px;
				line-height: 100px;
				background: $akGreyDark;
				&:hover {
					background: $akGrey;
				}
				.menuItemSubMenuItemGreen:hover {
					@include bg-gradient-l2r($akGradientGreenDark, $akGradientGreenLight);
				}
				.menuItemSubMenuItemOrange:hover {
					@include bg-gradient-l2r($akGradientOrangeDark, $akGradientOrangeLight);
				}
				.menuItemSubMenuItemBlue:hover {
					@include bg-gradient-l2r($akGradientBlueDark, $akGradientBlueLight);
				}
				&Link {
					padding-left: 30px;
					position: relative;
					&:after {
						content: "\0044";
						font-family: 'icomoon';
						position: absolute;
						right: 20px;
					}
				}
				&Libelle {
					display: inline-block;
					vertical-align: middle;
					@include font-size(1.6);
					font-weight: 400;
					line-height: 2;
					text-align: center;
				}
				&SubMenu {
					position: relative;
					width: 100%;
					right: 0;
				}
			}
			.menuItemSelected .menuItemLink:after {
				content: "\0045";
			}
			.menuItemSelected .menuItemSubMenuItemLink:after {
				content: "\0044";
			}
			.menuItemSubMenuItem {
				background: $akWhite;
			}
			.menuItem, .menuItemSubMenuItem {
				text-align: left;
				i {
					@include font-size(4);
					margin-top: 0;
					vertical-align: middle;
					margin-right: 30px;
				}
			}
			&Open {
				display: block;
			}
		}
	}
}

@media only screen and (max-width: #{$layout-breakpoint-s}) {
	.birthdayRow {
		display: block;
	}
	.pricesBankLogos {
		margin-top: 20px !important;
		margin-bottom: 10px !important;
	}
	.akContent {
		width: 100%;
	}

	.header {
		height: auto;
		&Basket {
			position: relative;
			right: inherit;
			top: inherit;
			float: right;
			margin: 10px;
		}
		&Logo {
			height: 60px;
			padding: 10px 0;
			img {
				width: 220px;
				margin-top: 0px;
			}
		}
	}
	.menu {
		display: none;
		&Content {
			height: 100%;
			overflow: auto;
		}
		&Mobile {
			width: 100%;
			position: fixed;
			padding-top: 60px;
			background: $akGreyDark;
			z-index: 400;
			.menuItem {
				height: auto;
				min-height: 100px;
				line-height: 100px;
				background: $akGreyDark;
				&:hover {
					background: $akGrey;
				}
				.menuItemSubMenuItemGreen:hover {
					@include bg-gradient-l2r($akGradientGreenDark, $akGradientGreenLight);
				}
				.menuItemSubMenuItemOrange:hover {
					@include bg-gradient-l2r($akGradientOrangeDark, $akGradientOrangeLight);
				}
				.menuItemSubMenuItemBlue:hover {
					@include bg-gradient-l2r($akGradientBlueDark, $akGradientBlueLight);
				}
				&Link {
					padding-left: 30px;
					position: relative;
					&:after {
						content: "\0044";
						font-family: 'icomoon';
						position: absolute;
						right: 20px;
					}
				}
				&Libelle {
					display: inline-block;
					vertical-align: middle;
					@include font-size(1.6);
					font-weight: 400;
				}
				&SubMenu {
					position: relative;
					width: 100%;
					right: 0;
				}
			}
			.menuItemSelected .menuItemLink:after {
				content: "\0045";
			}
			.menuItemSelected .menuItemSubMenuItemLink:after {
				content: "\0044";
			}
			.menuItemSubMenuItem {
				background: $akWhite;
			}
			.menuItem, .menuItemSubMenuItem {
				text-align: left;
				i {
					@include font-size(4);
					margin-top: 0;
					vertical-align: middle;
					margin-right: 30px;
				}
			}
			&Open {
				display: block;
				position: fixed;
			}
		}
	}
	.shopCardBooking {
		padding: 20px 30px;
		&Selection {
			position: relative;
			top: inherit;
			transform: none;
			right: inherit;
			margin-top: 15px;
		}
	}
}
