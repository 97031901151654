.menu {
	width: 170px;
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2000;
	display: block;
	&Content {
		height: 100vh;
	}
	&Item {
		height: calc(100vh / 7);
		list-style: none;
		text-align: center;
		background: $akGreyDark;
		color: $akWhite;
		&:hover {
			background: $akGrey;
			.menuItemIcon:before {
				@include text-gradient(left, $akGradientGreenDark, $akGradientGreenLight);
			}
		}
		&Libelle {
			@include font-size(1.4);
			text-transform: uppercase;
			font-weight: 600;
		}
		&Link {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
			height: 100%;
			color: $akWhite;
		}
		i {
			@include font-size(4.5);
		}
		&SubMenu {
			position: absolute;
			right: 170px;
			width: 170px;
			top: 0;
			display: none;
			&Item {
				@extend .menuItem;
				background: $akWhite;
				color: $akBlack;
				&Link {
					@extend .menuItemLink;
					color: $akBlack;
				}
				&Green {
					i:before {
						@include text-gradient(left, $akGradientGreenDark, $akGradientGreenLight);
					}
					&:hover {
						@include bg-gradient-l2r($akGradientGreenDark, $akGradientGreenLight);
					}
				}
				&Orange {
					i:before {
						@include text-gradient(left, $akGradientOrangeDark, $akGradientOrangeLight);
					}
					&:hover {
						@include bg-gradient-l2r($akGradientOrangeDark, $akGradientOrangeLight);
					}
				}
				&Blue {
					i:before {
						@include text-gradient(left, $akGradientBlueDark, $akGradientBlueLight);
					}
					&:hover {
						@include bg-gradient-l2r($akGradientBlueDark, $akGradientBlueLight);
					}
				}
				&Pink {
					i:before {
						@include text-gradient(left, $akGradientPinkDark, $akGradientPinkLight);
					}
					&:hover {
						@include bg-gradient-l2r($akGradientPinkDark, $akGradientPinkLight);
					}
				}
				&:hover {
					.menuItemSubMenuItemLink {
						color: $akWhite;
					}
					color: $akWhite;
					i:before {
						@include text-gradient(left, $akWhite, $akWhite);
						color: $akWhite;
					}
				}
			}
		}
		&:first-child:hover .menuItemSubMenu {
			display: block !important;
		}
	}

	&Mobile {
        &Open {
            background: $akGrey;
            color: $akWhite;
            position: absolute;
            right: 0;
            top: 0;
            width: 60px;
            height: 60px;
            text-align: center;
            padding-top: 15px;
            @include font-size(2.5);
            z-index: 3000;
            display: none;
        }

        .menuItemLink {
            flex-direction: row;
            justify-content: flex-start;
        }
    }
}
