//colors
$akWhite: #FFFFFF;
$akGrey: #2A3646;
$akGreyText: #747b86;
$akGreyDark: #212631;
$akGreyBg: #111824;
$akBlackLight: #0C1119;
$akBlack: #00060A;
$akBlue: #04BAEE;
$akGreen: #93C01F;
$akOrange: #EB6608;
$akRed: #D6002C;
$akGradientGreenDark: #1A9643;
$akGradientGreenLight: #C0CA51;
$akGradientBlueDark: #2A4DAD;
$akGradientBlueLight: #04BAEE;
$akGradientOrangeDark: #EB6608;
$akGradientOrangeLight: #FFC95C;
$akGradientPinkDark: #CC1E5E;
$akGradientPinkLight: #F0804D;

// For gallery
$primary-color: color("purple", "lighten-2") !default;
$primary-color-light: lighten($primary-color, 15%) !default;
$primary-color-dark: darken($primary-color, 15%) !default;
$secondary-color: color("teal", "lighten-1") !default;
$success-color: color("green", "base") !default;
$error-color: color("red", "base") !default;
$link-color: color("light-blue", "darken-1") !default;

$navbar-height: 64px !default;
$navbar-line-height: $navbar-height !default;
$navbar-height-mobile: 56px !default;
$navbar-line-height-mobile: $navbar-height-mobile !default;
$navbar-font-size: 1rem !default;
$navbar-font-color: #fff !default;
$navbar-brand-font-size: 2.1rem !default;

$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

$gallery-gutter: 15%;
