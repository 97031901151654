.member{
  &Img{
    width:100%;
  }
  &Karting, &Freefall{
    &Title{
      &Secondary{
        margin-bottom: 0;
      }
    }

    &Detail{
      margin-bottom: 0;
      &Formule{
        font-weight: bold;
      }
      &Price{
        @extend .akTitle6;
        font-weight: bold;
      }
      &Session{
        color: $akGreyText;
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: 400;
      }
    }
    &Advantage{
      margin: 60px 0 30px 0;
      &Thursday{
        position: relative;
        margin-left: 10px;
        margin-bottom: 40px;

        li:before {
          content: "• ";
          position: absolute;
          left: -10px;
        }
      }
    }
    &StartUp{
      margin-top: 60px;
      margin-bottom: 20px;
    }
  }
  &Hours{
    &Day{
      margin-bottom: 0;
    }
  }
}
