.news {
	.akFilter {
		margin: 0 0 30px 0;
	}
	&Bloc {
		overflow: hidden;
		color: $akWhite;
		display: block;
		width: 100%;
		&.gallery-item {
			margin-bottom: 40px;
		}
		&Picture {
			overflow: hidden;
			position: relative;
			/*height: 360px;*/
			height: 210px;
			float: left;
			width: 100%;
		}
		&Main{
			float: left;
			width: 100%;
		}
		&Title {
			padding: 0 40px 0 40px;
			background-color: rgba(0, 6, 10, 0.5);
			height: 100px;
			overflow: hidden;
			position: relative;
			&Content {
				position: absolute;
				top: 50%;
				@include translate(0,-50%);
				&Libelle {
					@extend .akTitle4;
					position: relative;
					&:before {
						content:"";
						width: 20px;
						margin-left: -20px;
						height: 1px;
						background: $akWhite;
						position: absolute;
						left: -20px;
						top: 50%;
						@include translate(0,-50%);
					}
				}
				&Date {
					@include font-size(1.4);
					font-weight: 400;
				}
			}

		}
		&Content {
			/*height: 260px;*/
			height: 110px;
			padding: 20px;
			@extend .akBgBlocLight;
			&Text {
				/*height: 220px;*/
				height: 70px;
				overflow: hidden;

				*{
					display: none;
				}
				*:first-child{
					display: block;
				}
			}
		}

		/* 1er item */
		&:first-child {
			.newsBloc {
				&Picture{
					float: none;
					width: 100%;
					height: 360px;
				}
				&Main{
					float:none;
					width:100%;
				}
				&Content {
					height: 140px;
					&Text {
						height: 70px;
					}
				}
			}
		}
	}


	&More {
		margin-bottom: 40px;
		display: block;
	}
	&SocialWall {
		background-color: rgba(0, 6, 10, 0.5);
		padding: 20px;
		height: 695px;
		overflow-y: auto;
		&.newsSocialWallBig {
			height: auto;
			max-height: 965px;
			margin-bottom: 30px;
		}
		&Bloc {
			margin-bottom: 30px;
			&:after {
				content: "";
				display: table;
				clear: both;
			}
			&Icon {
				float: left;
				width: 15%;
				@include font-size(2.4);
			}
			&Content {
				float: left;
				width: 85%;
				&Text {
					@include font-size(1.4);
					margin-bottom: 20px;
				}
			}
		}
	}
	&Detail {
		&Container {
			max-width: 760px;

			ul{
				position: relative;
				margin-left: 10px;
				li:before{
					content: "• ";
					position: absolute;
					left: -10px;
				}
				li{
					margin-bottom: 10px;
					&:last-child{
						margin-bottom: 20px;
					}
				}
			}
		}
		&Content {
			background: $akWhite;
			color: $akBlackLight;
			margin: 50px 0 10px 0;
			&Title {
				padding: 40px;
				@include font-size(2.4);
			}
			img {
				width: 100%;
			}
			&Text {
				padding: 40px;
				p {
					margin-bottom: 25px;
				}
				a {
					color: $akBlue;
					font-weight: 700;
				}
			}
		}
		&Tag {
			color: $akGreyText;
			@include font-size(1.4);
		}
		&Back {
			float: right;
			margin: 10px 0 50px 0;
		}

	}
}

.home .news{
	&Bloc{
		&:first-child {
			.newsBloc {
				&Picture {
					width: 100%;
					float: left;
				}
				&Main{
					width: 100%;
					float: left;
				}
				&Content{
					height: 100px;
				}
			}
		}
	}
}

@media screen and (min-width: 600px) {
	.newsBloc{
		&Picture {
			width: 50%;
		}
		&Main{
			width: 50%;
		}
	}
	.newsBloc:first-child .newsBlocContentText {
		height: 100px;
	}
	.home .news{
		&Bloc{
			&:first-child {
				.newsBloc {
					&Picture {
						width: 50%;
					}
					&Main{
						width: 50%;
					}
					&Content{
						&Text{
							height: 215px;
						}
						height: 260px;
					}
				}
			}
		}
	}
}