.kartingNanterre {

  &Titre {
    margin-bottom: 50px;
  }

  &LienAutresItineraires {
    a {
      color: #686868;
    }
    a:hover {
      color: white;
    }
  }

}