@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// Browser Prefixes
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

// Rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}

// Skew
@mixin skew ($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}

// Animation
@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}

// multi columns
@mixin columns($value) {
  -moz-columns: $value;
  -webkit-columns: $value;
  columns: $value;
}

// Text gradient & border
// $dir = Direction of gradient
// $from = Starting color
// $to = Ending color
// $width = Border width/thickness
@mixin text-gradient($dir, $from, $to) {
  background: -webkit-linear-gradient($dir, $from, $to);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin border-gradient($dir, $from, $to, $width) {
  border-left: $width solid $from;
  border-right: $width solid $to;
  background-image:
          // Needed for both the top and bottom borders
          -webkit-linear-gradient($dir, $from, $to),
          -webkit-linear-gradient($dir, $from, $to);
  background-size: 100% $width;
  background-position: 0 100%, 0 0;
  background-repeat: no-repeat;
  background-clip: border-box;
}

// Background Gradient: Left to Right
@mixin bg-gradient-l2r($start-colour, $end-colour) {
  background-color: $start-colour;
  background-image: -webkit-gradient(linear, left top, right top, from($start-colour), to($end-colour));
  background-image: -webkit-linear-gradient(left, $start-colour, $end-colour);
  background-image:    -moz-linear-gradient(left, $start-colour, $end-colour);
  background-image:     -ms-linear-gradient(left, $start-colour, $end-colour);
  background-image:      -o-linear-gradient(left, $start-colour, $end-colour);
  background-image:         linear-gradient(left, $start-colour, $end-colour);
  filter:            progid:DXImageTransform.Microsoft.gradient(start-colourStr='#{$start-colour}', end-colourStr='#{$end-colour}', gradientType='1');
}

// Background Gradient: Top to Bottom
@mixin bg-gradient-t2b($start-colour, $end-colour) {
  background-color: $start-colour;
  background-image: -webkit-gradient(linear, left top, left bottom, from($start-colour), to($end-colour));
  background-image: -webkit-linear-gradient(top, $start-colour, $end-colour);
  background-image:    -moz-linear-gradient(top, $start-colour, $end-colour);
  background-image:     -ms-linear-gradient(top, $start-colour, $end-colour);
  background-image:      -o-linear-gradient(top, $start-colour, $end-colour);
  background-image:         linear-gradient(top, $start-colour, $end-colour);
  filter:            progid:DXImageTransform.Microsoft.gradient(start-colourStr='#{$start-colour}', end-colourStr='#{$end-colour}');
}

// placeholder color
@mixin placeholder($color, $size:"") {
  &::-webkit-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &::-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-ms-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
}
